import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Subscription_Box from "./Subscription_Box";
import Price_Box from "./Price_Box";
import { useLocation } from "react-router-dom";
import { buy_subscription_api } from "../../../apis/apis";
import { useSelector } from "react-redux";

const Price_Page = ({}) => {
  const state = useLocation();
  const subscriptions = state?.state;

  return (
    <Layout no_sidebar={true}>
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "start",
          position: "fixed ",
          top: "120px",
          right: "0px",
          height: "70vh",
          width: "100vw",
          lineHeight: 1.8,
          paddingTop: "20px",
          overflowY: "auto",
        }}
      >
        <b style={{ fontSize: "24px" }}>Subscriptions</b>
        <span style={{ fontSize: "14px" }}>
          Unlock unlimited access to all the features
        </span>

        <Flex_box style={{ gap: 30, marginTop: "25px" }}>
          {subscriptions?.map((el, index) => {
            return (
              <Price_Box
                price={`$` + el?.Amount}
                subtext={"Per " + el?.Cycle}
                duration={el?.Duration}
                content={el?.Description}
                _id={el?._id}
                item={el}
              />
            );
          })}
        </Flex_box>
      </Flex_box>
    </Layout>
  );
};

export default Price_Page;
