import React from "react";
import Points_Comp from "../Components/Points_Comp";
import Button from "../../../utilities/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set_login_type_action } from "../../../redux_prog/actions/auth_action";

const SmartQDifference = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Points_Comp
      label={"Ready to Experience the Yaarnbox Difference?"}
      sub_text_arr={[
        {
          text: "Join the thousands of researchers and respondents already using Yaarnbox to unlock the power of market research",
          bold: true,
        },
        { text: "Call to Action section:", bold: true },
      ]}
      points_arr={[
        {
          heading: "Researchers",
          text: (
            <div>
              <span>
                Sign up for a free trial and experience the comprehensive
                research toolkit Yaarnbox offers.
              </span>
              <br />
              <Button
                label={"Sign Up for Free Trial"}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => {
                    dispatch(set_login_type_action(2));
                    navigate('/signup');
                }}
              />
            </div>
          ),
        },
        {
          heading: "Respondents",
          text: (
            <div>
              <span>
                Create your free account and start participating in exciting
                studies and earning rewards!
              </span>
              <br />
              <Button
                label={"Sign Up for Free"}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => {
                    dispatch(set_login_type_action(3));
                    navigate('/signup');
                }}
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default SmartQDifference;
