import React from 'react'
import Points_Comp from '../Components/Points_Comp'

const Knowledge_base = () => {
    return (
        <Points_Comp
            bg={'true'}
            label={'Knowledge Base'}
            points_icon={true}
            sub_text_arr={[
                {
                    text: `Our knowledge base is constantly evolving to keep pace with the latest platform features and functionalities.  We recommend checking back periodically for new articles and updates.`,
                    bold: true
                }
            ]}
            points_arr={[
                { "text": "Creating Engaging Multiple Choice Questions: Craft compelling multiple-choice questions with clear answer options and avoid common pitfalls." },
                { "text": "Leveraging Logic Branching for Dynamic Surveys: Take your surveys to the next level by implementing logic branching to personalize the respondent experience based on their answers." },
                { "text": "Advanced Targeting Options for Reaching the Right Audience: Go beyond demographics! Utilize advanced targeting filters like location, behavior, and interests to ensure you connect with highly qualified respondents for your research." },
                { "text": "Optimizing Surveys for Mobile Devices: Ensure your surveys are accessible and user-friendly on all devices, maximizing completion rates." },
                { "text": "Data Analysis Best Practices: Transform raw data into actionable insights with our data analysis tools. Learn how to interpret key metrics, generate compelling reports, and uncover hidden patterns within your data." },
                { "text": "Completing Your Respondent Profile: While not mandatory, providing some demographic information helps us connect you with relevant research studies that match your interests and expertise." },
                { "text": "Participating in Group Discussions: Share your valuable insights through interactive group discussions. This article outlines etiquette tips and best practices for effective participation." },
                { "text": "Understanding Smart Coins and Rewards: Learn how to earn Smart Coins for your contributions and explore the various ways to redeem them for exciting rewards." },
                { "text": "Data Privacy and Security on Yaarnbox: Your privacy is our priority. This article explains how Yaarnbox safeguards your personal information and ensures the confidentiality of your survey responses." }
            ]
            }
        />
    )
}

export default Knowledge_base
