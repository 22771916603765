import React from "react";
import Flex_box from "../../../../utilities/flex_box";
import { get_percentage } from "../../../../utilities/Get_percentage";

const Table_Comp = ({ item, index }) => {
  return (
    <>
      <Flex_box
        style={{
          justifyContent: "start",
          padding: "5px 5px",
          marginTop: "10px",
          border: "1px dashed grey",
          width: "98.4%",
        }}
      >
        <b style={{ width: "50%" }}>Answer Choices</b>
        <b style={{ width: "40%" }}>Responses</b>
      </Flex_box>
      <div
        style={{
          backgroundColor: "#F9F9F9",
          fontSize: "15px",
          boxSizing: "border-box",
          borderRadius: "5px",
        }}
      >
        {item?.graph_data?.length > 0 &&
          item?.graph_data?.map((li, index) => {
            return (
              <Flex_box
                key={index}
                style={{
                  justifyContent: "start",
                  padding: "5px 10px",
                  boxSizing: "border-box",
                  border: "1px dashed grey",
                }}
              >
                <span style={{ width: "50.5%" }}>{li?.label}</span>
                <span style={{ width: "40%" }}>
                  {get_percentage(li.y, item?.total_answers) || 0}
                  {"%"}
                </span>
                <span>{li?.y}</span>
              </Flex_box>
            );
          })}

        <Flex_box
          style={{
            justifyContent: "start",
            padding: "5px 10px",
            backgroundColor: "rgb(2, 103, 193,0.1)",
            boxSizing: "border-box",
            border: "1px solid rgb(2, 103, 193,0.1)",
          }}
        >
          <b style={{ width: "90.5%" }}>Total</b>
          <b>{item?.total_answers}</b>
        </Flex_box>
      </div>
    </>
  );
};

const Question_analysis = ({ item, index, s_id }) => {
  const sub_questions = item?.sub_questions;

  return (
    <div
      style={{
        padding: "15px 15px 0px 15px",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px #0000000D",
        marginTop: "15px",
        lineHeight: 1.5,
      }}
    >
      <b>
        Q{index + 1}. {item?.questiontitle}
      </b>
      <br />
      {!sub_questions && (
        <span
          style={{
            fontSize: "14px",
            opacity: "0.7",
          }}
        >
          Respondents: {item?.inp_ans || item?.total_answers}
        </span>
      )}
      {item?.graph_data?.length > 0 && (
        <>
          <Table_Comp item={item} index={index} />
        </>
      )}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {sub_questions?.length > 0 &&
          sub_questions?.map((li, ind) => {
            return (
              <div
                style={{
                  border: "1px dashed grey",
                  padding: "10px",
                }}
              >
                <b
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Sub-Q{ind + 1}. {li?.subquestiontitle}:-
                </b>
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    opacity: "0.7",
                  }}
                >
                  Respondents: {li?.total_answers}
                </span>
                <Table_Comp item={li} index={ind} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Question_analysis;
