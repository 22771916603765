import React, { useEffect, useRef, useState } from "react";
// import Layout from "../../../../utilities/Layout";
import Flex_box from "../../../../utilities/flex_box";
import Page_heading from "../../../../utilities/Page_heading";
import Question_analysis from "./Question_analysis";
import { get_survey_analysis_api } from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import { sum_of_array_elem } from "../../../../utilities/Add_array_elem";
import { useDispatch } from "react-redux";
import { loading_action } from "../../../../redux_prog/actions/base_action";

const Survey_Analysis = ({ s_id, studyname }) => {
  const path_state = useLocation();
  const dispatch = useDispatch();
  const state = s_id ? { study_id: s_id, studyname } : path_state?.state;
  const { study_id } = state || {};
  const s_name = state?.studyname;

  const [study_analysis_data, setstudy_analysis_data] = useState({});

  const get_surveyanalysis = () => {
    dispatch(loading_action(true));
    get_survey_analysis_api(study_id)
      .then((res) => {
        dispatch(loading_action(false));
        const analysis_data = res.data.Res.map((el) => {
          if (el?.question?.questiontype == 4) {
            return {
              questiontitle: el?.question?.questiontitle,
              sub_questions: el?.options?.map((li) => {
                return {
                  subquestiontitle: li?.subquestion?.questiontitle,
                  total_answers: sum_of_array_elem(li.subansw, "attempt"),
                  graph_data: li?.subansw?.map((elem) => {
                    return {
                      label: elem?.suboption?.optiontitle,
                      y: parseInt(elem?.attempt),
                    };
                  }),
                };
              }),
            };
          } else {
            return {
              questiontitle: el?.question?.questiontitle,
              inp_ans: el?.inp_ans,
              total_answers: sum_of_array_elem(el.options, "attempt"),
              graph_data: el?.options.map((li) => {
                return {
                  label: li?.option?.optiontitle,
                  y: parseInt(li?.attempt),
                };
              }),
            };
          }
        });

        console.warn(analysis_data);

        setstudy_analysis_data(analysis_data);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(get_surveyanalysis, []);

  return (
    <>
      {study_analysis_data?.length > 0 &&
        study_analysis_data?.map((el, index) => {
          return (
            <Question_analysis
              s_id={s_id}
              key={index}
              index={index}
              item={el}
            />
          );
        })}
    </>
  );
};

export default Survey_Analysis;
