import React from 'react'

const Flex_box = (props) => {

    const style_obj = props?.style || {};

    return (
        <div
            className={props.className || ''}
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...style_obj
            }}
        >
            {props.children}
        </div>
    )
}

export default Flex_box
