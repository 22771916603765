import React from 'react'
import Table from '../../../utilities/Table'
import Flex_box from '../../../utilities/flex_box'

const Table_Comp = () => {

    const head_arr = [
        {
            col1: 'Real-time Communication',
            col2: 'Facilitate smooth communication with respondents through in-survey messages, group discussion chat functionalities, and direct messaging within the platform.'
        },
        {
            col1: 'Notification System',
            col2: 'Stay informed about study updates, survey completions, and messages through our integrated notification system.'
        },
        {
            col1: 'Your Data is Safe',
            col2: 'We prioritize the security and privacy of your data. Yaarnbox employs industry-standard encryption protocols to safeguard all information and transactions carried out on the platform.'
        },
        {
            col1: 'Know Your Customer (KYC) Verification',
            col2: 'Researchers undergo a rigorous KYC verification process, ensuring the legitimacy and credibility of those conducting studies on the platform.'
        },
        {
            col1: 'Data Ownership and Control',
            col2: 'Researchers retain full ownership of their collected data, and respondents have complete control over their personal information.'
        },
    ]

    return (
        <Flex_box
            style={{
                flexDirection: "column",
                gap: 10,
                padding:"40px 0px"
            }}
        >
            {
                head_arr?.map((el, index) => {
                    return (
                        <Flex_box
                            style={{
                                width: "70%",
                                gap: 10,
                                alignItems: "",
                                // marginTop: "10px"
                            }}
                        >
                            <Flex_box
                                style={{
                                    backgroundColor: '#F5FAFF',
                                    color: "#000",
                                    padding: "18px 0px 18px 15px",
                                    textAlign: "start",
                                    borderRadius: "10px",
                                    width: "30%",
                                    fontSize: "14px",
                                    fontWeight: 600
                                }}
                            >
                                {el?.col1}
                            </Flex_box>
                            <Flex_box
                                style={{
                                    backgroundColor: '#F5FAFF',
                                    color: "#000",
                                    padding: "18px 0px 18px 15px",
                                    textAlign: "start",
                                    borderRadius: "10px",
                                    width: "68%",
                                    fontSize: "14px",
                                    // fontWeight:600
                                }}
                            >
                                {el?.col2}
                            </Flex_box>
                        </Flex_box>
                    )
                })
            }
            {/* <Table
                key={index}
                style={{
                    width: "80%"
                }}
                heads={
                    [<p style={{ width: '350px' }}>{el?.col1}</p>, <span style={{ fontWeight: 300 }}>{el?.col2}</span>]
                }
            /> */}
        </Flex_box>
    )
}

export default Table_Comp
