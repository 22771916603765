import React from 'react'
import { Modal } from '../../../utilities/Modal'
import Flex_box from '../../../utilities/flex_box';
import share_arrow from "../../../assets/Group 68236.svg";
import cross from "../../../assets/close-circle-sharp.svg";
import { useLocation } from 'react-router-dom';

const Goto_Modal_Comp = ({ isOpen, onClose,setcurrent_ques,survey_details }) => {

    const path_state  = useLocation();
    const state = path_state?.state || survey_details;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <Flex_box
                className="responsive_width_35"
                style={{
                    justifyContent: "start",
                    flexDirection: "column",
                    width: '',
                    position: "relative",
                    // maxHeight:"60vh",
                    // overflowY:"auto"
                }}
            >
                <img
                    src={cross}
                    onClick={onClose}
                    style={{
                        height: "20px",
                        position: "absolute",
                        right: "-5px",
                        top: "-10px",
                        zIndex: "1000",
                        cursor: "pointer"
                    }}
                />
                <b style={{ fontSize: "22px" }}>{state?.surveydata?.surveytitle}</b>
                {
                    state?.questionsdata?.length > 0 &&
                    state?.questionsdata?.map((el, index) => {
                        return (
                            <Flex_box
                                style={{
                                    justifyContent: "space-between",
                                    padding: "5px 0px"
                                }}
                            >
                                <b>Q{index + 1} {el?.QuestionData?.questiontitle}</b>
                                <img
                                onClick={()=>{
                                    setcurrent_ques(index);
                                    onClose();
                                }}
                                src={share_arrow} style={{ height: "40px",cursor:"pointer" }} />
                            </Flex_box>
                        )
                    })
                }
            </Flex_box>
        </Modal>
    )
}

export default Goto_Modal_Comp
