import React from "react";
import Layout from "../../../utilities/Layout";
import Flex_box from "../../../utilities/flex_box";
import { useNavigate } from "react-router-dom";
import Button from "../../../utilities/Button";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../redux_prog/actions/base_action";

const Study_Intro_Page = () => {
  const navigate = useNavigate();
  const { user_data } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();

  return (
    <Layout
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex_box
        className="responsive_width_35"
        style={{
          flexDirection: "column",
          padding: "20px",
          width: "",
          borderRadius: "10px",
          boxShadow: "0px 5px 10px #0000000D",
          gap: 20,
        }}
      >
        <b style={{ fontSize: "22px" }}>Your journey starts here!</b>
        <span
          style={{
            fontSize: "14px",
          }}
        >
          Now you can design dynamic surveys that adapt to each respondent,
          yielding richer, more nuanced data.
          <br />
          <br />
          Engage directly with your audience through live chats, video calls,
          and Q&A sessions, gaining deeper insights and building trust.
          <br />
          <br />
          Visualize raw responses into clear, actionable dashboards and reports,
          guiding your next steps with confidence
        </span>
        <Button
          id="btn_comp"
          onClick={() => {
            if (user_data?.kyc_verified) {
              navigate("/create-study");
            } else {
              dispatch(
                alert_action({
                  type: "error",
                  title: "Unverified KYC",
                  msg: "Please update your KYC!",
                })
              );
            }
          }}
          label={"Start Here"}
        ></Button>
      </Flex_box>
    </Layout>
  );
};

export default Study_Intro_Page;
