import React from "react";
import Flex_box from "../../../utilities/flex_box";
import contact_bg from "../../../assets/Path37581.png";
import bg2 from "../../../assets/Group 48291.svg";
import settings_icon from "../../../assets/Group 48324.svg";
import twofour_icon from "../../../assets/Group 48320.svg";
import man_icon from "../../../assets/Group 48343.svg";
import woman_icon from "../../../assets/Group 48312.svg";
import line_icon from "../../../assets/Group 2007.svg";
import arrow_icon from "../../../assets/Group 2194.svg";
import Text_Input from "../../../utilities/Text_Input";
import Select_box from "../../../utilities/Select_box";
import { margin, padding, width } from "@mui/system";
import Button from "../../../utilities/Button";
import { useMediaQuery } from "@mui/material";
import { theme_color } from "../../../utilities/colors";

const Contact_Form = ({ open_modal }) => {
  const max_w_850 = useMediaQuery("(max-width:850px)");

  return (
    <Flex_box
      className="auth_box_cont"
      style={{
        flexDirection: "column",
        backgroundImage: `url(${contact_bg})`,
        padding: "40px 0px",
        position: "relative",
      }}
    >
      <img
        src={bg2}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 50,
          left: 0,
          zIndex: 100,
        }}
        className="hover_img"
      />
      <img
        src={settings_icon}
        style={{
          height: "150px",
          position: "absolute",
          top: 100,
          left: 150,
        }}
      />
      <img
        src={twofour_icon}
        style={{
          height: "80px",
          position: "absolute",
          top: 50,
          right: 150,
        }}
      />
      {/* {!max_w_850 && (
        <img
          src={man_icon}
          style={{
            height: "200px",
            position: "absolute",
            bottom: 50,
            left: "12%",
            zIndex: 102,
          }}
        />
      )}
      <img
        src={woman_icon}
        style={{
          height: "170px",
          position: "absolute",
          bottom: 50,
          right: "5%",
        }}
      /> */}
      <img
        src={line_icon}
        style={{
          height: "200px",
          position: "absolute",
          top: "40%",
          right: "6.5%",
        }}
      />
      <img
        src={arrow_icon}
        style={{
          height: "50px",
          position: "absolute",
          top: "38%",
          right: "3.5%",
        }}
      />

      <Flex_box
        style={{
          gap: 40,
          flexWrap: "wrap",
        }}
      >
        {!max_w_850 && (
          <div
            style={{
              width: "40%",
              zIndex: 120,
            }}
          >
            <b
              style={{
                fontSize: "34px",
              }}
            >
              Get In Touch with
            </b>
            <br />
            <b
              style={{
                fontSize: "56px",
                color: theme_color,
                lineHeight: 1,
                fontWeight: 800,
              }}
            >
              Our <br />
              Experts
            </b>
            <p>
              Want find out how Yaarnbox can help your organization? We'd love
              to hear about from you.
            </p>
            <b>Integrate towards innovation</b>
            <p>
              Become a Yaarnbox partner to Launch, Run and Grow your business
              globally.
            </p>
            <Button
              style={{
                width: "120px",
              }}
              onClick={open_modal}
              label={"Start Now"}
            />
          </div>
        )}
        <Flex_box
          style={{
            flexDirection: "column",
            width: !max_w_850 ? "50%" : "60%",
            padding: "20px 20px 35px 20px",
            borderRadius: "10px",
            backgroundColor: "white",
            zIndex: 101,
            marginTop: max_w_850 ? "10px" : "40px",
          }}
        >
          <Flex_box
            style={{
              justifyContent: "start",
            }}
          >
            <b className="intro_heading_text">Let's get to know you</b>
          </Flex_box>
          <Flex_box
            style={{
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Text_Input
              label={"First Name"}
              cont_style={{
                width: "49%",
              }}
              onChange={(e) => {}}
            />
            <Text_Input
              label={"Last Name"}
              cont_style={{
                width: "49%",
              }}
              onChange={(e) => {}}
            />
          </Flex_box>
          <Text_Input
            label={"Your Email Address"}
            cont_style={{
              marginTop: "30px",
              width: "100%",
            }}
            onChange={(e) => {}}
          />
          <Select_box
            cont_style={{
              marginTop: "30px",
              width: "100%",
            }}
            input_style={{
              width: "100%",
            }}
            select_cont_style={{
              width: "100%",
            }}
            label={"Which related problem are you facing?"}
            // value={ConductIndustryStudiesIn}
            onChange={(e) => {}}
          />
          <Text_Input
            label={"Type your message"}
            textArea={true}
            input_style={{
              height: "60px",
            }}
            cont_style={{
              marginTop: "30px",
              width: "100%",
            }}
            onChange={(e) => {}}
          />
          <Button
            label={"Submit"}
            style={{
              marginTop: "40px",
            }}
          />
        </Flex_box>
      </Flex_box>
    </Flex_box>
  );
};

export default Contact_Form;
