import React from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../../utilities/Layout';
import Flex_box from '../../../utilities/flex_box';
import { theme_bg, theme_color } from '../../../utilities/colors';
import share_arrow from "../../../assets/Group 68236.svg";
import Radio_Box from '../../../utilities/Radio_Box';

const Survey_Completed = ({ setsurvey_completed }) => {

    const navigate = useNavigate();

    return (
        <Flex_box
            className="responsive_width_35"
            style={{
                flexDirection: "column",
                padding: "20px",
                width: "",
                borderRadius: "10px",
                boxShadow: "0px 5px 10px #0000000D",
                gap: 10,
                textAlign: "center",
                position: "relative"
            }}
        >
            <b style={{ fontSize: "22px" }}>Survey Name</b>
            <div style={{ width: "100%", marginTop: "15px" }}>
                <b style={{ fontSize: "14px" }}>Click the button below to submit this form as it is currently filled out.</b>


                <button
                    id='btn_comp'
                    style={{ fontSize: "16px", fontWeight: "500", marginTop: "50px" }}
                    onClick={() => {
                        // navigate("/study-details");
                    }}
                >
                    Submit Form
                </button><br />
                <button
                    id='btn_comp'
                    style={{ fontSize: "16px", fontWeight: "500", backgroundColor: "#FFCC99", marginTop: "20px" }}
                    onClick={() => {
                        // navigate("/study-details");
                        setsurvey_completed(false);
                    }}
                >
                    Previous
                </button>
            </div>
        </Flex_box>
    )
}

export default Survey_Completed;
