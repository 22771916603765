import React, { useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import landing_bg from "../../../assets/Path1.png";
import { useNavigate } from "react-router-dom";

const Welcome_Comp = ({ open_modal }) => {
  const navigate = useNavigate();

  return (
    <Flex_box
      className="auth_box_cont intro_comp_cont"
      style={{
        // height: "86vh",
        // gap: 50,
        flexWrap: "wrap",
        position: "relative",
        backgroundImage: `url(${landing_bg})`,
      }}
    >
      <img
        style={{
          position: "absolute",
          top: "-80px",
          left: "0px",
          height: "700px",
          zIndex: 100,
        }}
        src={landing_mask}
        className="hover_img"
      />
      <img
        style={{
          position: "absolute",
        //   top: "-110px",
          right: "0px",
          // height: "700px",
          width: "47%",
          height: "100%",
          zIndex: 101,
        }}
        src={right_mask}
        className="hover_img"
      />
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="intro_text_cont"
          style={{
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            Welcome to Yaarnbox…fostering Impactful Connections for brands and
            their consumers
          </b>
          <br className="max_100_hidden" />
          <br />
          <span
            className="intro_sub_text"
            style={{ fontStyle: "italic" }}
          >
            Beyond traditional surveys, we enable meaningful connections that
            unlock deeper insights.
          </span>
          <br className="max_100_hidden" />
          <br />
          <span
            className="intro_sub_text"
            style={{ fontStyle: "italic" }}
          >
            Explore a dynamic ecosystem designed to redefine the way researchers
            and respondents connect and interact.
          </span>

          <Flex_box
            style={{
              width: "",
              boxSizing: "border-box",
              justifyContent: "start",
              gap: 20,
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                width: "120px",
              }}
              onClick={() => {
                navigate("/documentation");
              }}
              label={"Discover more"}
            />
            <Button
              style={{
                width: "120px",
              }}
              onClick={open_modal}
              label={"Start Now"}
            />
          </Flex_box>
        </div>
        <div
          className="max_100_hidden"
          style={{
            width: "40%",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              minWidth: "200px",
              gap: 20,
            }}
          >
            <img
              src={welcome_icon}
              style={{
                height: "60vh",
                // position: "absolute",
                // bottom: "0px",
                // left: "20%"
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

export default Welcome_Comp;
