import React from "react";
import Points_Comp from "../Components/Points_Comp";
import Button from "../../../utilities/Button";
import { set_login_type_action } from "../../../redux_prog/actions/auth_action";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Diff_exp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Points_Comp
      label={"Ready to experience the difference?"}
      sub_text_arr={[
        {
          text: "Join the growing community of researchers and respondents utilizing Yaarnbox to revolutionize the way market research is conducted.",
        },
      ]}
      points_arr={[
        {
          heading: "Researchers",
          text: (
            <div>
              <span>
                Sign up for a free trial and experience the comprehensive
                research toolkit Yaarnbox offers.
              </span>
              <br />
              <Button
                label={"Sign Up for Free Trial"}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => {
                  dispatch(set_login_type_action(2));
                  navigate("/signup");
                }}
              />
            </div>
          ),
        },
        {
          heading: "Respondents",
          text: (
            <div>
              <span>
                Create your free account and start participating in exciting
                studies and earning rewards!
              </span>
              <br />
              <Button
                label={"Sign Up for Free"}
                style={{
                  marginTop: "10px",
                }}
                onClick={() => {
                  dispatch(set_login_type_action(3));
                  navigate("/signup");
                }}
              />
            </div>
          ),
        },
      ]}
      bg={"true"}
    />
  );
};

export default Diff_exp;
