export const generate_otp = (num) => {
  if (num < 1) {
    throw new Error("num must be a positive integer");
  }

  const min = Math.pow(10, num - 1); // Minimum value based on num
  const max = Math.pow(10, num) - 1; // Maximum value based on num

  const otp = Math.floor(Math.random() * (max - min + 1)) + min;
  console.warn({ otp });
  return otp.toString();
};

export const read_stream = (file, func) => {
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      // Create a stream from the ArrayBuffer
      const stream = new ReadableStream({
        start(controller) {
          const uint8Array = new Uint8Array(reader.result);
          let pos = 0;

          function push() {
            if (pos < uint8Array.length) {
              controller.enqueue(uint8Array.slice(pos, (pos += 1024)));
              // Adjust the slice size as needed for performance
              // Increase/decrease it depending on your file size and performance requirements
              setTimeout(push, 0); // This allows handling large files without blocking the main thread
            } else {
              controller.close();
            }
          }

          push();
        },
      });

      if (func) {
        func(stream);
      }
    };
    reader.readAsArrayBuffer(file);
  }
};
