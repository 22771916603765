import React, { useState } from "react";
import { Modal } from "../../../../../utilities/Modal";
import Flex_box from "../../../../../utilities/flex_box";
import Text_Input from "../../../../../utilities/Text_Input";
import Radio_Box from "../../../../../utilities/Radio_Box";
import clock_icon from "../../../../../assets/clock_icon.png";
import date_icon from "../../../../../assets/date_icon.png";
import Button from "../../../../../utilities/Button";
import { create_poll_api } from "../../../../../apis/apis";
import validate_object from "../../../../../utilities/Validate_object";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../../../redux_prog/actions/base_action";

const Poll_Form = ({ poll_form_modal, setpoll_form_modal, handleSubmit }) => {
  const [options, setoptions] = useState([
    {
      option: "",
    },
  ]);

  const dispatch = useDispatch();

  const option_gt_1 = options?.length > 1;

  const [poll_data, setpoll_data] = useState({
    exp_date: "",
    exp_time: "",
    question: "",
    allow_multiple_opts: true,
  });

  const upd_poll_data = (val_obj) => {
    setpoll_data({
      ...poll_data,
      ...val_obj,
    });
  };

  const { exp_date, exp_time, question, allow_multiple_opts } = poll_data;

  const create_poll = (e) => {
    const poll_data_validated = validate_object(poll_data);

    // console.warn(poll_data_validated);
    if (
      poll_data_validated?.status ||
      poll_data_validated?.key == "allow_multiple_opts"
    ) {
      create_poll_api({
        poll_data: {
          ...poll_data,
          options,
        },
      })
        .then((res) => {
          console.warn(res.data);
          handleSubmit(e, "poll~" + res.data.Res?._id, "nopreventdefault");
          setpoll_form_modal(false);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      dispatch(
        alert_action({
          title: `Error!`,
          type: "error",
          msg: "Enter all fields!",
        })
      );
    }
  };

  return (
    <Modal
      contentStyle={{
        width: "300px",
        height: "70vh",
        overflowY: "auto",
      }}
      isOpen={poll_form_modal}
    >
      <Flex_box
        style={{
          paddingBottom: "10px",
          // borderBottom: "1px solid lightgrey",
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "#000",
          }}
        >
          Poll
        </span>

        <span
          style={{
            fontSize: "22px",
            fontWeight: 500,
            color: "#000",
            position: "absolute",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setpoll_form_modal(false);
          }}
        >
          &times;
        </span>
      </Flex_box>
      <Text_Input
        value={question}
        onChange={(e) => {
          upd_poll_data({
            question: e.target.value,
          });
        }}
        placeholder={`What's your poll question?`}
      />
      <Flex_box
        style={{
          justifyContent: "start",
          padding: "5px 0px",
        }}
      >
        <Radio_Box
          onClick={() => {
            upd_poll_data({
              allow_multiple_opts: !allow_multiple_opts,
            });
          }}
          checked={allow_multiple_opts}
        />
        <span
          style={{
            fontSize: "14px",
          }}
        >
          Allow selecting multiple options
        </span>
      </Flex_box>
      <span
        style={{
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        EXPIRATION
      </span>
      <Text_Input
        value={exp_date}
        onChange={(e) => {
          upd_poll_data({
            exp_date: e.target.value,
          });
        }}
        type={"date"}
      />
      <Text_Input
        value={exp_time}
        onChange={(e) => {
          upd_poll_data({
            exp_time: e.target.value,
          });
        }}
        type={"time"}
      />
      <p
        style={{
          fontSize: "12px",
          fontWeight: "500",
        }}
      >
        OPTIONS
      </p>
      {options?.map((el, index) => {
        return (
          <Flex_box
            style={{
              justifyContent: "start",
              gap: "5px",
            }}
          >
            <Text_Input
              cont_style={{
                width: option_gt_1 ? "90%" : "100%",
              }}
              value={el?.option}
              onChange={(e) => {
                setoptions(
                  options?.map((li, ind) => {
                    if (ind == index) {
                      return {
                        ...li,
                        option: e.target.value,
                      };
                    } else {
                      return li;
                    }
                  })
                );
              }}
              placeholder={`Option ` + (index + 1)}
            />
            {option_gt_1 && (
              <div
                onClick={() => {
                  setoptions(
                    options?.filter((el, ind) => {
                      return index != ind;
                    })
                  );
                }}
                style={{
                  color: "red",
                  fontSize: "20px",
                  cursor: "pointer",
                  border: "1px solid red",
                  padding: "0px 5px 0px 6px",
                  borderRadius: "50%",
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </div>
            )}
          </Flex_box>
        );
      })}
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setoptions([
            ...options,
            {
              option: "",
            },
          ]);
        }}
      >
        <Flex_box
          style={{
            justifyContent: "start",
            gap: "10px",
            marginTop: "7px",
          }}
        >
          <i
            style={{
              fontSize: "18px",
              color: "grey",
            }}
            class="fa-solid fa-plus"
          ></i>
          <span
            style={{
              fontSize: "14px",
              color: "grey",
            }}
          >
            Add Option
          </span>
        </Flex_box>
      </span>
      <Flex_box>
        <Button
          style={{
            marginTop: "15px",
          }}
          label={"Create Poll"}
          onClick={create_poll}
        />
      </Flex_box>
    </Modal>
  );
};

export default Poll_Form;
