import React from 'react';
import Layout from '../../../../utilities/Layout';
import My_studies_comp from './My_studies_comp';

const My_Studies = () => {
    return (
        <Layout
            style={{
                padding: "20px",
                boxSizing: "border-box"
            }}
        >
            <My_studies_comp />
        </Layout>
    )
}

export default My_Studies;
