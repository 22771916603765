import React from 'react'

const Page_heading = ({ label }) => {
    return (
        <span
            style={{
                fontSize: "24px",
                fontStyle: "italic",
                fontWeight: "700"
            }}
        >
            {label}
        </span>
    )
}

export default Page_heading
