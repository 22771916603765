import React from 'react'
import { useNavigate } from 'react-router-dom';
import back_icon from "../../assets/chevron_back_circle.svg";

const Auth_header = ({ label,no_back_icon }) => {

    const navigate = useNavigate();

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: 'center',
                alignItems: 'center',
                position: "relative",
                textAlign: "center"
            }}
        >
            {!no_back_icon &&
            <img src={back_icon}
                style={{
                    height: '30px',
                    position: "absolute",
                    left: 0,
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigate(-1);
                }}
            />
}
            <b
                style={{
                    fontSize: "26px"
                }}
            >
                {label}
            </b>
        </div>
    )
}

export default Auth_header
