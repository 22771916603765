import React from "react";
import { useNavigate } from "react-router-dom";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import bg from "../../../assets/Path1.png";
import Button from "../../../utilities/Button";
import { useMediaQuery } from "@mui/material";

const Subscription_Box = ({ label, subtext, content_arr, path, btn_text }) => {

  return (
    <Flex_box
      className="responsive_width"
      style={{
        width: "",
        minWidth: "250px",
        flexDirection: "column",
        lineHeight: 0.9,
        boxShadow: "0px 10px 20px #0000000D",
        padding: "20px 10px",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    >
      <span className="intro_heading_text" style={{ fontWeight: 600 }}>
        {label}
      </span>
      <br />
      <span className="intro_sub_text">{subtext}</span>
      <Flex_box
        style={{
          flexDirection: "column",
          backgroundColor: theme_color,
          borderRadius: "10px",
          color: "#fff",
          gap: 15,
          padding: "25px 10px",
          boxSizing: "border-box",
          marginTop: "20px",
        }}
      >
        {content_arr?.length > 0 &&
          content_arr.map((el, index) => {
            return (
              <span key={index} style={{ textAlign: "center" }}>
                {el}
              </span>
            );
          })}
      </Flex_box>
    </Flex_box>
  );
};

const Subscriptions = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");
  return (
    <Flex_box
      style={{
        padding: "60px 10px",
        backgroundImage: `url(${bg})`,
        flexDirection: "column",
        backgroundPosition: "0px -420px",
      }}
    >
      <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
        Subscriptions
      </b>
      <br className="max_100_hidden" />
      <span className="intro_sub_text" style={{ fontStyle: "italic" }}>
        Try Yaarnbox for Free: Explore the Basic Plan for free or opt for a
        14-day trial of the Pro Plan.
      </span>
      <Flex_box
        style={{
          gap: 40,
          width: "92vw",
          overflowX: "auto",
          overflowY: "hidden",
          marginTop: max_w_1000 ? "5px" : "35px",
          justifyContent: max_w_1000 ? "start" : "center",
        }}
      >
        <Subscription_Box
          label={"Basic Plan"}
          subtext={"Free"}
          content_arr={[
            `Limited survey features`,
            `Standard data visualization tools`,
            <span>&nbsp;</span>,
            <span>&nbsp;</span>,
          ]}
          btn_text={"See Pricing"}
          path={"/sub-price"}
        />
        <Subscription_Box
          label={"Pro Plan"}
          subtext={"$29.99/month"}
          content_arr={[
            <span>Enhanced survey capabilities</span>,
            <span>Advanced data analytics</span>,
            <span>Real time communication tools</span>,
            <span>&nbsp;</span>,
          ]}
          btn_text={"Continue"}
          path={"/dashboard"}
        />
        <Subscription_Box
          label={"Enterprise Plan"}
          subtext={"Custom Pricing"}
          content_arr={[
            <span>Tailored solutions for large-scale</span>,
            <span>research projects</span>,
            <span>dedicated support and additional</span>,
            <span>features</span>,
          ]}
          btn_text={"Continue"}
          path={"/dashboard"}
        />
      </Flex_box>
      <Button
        label={"Get Started"}
        style={{
          marginTop: "60px",
        }}
      />
    </Flex_box>
  );
};

export default Subscriptions;
