import React, { useState } from 'react';
import support_icon from "../../assets/support_icon.svg";
import Chat_Box from '../Researcher_screens/My_Studies/GD_Chat/Chat_Box';

const Support_Chat = () => {

    const [chat_visibility, setchat_visibility] = useState(false)

    return (
        <div
            style={{
                position: "fixed",
                bottom: '50px',
                right: '30px',
                zIndex: 1002
            }}
        >
            <img src={support_icon}
                style={{
                    height: "70px",
                    cursor: "pointer"
                }}
                onClick={() => {
                    setchat_visibility(!chat_visibility);
                }}
            />
            {
                chat_visibility &&
                <div
                    style={{
                        bottom: "80px",
                        position: "absolute",
                        width: "300px",
                        right: "30px",
                        zIndex: 1002
                    }}
                >
                    <Chat_Box top_less={true} />
                </div>
            }
        </div>
    )
}

export default Support_Chat
