import Flex_box from "../../../utilities/flex_box";
import respondent_icon from "../../../assets/Group 48287.svg";
import bg_p from "../../../assets/Path37581.png";
import Button from "../../../utilities/Button";
// import Table from "../../../utilities/Table";
import { useMediaQuery } from "@mui/material";
import { theme_bg } from "../../../utilities/colors";


const Table = ({ style, heads, rows, fontSize, row_click_func }) => {
  const style_obj = style || {};

  const th_col_style = {
    padding: "18px 0px 18px 15px",
    textAlign: "start",
    borderRadius: "10px",
  };

  const td_col_style = {
    padding: "10px 0px 0px 15px",
    textAlign: "start",
  };

  return (
    <table
      style={{
        width: "100%",
        padding: "0px",
        ...style_obj,
      }}
    >
      <thead
        style={{
          backgroundColor: theme_bg,
          color: "#000",
          fontWeight: "600",
          fontSize: fontSize || "11px",
        }}
      >
        {heads?.length > 0 &&
          heads.map((el, index) => {
            return (
              <th key={index} style={th_col_style}>
                {el}
              </th>
            );
          })}
      </thead>
      <tbody
        style={{
          backgroundColor: "#FFF",
          color: "#000",
          fontSize: fontSize || "11px",
        }}
      >
        {rows?.length > 0 &&
          rows.map((el, index) => {
            return (
              <tr
                key={index}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (row_click_func) {
                    row_click_func(el);
                  }
                }}
              >
                {el?.length > 0 &&
                  el.map((li, ind) => {
                    // const style_obj = li.tr_style || {};
                    return (
                      <td
                        key={ind}
                        style={{ ...td_col_style, ...style_obj }}
                      >
                        {li}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const Comparison_table = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  const row_arr = [
    [`Surveys`, `Up to 5 questions`, `Up to 20 questions`, `Unlimited`],
    [`Survey Complexity`, `Basic`, `Any Complexity`, `Any Complexity`],
    [
      `Quota Management`,
      `Up to 50 responses`,
      `Up to 250 responses`,
      `Up to 1000 responses`,
    ],
    [
      `Reporting & Visualization`,
      `Basic Tools`,
      `Comprehensive Tools`,
      `In-Depth Analysis & Customization`,
    ],
    [
      `Group Discussions`,
      `Up to 5 per month (3 participants max)`,
      `Up to 10 per month (10 participants max)`,
      `Up to 15 per month (15 participants max)`,
    ],
    [`Customer Support`, `Basic`, `Dedicated`, `Priority`],
    [`Team Collaboration Tools`, `N/A`, `N/A`, `Included`],
    [`Incentive Credit (Smart-Coins)`, `10,000`, `100,000`, `500,000`],
  ];

  return (
    <Flex_box
      style={{
        flexWrap: "wrap",
        position: "relative",
        padding: "40px 0px",
      }}
    >
      <Flex_box
        style={{
          gap: 60,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          style={{
            width: max_w_1000 ? "90%" : "70%",
            minWidth: "200px",
            textWrap: "wrap",
            whiteSpace: "wrap",
            overflowX: "auto",
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            COMPARE OUR PLANS
          </b>
          <br className="max_100_hidden" />
          <br />

          <Table
            fontSize="16px"
            heads={[
              "Feature",
              `Starter(Free)`,
              `Standard($29/month)`,
              `Premium($99/month)$951/year (Save 20%)`,
            ]}
            rows={[...row_arr]}
          />
        </div>
      </Flex_box>
    </Flex_box>
  );
};

export default Comparison_table;
