import React, { useState } from "react";
import Text_Input from "../../../../utilities/Text_Input";
import Select_box from "../../../../utilities/Select_box";
import Flex_box from "../../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import Radio_Box from "../../../../utilities/Radio_Box";
import validate_object from "../../../../utilities/Validate_object";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { create_GD_api, update_GD_api } from "../../../../apis/apis";
import Button from "../../../../utilities/Button";

const GD_Form = () => {
  const { state } = useLocation();

  const gddata = state?.gd_data || "";

  // console.log({state});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user_data } = useSelector((state) => state?.authReducer);
  const [loading, setloading] = useState(false);

  const [gd_data, setgd_data] = useState(
    gddata
      ? {
          date: gddata?.date,
          DiscussionAbout: gddata?.discussionabout,
          GroupDiscussionName: gddata?.groupdiscussionname,
          Time: gddata?.time,
          _id: gddata?._id,
        }
      : {
          GroupDiscussionName: "",
          DiscussionAbout: "",
          date: "",
          Time: "",
        }
  );

  const [selected_media, setselected_media] = useState(
    gddata?.video == "true" ? "video" : "audio"
  );

  const { GroupDiscussionName, DiscussionAbout, date, Time } = gd_data;

  const upd_gd_data = (obj) => {
    setgd_data({
      ...gd_data,
      ...obj,
    });
  };

  const create_gd = () => {
    const is_validated = validate_object(gd_data);
    if (is_validated?.status == true) {
      const media_obj =
        selected_media == "audio"
          ? { audio: true, Video: false }
          : { Video: true, audio: false };

      setloading(true);

      create_GD_api({
        ...gd_data,
        ...media_obj,
        UserId: user_data?._id,
        StudyId: state?.studyid,
      })
        .then((res) => {
          console.log(res.data);
          setloading(false);
          navigate("/send-invite",{
           state:{ study_id:state?.studyid}
          });
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing Fields",
          msg: "Please enter " + is_validated?.key + " field!",
        })
      );
    }
  };

  const update_gd = () => {
    const is_validated = validate_object(gd_data);
    if (is_validated?.status == true) {
      const media_obj =
        selected_media == "audio"
          ? { audio: true, Video: false }
          : { Video: true, audio: false };

      setloading(true);

      update_GD_api({
        ...gd_data,
        ...media_obj,
      })
        .then((res) => {
          console.log(res.data);
          setloading(false);
          dispatch(
            alert_action({
              type: "success",
              title: "Success!",
              msg: "Successfully updated the group discussion",
            })
          );
          navigate(-1);
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing Fields",
          msg: "Please enter " + is_validated?.key + " field!",
        })
      );
    }
  };

  const handleSubmit = () => {
    if (gddata) {
      update_gd();
    } else {
      create_gd();
    }
  };

  return (
    <div
      className="responsive_width_35"
      style={{
        padding: "20px",
        maxHeight: "79vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        Create A Group Discussion
      </span>
      <Text_Input
        label={"Group Discussion Name"}
        input_style={{
          width: "35vw",
        }}
        value={GroupDiscussionName}
        onChange={(e) => {
          upd_gd_data({ GroupDiscussionName: e.target.value });
        }}
      />
      <Text_Input
        label={"Discussion About"}
        input_style={{
          width: "35vw",
          height: "60px",
        }}
        textArea={true}
        value={DiscussionAbout}
        onChange={(e) => {
          upd_gd_data({ DiscussionAbout: e.target.value });
        }}
      />
      <Flex_box
        className="responsive_width_35"
        style={{
          justifyContent: "space-between",
          width: "",
          gap: 10,
        }}
      >
        <Text_Input
          label={"Date"}
          type={"date"}
          input_style={{
            width: "90%",
          }}
          cont_style={{
            width: "100%",
          }}
          value={date}
          onChange={(e) => {
            upd_gd_data({ date: e.target.value });
          }}
        />
        <Text_Input
          label={"Time"}
          type={"time"}
          input_style={{
            width: "90%",
          }}
          cont_style={{
            width: "100%",
            justifyContent: "end",
          }}
          value={Time}
          onChange={(e) => {
            upd_gd_data({ Time: e.target.value });
          }}
        />
      </Flex_box>

      <div className="responsive_width_35">
        <b>Type of discussion</b>&nbsp;
        <span style={{ fontStyle: "italic" }}>(Select Only One)</span>
      </div>

      <Flex_box
        style={{
          alignItems: "start",
          justifyContent: "start",
          gap: 0,
        }}
      >
        <Radio_Box
          checked={selected_media == "audio"}
          onChange={(e) => {
            setselected_media("audio");
          }}
        />
        <div style={{ marginLeft: "-5px" }}>
          <b style={{ fontSize: "12px" }}>Audio</b>
          <br />
          <span style={{ fontSize: "10px" }}>
            10 participants for a smooth Audio Group calls
          </span>
        </div>
      </Flex_box>

      <Flex_box
        style={{
          alignItems: "start",
          justifyContent: "start",
          gap: 0,
        }}
      >
        <Radio_Box
          checked={selected_media == "video"}
          onChange={(e) => {
            setselected_media("video");
          }}
        />
        <div style={{ marginLeft: "-5px" }}>
          <b style={{ fontSize: "12px" }}>Video</b>
          <br />
          <span style={{ fontSize: "10px" }}>
            4 participants for a smooth Video Group calls
          </span>
        </div>
      </Flex_box>

      <Button
        id="btn_comp"
        style={{
          marginTop: "40px",
          fontWeight: 600,
        }}
        onClick={handleSubmit}
        label={gddata ? "Update" : "Publish"}
        loading={loading}
      />
    </div>
  );
};

export default GD_Form;
