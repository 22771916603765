import React, { useEffect, useState } from "react";
import Layout from "../../../../utilities/Layout";
import { send_notification_api, user_list_api } from "../../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import Button from "../../../../utilities/Button";
import { useLocation } from "react-router-dom";

const Resp_list = () => {
  const state = useLocation();

  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);
  const [user_list, setuser_list] = useState([]);

  const get_user_list = () => {
    user_list_api()
      .then((res) => {
        console.log({ res });
        if (res.data.status == 1) {
          setuser_list(
            res.data.Res?.map((el, index) => {
              return {
                index: index + 1,
                name: el?.firstname + " " + el?.lastname,
                email: el?.email,
                role: el?.role,
                _id: el?._id,
              };
            })
          );
        } else {
          dispatch(
            alert_action({
              type: "error",
              title: "Error",
              msg: "Some error occurred!",
            })
          );
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(get_user_list, []);

  const send_notification = (id) => {
    send_notification_api({
      UserId: user_data?._id,
      formId: "45345345fcgdfg",
      toId: [
        {
          id: id,
        },
      ],
      msg: state?.state?.msg,
      title: "Study Invitation",
      type: "Study",
      notificatonTypeId: state?.state?.study_id,
      sender_type: 1,
    })
      .then((res) => {
        dispatch(
          alert_action({
            type: "success",
            title: "Success",
            msg: "Invitation sent successfully!",
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const t_heads = [
    {
      label: "S No.",
      accessor: "index",
    },
    {
      label: "Name",
      accessor: ["firstname", "lastname"],
      accessor_type: "array",
    },
    {
      label: "Email",
      accessor: "email",
    },
  ];

  const style_obj = {};

  const th_col_style = {
    padding: "18px 0px 18px 15px",
    textAlign: "start",
    borderRadius: "10px",
  };

  const td_col_style = {
    padding: "10px 0px 0px 15px",
    textAlign: "start",
  };
  
  return (
    <Layout>
      <table
        style={{
          width: "100%",
          padding: "0px",
          ...style_obj,
        }}
      >
        <thead
          style={{
            backgroundColor: "#F5FAFF",
            color: "#000",
            fontWeight: "600",
            fontSize: "11px",
          }}
        >
          {t_heads?.length > 0 &&
            t_heads.map((el, index) => {
              return (
                <th key={index} style={th_col_style}>
                  {el?.label}
                </th>
              );
            })}
        </thead>
        <tbody
          style={{
            backgroundColor: "#FFF",
            color: "#000",
            fontSize: "11px",
          }}
        >
          {user_list?.length > 0 &&
            user_list.map((el, index) => {
              return (
                <tr key={index}>
                  <td style={td_col_style}>{el?.index}</td>
                  <td style={td_col_style}>{el?.name}</td>
                  <td style={td_col_style}>{el?.email}</td>
                  <td style={td_col_style}>
                    <Button
                      style={{
                        width: "100px",
                      }}
                      label={"Send"}
                      onClick={() => {
                        send_notification(el?._id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Layout>
  );
};

export default Resp_list;
