export const state_arr = [
  { state: "Abia" },
  { state: "Adamawa" },
  { state: "Akwa Ibom" },
  { state: "Anambra" },
  { state: "Bauchi" },
  { state: "Bayelsa" },
  { state: "Benue" },
  { state: "Borno" },
  { state: "Cross River" },
  { state: "Delta" },
  { state: "Ebonyi" },
  { state: "Edo" },
  { state: "Ekiti" },
  { state: "Enugu" },
  { state: "Gombe" },
  { state: "Imo" },
  { state: "Jigawa" },
  { state: "Kaduna" },
  { state: "Kano" },
  { state: "Katsina" },
  { state: "Kebbi" },
  { state: "Kogi" },
  { state: "Kwara" },
  { state: "Lagos" },
  { state: "Nasarawa" },
  { state: "Niger" },
  { state: "Ogun" },
  { state: "Ondo" },
  { state: "Osun" },
  { state: "Oyo" },
  { state: "Plateau" },
  { state: "Rivers" },
  { state: "Sokoto" },
  { state: "Taraba" },
  { state: "Yobe" },
  { state: "Zamfara" },
  { state: "Federal Capital Territory (FCT)" },
];

export const industry_arr = [
  { label: "Agriculture", value: 1 },
  { label: "Automotive", value: 2 },
  { label: "Banking", value: 3 },
  { label: "Chemical", value: 4 },
  { label: "Construction", value: 5 },
  { label: "Education", value: 6 },
  { label: "Energy", value: 7 },
  { label: "Entertainment", value: 8 },
  { label: "Fashion", value: 9 },
  { label: "Food and Beverage", value: 10 },
  { label: "Healthcare", value: 11 },
  { label: "Hospitality", value: 12 },
  { label: "Information Technology", value: 13 },
  { label: "Manufacturing", value: 14 },
  { label: "Media", value: 15 },
  { label: "Mining", value: 16 },
  { label: "Pharmaceuticals", value: 17 },
  { label: "Real Estate", value: 18 },
  { label: "Retail", value: 19 },
  { label: "Telecommunications", value: 20 },
  { label: "Transportation", value: 21 },
  { label: "Utilities", value: 22 },
  { label: "Aerospace", value: 23 },
  { label: "Defence", value: 24 },
  { label: "Insurance", value: 25 },
  { label: "Financial Services", value: 26 },
  { label: "Legal Services", value: 27 },
  { label: "Consulting", value: 28 },
  { label: "Advertising", value: 29 },
  { label: "Environmental Services", value: 30 },
];

export const get_industry_name = (val) => {
  return industry_arr.filter((el) => {
    return el?.value == parseInt(val);
  })[0]?.label;
};

export const get_sec_value = (val) => {
  if (val >= 80 && val <= 100) {
    return "A";
  }
  if (val >= 71 && val <= 79) {
    return "AB";
  }
  if (val >= 66 && val <= 70) {
    return "B";
  }
  if (val >= 61 && val <= 65) {
    return "BC1";
  }
  if (val >= 56 && val <= 60) {
    return "C1";
  }
  if (val >= 51 && val <= 55) {
    return "C1C2";
  }
  if (val >= 46 && val <= 50) {
    return "C1C2D";
  }
  if (val >= 41 && val <= 45) {
    return "C2";
  }
  if (val >= 36 && val <= 40) {
    return "C2D";
  }
  if (val >= 31 && val <= 35) {
    return "C2DE";
  }
  if (val >= 26 && val <= 30) {
    return "D";
  }
  if (val >= 21 && val <= 25) {
    return "DE";
  }
  if (val >= 0 && val <= 20) {
    return "E";
  }
};

export const secValues = [
  { sec: "A" },
  { sec: "AB" },
  { sec: "B" },
  { sec: "BC1" },
  { sec: "C1" },
  { sec: "C1C2" },
  { sec: "C1C2D" },
  { sec: "C2" },
  { sec: "C2D" },
  { sec: "C2DE" },
  { sec: "D" },
  { sec: "DE" },
  { sec: "E" },
];

export const doc_type_options = [
  {
    value: 1,
    label: "Passport",
  },
  {
    value: 2,
    label: "Driving License",
  },
];

export const get_doc_type = (val) => {
  return doc_type_options[parseInt(val) - 1]?.label;
};
