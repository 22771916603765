import React, { useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import landing_bg from "../../../assets/Path1.png";
import { useMediaQuery } from "@mui/material";

const Welcome_Comp = ({ open_modal }) => {
  const max_w_850 = useMediaQuery("(max-width:850px)");

  return (
    <Flex_box
      className="auth_box_cont intro_comp_cont"
      style={{
        // height: "86vh",
        // gap: 50,
        flexWrap: "wrap",
        position: "relative",
        backgroundImage: `url(${landing_bg})`,
      }}
    >
      {!max_w_850 && (
        <>
          <img
            style={{
              position: "absolute",
              top: "-80px",
              left: "0px",
              height: "700px",
              zIndex: 100,
            }}
            src={landing_mask}
            className="hover_img"
          />
          <img
            style={{
              position: "absolute",
              //   top: "-110px",
              right: "0px",
              // height: "700px",
              width: "47%",
              height: "100%",
              zIndex: 101,
            }}
            className="hover_img"
            src={right_mask}
          />
        </>
      )}
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="intro_text_cont"
          style={{
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            The Story Behind Yaarnbox
          </b>
          <br className="max_100_hidden" />
          <br />
          <span
            className="intro_sub_text"
            style={{ fontStyle: "italic", fontWeight: 500 }}
          >
            Yaarnbox was born from a vision to bridge the gap between traditional
            market research methods and the fast-paced digital world. We believe
            that research should be accessible, and efficient, and deliver
            actionable insights that drive real-world results.
          </span>
          <br />
          <br className="max_100_hidden" />
          <span className="intro_sub_text" style={{ fontStyle: "italic" }}>
            Our team of passionate researchers and developers saw a need for a
            platform that empowered both researchers and respondents. With
            Yaarnbox, researchers can access powerful tools to conduct
            high-quality studies, while respondents gain a convenient and
            rewarding platform to contribute their valuable perspectives.
          </span>
        </div>
        <div
          className="max_100_hidden"
          style={{
            width: "40%",
            minWidth: "200px",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              gap: 20,
            }}
          >
            <img
              src={welcome_icon}
              style={{
                height: "60vh",
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

export default Welcome_Comp;
