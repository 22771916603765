import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Resp_box from "./Resp_box";

const Resp_Page = ({ arr }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
        marginTop: "15px",
      }}
    >
      {arr?.length > 0 &&
        arr?.map((item, index) => {
          return (
            <Flex_box
              className="responsive_width_35"
              style={{
                padding: "5px 15px 18px 15px",
                borderRadius: "10px",
                boxShadow: "0px 5px 10px #0000000D",
                width: "",
                flexDirection: "column",
                alignItems: "start",
                margin: "15px 0px 20px 0px",
              }}
            >
              {item?.sub_questions?.length > 0 && (
                <>
                  <span>
                    <span style={{ fontSize: "12px", fontWeight: "600" }}>
                      Q{index + 1}
                    </span>
                    &nbsp;
                    <span style={{ fontSize: "12px" }}>
                      {item?.questiontitle}
                    </span>
                  </span>
                  {item?.sub_questions?.map((li, ind) => {
                    return (
                      <Resp_box item={li} index={ind} subquestion={true} />
                    );
                  })}
                </>
              )}
              {!(item?.sub_questions?.length) && (
                <Resp_box item={item} index={index} />
              )}
            </Flex_box>
          );
        })}
    </Flex_box>
  );
};

export default Resp_Page;
