import React from "react";
import Flex_box from "../../../utilities/flex_box";
import bg from "../../../assets/Path37581.png";
import Button from "../../../utilities/Button";
import researcher_icon from "../../../assets/Group 48352.svg";
import respondent_icon from "../../../assets/Group 48287.svg";
import { useDispatch } from "react-redux";
import { set_login_type_action } from "../../../redux_prog/actions/auth_action";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const For_Researchers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Flex_box
      style={{
        flexWrap: "wrap",
        position: "relative",
        backgroundImage: `url(${bg})`,
      }}
    >
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="intro_text_cont"
          style={{
            // minWidth: "200px",
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            For Researchers
          </b>
          <br className="max_100_hidden" />
          <br />
          <b className="intro_sub_text" style={{ fontStyle: "italic" }}>
            Deliver your studies to qualified respondents based on demographics
            and preferences, ensuring high-quality data collection and fast
            delivery.
          </b>
          <br />
          <br />
          <span style={{ fontStyle: "italic", fontSize: "14px" }}>
            Boost your Efficiency by Streamlining your research process, saving
            valuable time and resources.
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Effortlessly Design Studies:</b>&nbsp;
            <span>
              Craft engaging surveys and facilitate interactive group
              discussions with our user-friendly tools.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Gain Deeper Insights:</b>&nbsp;
            <span>
              Leverage powerful data visualization and analytics to transform
              collected data into actionable insights.
            </span>
          </span>

          <Flex_box
            style={{
              width: "",
              boxSizing: "border-box",
              justifyContent: "start",
              gap: 20,
              marginTop: "20px",
            }}
          >
            <Button
              style={
                {
                  // width: "120px"
                }
              }
              onClick={() => {
                dispatch(set_login_type_action(2));
                navigate("/signup");
              }}
              label={" Sign Up as a researcher"}
            />
          </Flex_box>
        </div>
        <div
          className="max_100_hidden"
          style={{
            width: "40%",
            minWidth: "200px",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              gap: 20,
            }}
          >
            <img
              src={researcher_icon}
              style={{
                height: "55vh",
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

const For_Respondants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <Flex_box
      style={{
        flexWrap: "wrap",
        position: "relative",
        marginTop: max_w_1000 ? "20px" : "",
      }}
    >
      <Flex_box
        style={{
          gap: 60,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="max_100_hidden"
          style={{
            width: "40%",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              minWidth: "200px",
              gap: 20,
            }}
          >
            <img
              src={respondent_icon}
              style={{
                height: "55vh",
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>

        <div
          className="intro_text_cont"
          style={{
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            For Researchers
          </b>
          <br className="max_100_hidden" />
          <br />
          <b className="intro_sub_text" style={{ fontStyle: "italic" }}>
            Deliver your studies to qualified respondents based on demographics
            and preferences, ensuring high-quality data collection and fast
            delivery.
          </b>
          <br />
          <br />
          <span style={{ fontStyle: "italic", fontSize: "14px" }}>
            Boost your Efficiency by Streamlining your research process, saving
            valuable time and resources.
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Effortlessly Design Studies:</b>&nbsp;
            <span>
              Craft engaging surveys and facilitate interactive group
              discussions with our user-friendly tools.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Gain Deeper Insights:</b>&nbsp;
            <span>
              Leverage powerful data visualization and analytics to transform
              collected data into actionable insights.
            </span>
          </span>

          <Flex_box
            style={{
              width: "",
              boxSizing: "border-box",
              justifyContent: "start",
              gap: 20,
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                width: "350px",
              }}
              onClick={() => {
                dispatch(set_login_type_action(3));
                navigate("/signup");
              }}
              label={"Sign Up to take part in surveys and start earning"}
            />
          </Flex_box>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

const Why_SmartQ = () => {
  return (
    <>
      <For_Researchers />
      <For_Respondants />
    </>
  );
};

export default Why_SmartQ;
