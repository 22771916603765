import React, { useState } from "react";
import { theme_color } from "../../../utilities/colors";
import logo_white from "../../../assets/Group 40944@2x.png";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import Nav_Link from "../Nav_Comp/Nav_Link";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import ham_menu from "../../../assets/ham_menu.png";
import { useMediaQuery } from "@mui/material";
import { sidebar_action } from "../../../redux_prog/actions/base_action";
// import "./Sidebar/sidebar.css";

const Nav_Comp = ({ open_modal }) => {
  const visible = useSelector((state) => state?.baseReducer?.sidebar_open);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const max_w_500 = useMediaQuery("(max-width:500px)");

  return (
    <Flex_box
      style={{
        justifyContent: "center",
        backgroundColor: "#fff",
        zIndex: 105,
        borderBottom: "1px solid #e5e5e5",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
          padding: "20px 0px",
          width: "90%",
        }}
      >
        <Flex_box
          style={{
            width: "",
          }}
        >
          <img
            src={logo_white}
            onClick={() => {
              navigate("/");
            }}
            style={{
              height: max_w_500 ? "40px" : "50px",
              cursor: "pointer",
            }}
          />
        </Flex_box>
        <Sidebar />
        <div className="normal_nav">
          <Flex_box
            style={{
              width: "",
              boxSizing: "border-box",
              gap: 40,
            }}
          >
            <Nav_Link id="nav_link_1" label="Home" to="/" />
            <Nav_Link id="nav_link_2" label="Abouts Us" to="/about-us" />
            <Nav_Link id="nav_link_3" label="Features" to={"/features"} />
            <Nav_Link id="nav_link_4" label="Pricing" to={"/pricing"} />
            <Nav_Link id="nav_link_5" label="Support" to={"/support"} />
          </Flex_box>
        </div>
        <Flex_box
          style={{
            width: "",
            boxSizing: "border-box",
          }}
        >
          <Button
            style={{
              width: max_w_500 ? "90px" : "120px",
              fontSize: max_w_500 ? "10px" : "13px",
              backgroundImage:
                `linear-gradient(to right, ` + theme_color + ` , #3a99eb)`,
              color: "#fff",
              border: "none",
            }}
            onClick={open_modal}
            label={"Sign up / Login"}
          />
          <img
            src={ham_menu}
            id="sidebar_toggle"
            onClick={() => {
              dispatch(sidebar_action(true));
            }}
            style={{
              height: "30px",
              marginLeft: "20px",
              cursor: "pointer",
            }}
          />
        </Flex_box>
      </Flex_box>
    </Flex_box>
  );
};

export default Nav_Comp;
