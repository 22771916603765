import React from 'react'
import Layout from '../../../../utilities/Layout'
import Invite_Comp from './Invite_Comp'

const Send_Invite = () => {
    return (
        <Layout>
            <Invite_Comp />
        </Layout>
    )
}

export default Send_Invite
