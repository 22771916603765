export const researcher_route_labels = [
    {
        path: '/dashboard',
        label: 'Dashboard'
    },
    {
        path: '/my-gds',
        label: 'My Group Discussions'
    },
    {
        path: '/study-intro',
        label: 'My Studies'
    },
    {
        path: '/faq',
        label: 'FAQ'
    },
    {
        path: '/kyc',
        label: 'KYC'
    },
    // {
    //     path: '/chat-comp',
    //     label: 'Chat'
    // },
    // {
    //     path: '/video-chat-comp',
    //     label: 'Video Chat Example'
    // },
];

export const respondent_route_labels = [
    {
        path: '/dashboard',
        label: 'Dashboard'
    },
    {
        path: '/my-studies',
        label: 'My Studies'
    },
    {
        path: '/my-balance',
        label: 'My Balance'
    },
    {
        path: '/faq',
        label: 'Support'
    },
    // {
    //     path: '/chat-comp',
    //     label: 'Chat Example'
    // },
    // {
    //     path: '/video-chat-comp',
    //     label: 'Video Chat Example'
    // },
];