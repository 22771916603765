import React from 'react'
import Sidebar from '../../Nav_Comp/Sidebar'
import Flex_box from '../../../utilities/flex_box'
import Navbar from '../../Nav_Comp/Navbar'
import Dash_comp from './Dash_comp'
import Footer from '../../Nav_Comp/Footer'
import Support_Chat from '../../Support_Chat'

const Dashboard = () => {
    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <Flex_box
                style={{
                    justifyContent: "start"
                }}
            >
                <Sidebar />
                <div
                    className='comp_container'
                    style={{
                        position: 'relative'
                    }}
                >
                    <Navbar />
                    <Dash_comp />
                    <Footer />
                </div>
            </Flex_box>
            <Support_Chat />
        </div>
    )
}

export default Dashboard
