import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Progress_bar from "../../../utilities/Progress_bar";
import { get_percentage } from "../../../utilities/Get_percentage";

const Progress_comp = ({ item, total_answers }) => {
  const percentage = get_percentage(item.y, total_answers) || 0;

  return (
    <Flex_box
      style={{
        justifyContent: "start",
        alignItems: "",
        gap: 10,
        marginTop: "10px",
      }}
    >
      <p
        style={{
          fontSize: "10px",
          fontWeight: "600",
          width: "45px",
        }}
      >
        {percentage}%
      </p>
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          gap: 8,
        }}
      >
        <p
          style={{
            fontSize: "10px",
            fontWeight: "400",
          }}
        >
          {item?.label}
        </p>
        <Progress_bar progress={percentage + "%"} />
      </Flex_box>
    </Flex_box>
  );
};

const Resp_box = ({ item, index, subquestion }) => {
  return (
    <div
      style={{
        marginTop: "8px",
      }}
    >
      <span>
        <span
          style={{ fontSize: subquestion ? "11px" : "12px", fontWeight: "600" }}
        >
          {subquestion ? "Sub-Q" : "Q"}
          {index + 1}
        </span>
        &nbsp;
        <span style={{ fontSize: subquestion ? "11px" : "12px" }}>
          {item?.questiontitle}
        </span>
      </span>
      {item?.inp_ans > 0 && (
        <>
        <br/>
        <span
          style={{
            fontSize: "12px",
            opacity: "0.7",
          }}
        >
          Answered by: {item?.inp_ans}
        </span>
        </>
      )}
      {item?.graph_data?.length > 0 &&
        item?.graph_data?.map((el, ind) => {
          return (
            <Progress_comp item={el} total_answers={item?.total_answers} />
          );
        })}
    </div>
  );
};

export default Resp_box;
