import React from 'react'
import Flex_box from '../../../../utilities/flex_box'
import share_icon from "../../../../assets/Icon open-share-boxed.svg"

const Chat_Header = () => {
    return (
        <Flex_box
            style={{
                justifyContent: "space-between"
            }}
        >
            <b style={{
                fontSize: "22px"
            }}>
                Study on Industry Group Discussion
            </b>
            <button
                id="btn_comp"
                style={{
                    width: "40px",
                    height:"40px"
                    // padding:"0px"
                }}
            >
                <img src={share_icon}
                    style={{
                        height: "15px"
                    }}
                />
            </button>
        </Flex_box>
    )
}

export default Chat_Header
