import React from 'react';
import loading_icon from "../assets/loading.gif";

const Loading = () => {
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                opacity: 0.5
            }}
        >
            <img
                src={loading_icon}
                style={{
                    height: "60px"
                }}
            />
        </div>
    )
}

export default Loading
