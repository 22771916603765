import React from 'react'
import Points_Comp from '../Components/Points_Comp';
import researcher_icon from '../../../assets/Group 48352.svg';

const User_Experiance = () => {
    return (
        <Points_Comp
            label={'User-Friendly Experience:'}
            sub_text_arr={[
                { text: 'Navigate the platform with ease, regardless of your technical expertise. Our user-friendly interface allows you to manage your research projects and participate in studies effortlessly.', bold: true }
            ]}
            points_arr={[
                {
                    heading: 'Seamless Account Management',
                    text: 'Sign up for free as a researcher or respondent and manage your profile information, preferences, and communication settings within a centralized hub.'
                }
            ]}
            image={researcher_icon}
        />
    )
}

export default User_Experiance
