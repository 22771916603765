import React, { useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import File_input from "../../../utilities/File_input";
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from "../../../utilities/Text_Input";
import { useLocation, useNavigate } from "react-router-dom";
import Radio_Box from "../../../utilities/Radio_Box";
import Auth_header from "../Auth_header";
import { useDispatch, useSelector } from "react-redux";
import {
  login_action,
  set_login_type_action,
  set_otp_action,
  set_user_action,
} from "../../../redux_prog/actions/auth_action";
import { send_otp_email_api, signup_api } from "../../../apis/apis";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import { convert_into_base64 } from "../../../utilities/Image_conv";
import Button from "../../../utilities/Button";
import { generate_otp, read_stream } from "../../../utilities/Generate_OTP";

const Sign_up = () => {
  const navigate = useNavigate();
  const path_state = useLocation();
  const study_id = path_state?.state?.study_id;
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const dispatch = useDispatch();

  const [user_data, setuser_data] = useState({});
  const [loading, setloading] = useState(false);
  const [user_image, setuser_image] = useState(null);

  const upd_user_data = (key, val) => {
    setuser_data({
      ...user_data,
      [key]: val,
    });
  };

  const { email, user_name, first_name, last_name, password, c_password } =
    user_data;

  const sign_up = () => {
    if (email && first_name && last_name && user_name && password) {
      if (password == c_password) {
        const auth_otp = generate_otp(4);
        setloading(true);
        send_otp_email_api({ email, otp: auth_otp })
          .then((res) => {
            setloading(false);
            if (res.data.status == 1) {
              dispatch(
                set_user_action({
                  Email: email,
                  Password: password,
                  UserName: user_name,
                  FirstName: first_name,
                  LastName: last_name,
                  Role: login_type,
                  profilepictuer: user_data?.profilepictuer,
                })
              );
              dispatch(set_otp_action(res.data.index));
              alert("Your one time password is => " + auth_otp);
              navigate("/otp-verify", {
                state: { study_id },
              });
            } else {
              dispatch(
                alert_action({
                  title: `Duplicate Email`,
                  type: "error",
                  msg: "Sorry! This email already exists!",
                })
              );
            }
          })
          .catch((err) => {
            setloading(false);
            console.error(err);
          });
      } else {
        dispatch(
          alert_action({
            title: `Passwords don't match`,
            type: "error",
            msg: "Make sure password and confirm password match!",
          })
        );
      }
    } else {
      dispatch(
        alert_action({
          title: `Invalid Credentials`,
          type: "error",
          msg: "Fill all the fields!",
        })
      );
    }
  };

  return (
    <div
      className="auth_box_cont"
      style={{
        overflowY: "auto",
        padding: "150px 0px",
        alignItems: "start",
        maxHeight: "60vh",
      }}
    >
      <div
        className="auth_box"
        style={{
          padding: "15px",
          // backgroundColor:"red"
        }}
      >
        <Auth_header label={"Sign Up"} />

        <span
          className="responsive_width"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            padding: "25px 0px",
          }}
        >
          We'll keep this private, like everything you share with us.
        </span>

        <Flex_box style={{ gap: 50, marginBottom: "30px" }}>
          <Radio_Box
            label={"RESEARCHER"}
            value="2"
            checked={login_type == 2}
            onChange={(e) => {
              dispatch(set_login_type_action(e));
            }}
          />
          <Radio_Box
            label={"RESPONDENT"}
            value="3"
            checked={login_type == 3}
            onChange={(e) => {
              dispatch(set_login_type_action(e));
            }}
          />
        </Flex_box>

        <File_input
          onChange={(e) => {
            // read_stream(e[0], (res) => {
            //   upd_user_data("profilepictuer", res);
            // });

            upd_user_data("profilepictuer", e[0]);
            setuser_image(URL.createObjectURL(e[0]));

            // convert_into_base64(e[0], (base64) => {
            //     console.log({ base64 });
            // })
          }}
          renderItem={
            user_image ? (
              <img
                src={user_image}
                style={{
                  height: "120px",
                  width: "120px",
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    background: theme_bg,
                    border: "1px dashed " + theme_color,
                    borderRadius: 10,
                    padding: "25px 30px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={upload_img}
                    style={{
                      height: "50px",
                    }}
                  />
                </button>
                <b
                  style={{
                    color: theme_color,
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Upload Profile Picture
                </b>
              </div>
            )
          }
        />

        <Text_Input
          label={"First Name"}
          cont_style={{
            marginTop: "30px",
            width: "90%",
          }}
          value={first_name}
          onChange={(e) => {
            upd_user_data("first_name", e.target.value);
          }}
        />
        <Text_Input
          label={"Last Name"}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          value={last_name}
          onChange={(e) => {
            upd_user_data("last_name", e.target.value);
          }}
        />
        <div style={{ width: "90%" }}>
          <Text_Input
            label={"Username"}
            cont_style={{
              marginTop: "18px",
              width: "100%",
            }}
            value={user_name}
            onChange={(e) => {
              upd_user_data("user_name", e.target.value);
            }}
          />
          <span style={{ marginLeft: "10px" }}>
            <b style={{ color: "#000", fontSize: "11px" }}>Suggestions:</b>
            &nbsp;
            <span style={{ color: theme_color, fontSize: "11px" }}>
              Johndoe, Johndoe1, john.doe
            </span>
          </span>
        </div>
        <Text_Input
          label={"Email"}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          value={email}
          onChange={(e) => {
            upd_user_data("email", e.target.value);
          }}
        />
        <Text_Input
          label={"Password"}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          type={"Password"}
          value={password}
          onChange={(e) => {
            upd_user_data("password", e.target.value);
          }}
        />
        <div style={{ width: "90%" }}>
          <Text_Input
            label={"Confirm Password"}
            cont_style={{
              marginTop: "18px",
              width: "100%",
            }}
            type={"Password"}
            value={c_password}
            onChange={(e) => {
              upd_user_data("c_password", e.target.value);
            }}
          />
          <div
            style={{
              marginLeft: "10px",
              width: "300px",
              whiteSpace: "wrap",
              lineHeight: "12px",
              marginTop: "5px",
            }}
          >
            <span style={{ color: "#000", fontSize: "9px" }}>
              By clicking Sign Up, you are agreeing to Workout partner's{" "}
              <span style={{ color: theme_color, textDecoration: "underline" }}>
                Terms of Service
              </span>{" "}
              and are acknowledging our{" "}
              <span style={{ color: theme_color, textDecoration: "underline" }}>
                Privacy Policy
              </span>
            </span>
          </div>
        </div>

        <Button
          id="btn_comp"
          style={{
            marginTop: "40px",
          }}
          onClick={() => {
            sign_up();
          }}
          label={"Sign Up"}
          loading={loading}
        ></Button>

        <span style={{ fontSize: "10px", marginTop: "5px" }}>
          Already have an account?
          <b
            onClick={() => {
              navigate("/login");
            }}
            style={{ color: theme_color, cursor: "pointer" }}
          >
            Log In
          </b>
        </span>
      </div>
    </div>
  );
};

export default Sign_up;
