import React from "react";
import Flex_box from "./flex_box";
import Sidebar from "../screens/Nav_Comp/Sidebar";
import Navbar from "../screens/Nav_Comp/Navbar";
import Footer from "../screens/Nav_Comp/Footer";

const Layout = (props) => {
  const style = props.style || {};
  const { main_page_only } = props;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <Flex_box
      className="removescrollbar"
      style={{
        justifyContent: "start",
        // paddingBottom:"50px"
      }}
    >
      {!main_page_only && <Sidebar no_sidebar={props.no_sidebar} />}
      <div
        className="comp_container"
        style={{
          position: "relative",
          width: main_page_only ? "100%" : "",
        }}
      >
        {!main_page_only && <Navbar />}
        <div
          className="removescrollbar"
          style={{
            width: "100%",
            height: main_page_only ? "100vh" : "78.5vh",
            overflowY: "auto",
            boxSizing: "border-box",
            ...style,
          }}
        >
          {props.children}
        </div>
        {!main_page_only && <Footer />}
      </div>
    </Flex_box>
  );
};

export default Layout;
