import React, { useEffect, useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Layout from "../../../utilities/Layout";
import Subscription_Box from "./Subscription_Box";
import { get_subscriptions_api } from "../../../apis/apis";

const Select_Sub_Page = () => {
  const [subscriptions, setsubscriptions] = useState([]);
  const get_subscriptions = () => {
    get_subscriptions_api()
      .then((res) => {
        setsubscriptions(res.data.Res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const first_sub = subscriptions?.length > 0 ? subscriptions[0] : "";

  useEffect(get_subscriptions, []);

  return (
    <Layout no_sidebar={true}>
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "start",
          position: "fixed ",
          top: "120px",
          right: "0px",
          height: "85vh",
          width: "100vw",
          lineHeight: 1.8,
          paddingTop: "20px",
        }}
      >
        <b style={{ fontSize: "24px" }}>Subscriptions</b>
        <span style={{ fontSize: "14px" }}>
          Unlock unlimited access to all the features
        </span>

        <Flex_box style={{ gap: 40, marginTop: "25px" }}>
          <Subscription_Box
            label={"Full access"}
            subtext={"from $" + first_sub?.Amount + "/" + first_sub?.Cycle}
            content_arr={[
              `Unlimited video Call`,
              `Unlimited voice Call`,
              `Access to all features`,
              `Lorem ipsum dolor sit amet`,
            ]}
            btn_text={"See Pricing"}
            path={"/sub-price"}
            data={subscriptions}
          />
          <Subscription_Box
            label={"Continue Free"}
            subtext={<span>&nbsp;</span>}
            content_arr={[
              <span>100 Minutes voice call</span>,
              <span>100 Minutes of video call</span>,
              <span>&nbsp;</span>,
              <span>&nbsp;</span>,
            ]}
            btn_text={"Continue"}
            path={"/dashboard"}
          />
        </Flex_box>
      </Flex_box>
    </Layout>
  );
};

export default Select_Sub_Page;
