import React, { useState } from "react";
import { Modal } from "../../../../../../utilities/Modal";
import Flex_box from "../../../../../../utilities/flex_box";
import user_icon from "../../../../../../assets/user_dummy.png";
import pie_chart_icon from "../../../../../../assets/pie-chart.png";
import Radio_Box from "../../../../../../utilities/Radio_Box";
import Progress_bar from "../../../../../../utilities/Progress_bar";
import { get_iso_date } from "../../../../../../utilities/Date_Util";

const Poll_Details = ({
  poll_detail_modal,
  setpoll_detail_modal,
  poll_detail,
  gd_data,
}) => {

  const total_ans_given = poll_detail?.options
    ?.map((elem) => {
      return elem?.responses;
    })
    ?.flat();

  const res_user_count = [...new Set(total_ans_given?.map((el) => {
    return el?.user_id;
  }))];



  return (
    <Modal
      contentStyle={{
        width: "300px",
        height: "70vh",
        overflowY: "auto",
      }}
      isOpen={poll_detail_modal}
    >
      <Flex_box
        style={{
          paddingBottom: "10px",
          borderBottom: "1px solid lightgrey",
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontWeight: 500,
            color: "#000",
          }}
        >
          Poll
        </span>

        <span
          style={{
            fontSize: "22px",
            fontWeight: 500,
            color: "#000",
            position: "absolute",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setpoll_detail_modal(false);
          }}
        >
          &times;
        </span>
      </Flex_box>

      <Flex_box
        style={{
          padding: "10px 0px",
          borderBottom: "1px solid lightgrey",
          position: "relative",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        <img
          src={user_icon}
          style={{
            height: "25px",
          }}
        />
        <span
          style={{
            fontSize: "13px",
          }}
        >
          You sent a poll to {gd_data?.groupdiscussionname}
        </span>
      </Flex_box>
      <Flex_box
        style={{
          padding: "10px 0px",
          borderBottom: "1px solid lightgrey",
          position: "relative",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        <img
          src={pie_chart_icon}
          style={{
            height: "25px",
          }}
        />
        <span
          style={{
            fontSize: "13px",
          }}
        >
          {res_user_count?.length} of {gd_data?.g_requests_lookup?.length} people voted in your poll
        </span>
      </Flex_box>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            // color: "grey",
          }}
        >
          QUESTION
        </span>
        <br />
        <span
          style={{
            fontSize: "13px",
            // fontWeight: 500,
            // color: "grey",
          }}
        >
          {poll_detail?.question}
        </span>
      </div>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            // color: "grey",
          }}
        >
          EXPIRATION
        </span>
        <br />
        <span
          style={{
            fontSize: "13px",
            // fontWeight: 500,
            // color: "grey",
          }}
        >
          {get_iso_date(poll_detail?.exp_date)} at {poll_detail?.exp_time}
        </span>
      </div>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            // color: "grey",
          }}
        >
          RESPONSES
        </span>
        <br />
        {poll_detail?.options?.length > 0 &&
          poll_detail?.options?.map((el, index) => {
            const opt_res = total_ans_given?.filter((li) => {
              return li?.option_id == el?._id;
            });

            return (
              <Flex_box
                key={index}
                style={{
                  justifyContent: "start",
                  alignItems: "",
                  gap: 10,
                  marginTop: "5px",
                  borderBottom: "1px solid lightgrey",
                  padding: "10px 0px",
                  // width:"200px"
                }}
              >
                <Radio_Box
                  radio_style={{
                    border: "2px solid grey",
                  }}
                  checked={false}
                />
                <Flex_box
                  style={{
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    gap: 8,
                    // width:"150px"
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    {el?.option + " (" + opt_res?.length + ")"}
                  </span>
                  <Progress_bar
                    style={{
                      width: "250px",
                    }}
                    progress={
                      (opt_res?.length / total_ans_given?.length) * 100 + "%"
                    }
                  />
                </Flex_box>
              </Flex_box>
            );
          })}
      </div>
    </Modal>
  );
};

export default Poll_Details;
