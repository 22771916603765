import React from "react";
import Flex_box from "../../../utilities/flex_box";
import features_bg from "../../../assets/Group48288.png";
import { theme_bg, theme_color } from "../../../utilities/colors";
import grp_icon from "../../../assets/grp_icon_blue.png";
import poll_icon_blue from "../../../assets/poll_icon_blue.png";
import whiteboard_icon_blue from "../../../assets/whiteboard_icon_blue.svg";
import chat_icon_blue from "../../../assets/chat_icon_blue.svg";
import voice_icon_blue from "../../../assets/voice_icon_blue.svg";
import video_icon_blue from "../../../assets/video_icon_blue.svg";

const Feature_box = ({ el }) => {
  const { label, icon } = el;
  return (
    <div
      className="other_feature_box"
      style={{
        gap: 12,
        position: "relative",
        backgroundColor: theme_bg,
      }}
    >
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 30,
        }}
      >
        <img
          src={icon}
          style={{
            height: "40px",
          }}
          className="hover_img"
        />
        <div>
          <span className="intro_sub_text">
            <span style={{ fontWeight: 500 }}>{label}</span>
          </span>
        </div>
      </Flex_box>
    </div>
  );
};

const Other_Features = () => {

  const other_feature_arr = [
    {
      icon: voice_icon_blue,
      label: "Voice Calling",
    },
    {
      icon: video_icon_blue,
      label: "Video Calling",
    },
    {
      icon: chat_icon_blue,
      label: "Chatting",
    },
    {
      icon: poll_icon_blue,
      label: "Polls",
    },
    {
      icon: grp_icon,
      label: "Group Discussion",
    },
    {
      icon: whiteboard_icon_blue,
      label: "Surveys",
    },
  ];

  return (
    <Flex_box
      className="auth_box_cont intro_comp_cont"
      style={{
        flexDirection: "column",
        backgroundImage: `url(${features_bg})`,
        height: "100%",
      }}
    >
      <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
        Everything you need to get the people's opinion on anything
      </b>
      <span className="intro_sub_text" style={{ fontStyle: "italic" }}>
        Create your surveys and studies withmultiple options
      </span>
      <div className="other_feature_cont">
        <Flex_box
          style={{
            padding: "2px",
            flexWrap: "wrap",
            gap: 10,
            marginTop: "30px",
            overflowX: "auto",
            overflowY: "hidden",
            justifyContent: "space-between",
          }}
        >
          {other_feature_arr?.map((el, index) => {
            return <Feature_box el={el} key={index} />;
          })}
        </Flex_box>
      </div>
    </Flex_box>
  );
};

export default Other_Features;
