import React from 'react'
import Welcome_Comp from '../Components/Welcome_Comp'

const Welcome = () => {
    return (
        <Welcome_Comp
            label={'Unleash the Power of Seamless Research'}
            sub_text_arr={[
                {
                    bold: true,
                    text: `Yaarnbox empowers researchers and respondents with a comprehensive suite of features designed to streamline the market research process and unlock valuable insights. Here's a closer look at the functionalities that make Yaarnbox your one-stop solution for effective research:`
                }
            ]}
        />
    )
}

export default Welcome
