import React, { useEffect, useState } from "react";
import Text_Input from "../../../utilities/Text_Input";
import Flex_box from "../../../utilities/flex_box";
import search_icon from "../../../assets/Icon ionic-md-search.svg";
import blue_arrow from "../../../assets/arrow-ios-downward-outline.svg";
import white_arrow from "../../../assets/arrow-ios-downward-outline (1).svg";
import { theme_bg } from "../../../utilities/colors";

const FAQ_Tiles = ({ item, setselected_question }) => {
  return (
    <div
      style={{
        width: "90%",
        boxShadow: "0px 10px 20px #0000000D",
        borderRadius: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        setselected_question(item);
      }}
    >
      <Flex_box
        style={{
          padding: "20px 10px",
          justifyContent: "space-between",
        }}
      >
        <b style={{ fontSize: "14px" }}>{item?.title}</b>
        <img src={blue_arrow} style={{ height: "16px" }} />
      </Flex_box>
    </div>
  );
};

const FAQ_Comp = () => {
  const faq_arr = [
    {
      title: "How do I sign up and participate in studies?",
      content: `Creating a free account is quick and easy! Simply provide some basic information about yourself and get started. Once registered, you'll gain access to a variety of exciting research studies based on your interests and demographics.`,
      open: false,
    },
    {
      title: "How do I earn Smart-Coins and what are the benefits?",
      content: `Smart-Coins are your reward for contributing to valuable research! Earn them by completing surveys and participating in group discussions. The number of Smart-Coins awarded varies depending on the complexity of the study, allowing you to be fairly compensated for your time and effort.`,
      open: false,
    },
    {
      title: "How can I redeem Smart-Coins?",
      content: `We have retail partner outlets where you can convert your Smart-coins to shopping vouchers, which you can use to pay for products and services a your preferred time. Terms and Conditions of the respective outlet will apply.`,
      open: false,
    },
    {
      title: "Is my data secure on Yaarnbox?",
      content: `Data security is our top priority. We employ industry-standard encryption protocols to safeguard all information on the platform. This ensures your details and survey responses remain completely confidential.`,
      open: false,
    },
  ];

  const [changable_faq_arr, setchangable_faq_arr] = useState(faq_arr);

  const [selected_question, setselected_question] = useState(faq_arr[0]);
  const [ques_search, setques_search] = useState("");

  useEffect(() => {
    setchangable_faq_arr(
      faq_arr?.filter((el) => {
        if (ques_search) {
          return el?.title?.toLowerCase()?.includes(ques_search?.toLowerCase());
        } else {
          return el;
        }
      })
    );
  }, [ques_search]);

  return (
    <div
      style={{
        padding: "20px",
        maxHeight: "79vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        FAQ
      </span>
      <Flex_box>
        <Text_Input
          label={""}
          prefix_img={search_icon}
          placeholder={"Search here.."}
          input_style={{
            paddingLeft: "30px",
          }}
          value={ques_search}
          onChange={(e) => {
            setques_search(e.target.value);
          }}
        />
      </Flex_box>
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          alignItems: "",
        }}
      >
        <Flex_box
          className="responsive_width_35"
          style={{
            flexDirection: "column",
            width: "",
            gap: 20,
            padding: "20px 0px",
            borderRadius: "10px",
            boxShadow: "0px 10px 20px #0000000D",
            height: "300px",
            overflowY: "auto",
          }}
        >
          {changable_faq_arr?.map((el, index) => {
            return (
              <FAQ_Tiles
                item={el}
                setselected_question={setselected_question}
                key={index}
              />
            );
          })}
        </Flex_box>
        <div
          className="responsive_width_35"
          style={{
            width: "",
            alignItems: "",
            padding: "20px 0px 20px 10px",
            borderRadius: "10px",
            boxShadow: "0px 10px 20px #0000000D",
            // justifyContent:"start"
          }}
        >
          <div
            style={{
              backgroundColor: theme_bg,
              padding: "10px",
              width: "90%",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              {selected_question?.title}
            </span>
            <br />
            <span style={{ fontSize: "12px" }}>
              {selected_question?.content}
            </span>
          </div>
        </div>
      </Flex_box>
    </div>
  );
};

export default FAQ_Comp;
