import React, { useState } from "react";
import Text_Input from "../../../../utilities/Text_Input";
import Select_box from "../../../../utilities/Select_box";
import Flex_box from "../../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import validate_object from "../../../../utilities/Validate_object";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { create_study_api, update_study_api } from "../../../../apis/apis";
import Button from "../../../../utilities/Button";
import {
  get_sec_value,
  industry_arr,
  secValues,
  state_arr,
} from "../../../../utilities/select_arr";
import {
  Remove_obj_keys,
  add_obj_values,
  convertToArrayOfObjects,
  convertToObjectOfArrays,
} from "../../../../utilities/Remove_obj_keys";
import Radio_Box from "../../../../utilities/Radio_Box";

const Create_study_comp = () => {
  const r_state = useLocation();
  const study_detail = r_state?.state?.study_detail;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [study_data, setstudy_data] = useState(
    study_detail
      ? {
          ...convertToObjectOfArrays(study_detail),
          StudyOnInductry: study_detail.studyoninductry,
          studyname: study_detail.studyname,
          Description: study_detail.description,
          IncentiveType: "Smart coins",
          IncevtiveAmount: study_detail.incevtiveamount,
          TotalRespondents: study_detail.totalrespondents,
          close_date: study_detail.close_date?.split("T")[0] || "",
        }
      : {
          StudyOnInductry: "",
          studyname: "",
          Description: "",
          IncentiveType: "Smart coins",
          IncevtiveAmount: "",
          TotalRespondents: "",
          gender: "",
          age: "",
          close_date: "",
          // Location: "tedipuliya",
          SocioEconomicClassification: "",
          location: "",
          avail_for_non_reg: false,
        }
  );

  const upd_study_data = (key, val) => {
    setstudy_data({
      ...study_data,
      [key]: val,
    });
  };

  const {
    StudyOnInductry,
    studyname,
    Description,
    IncentiveType,
    IncevtiveAmount,
    TotalRespondents,
    gender,
    location,
    age,
    Gender,
    Age,
    Location,
    SocioEconomicClassification,
    close_date,
    avail_for_non_reg,
  } = study_data;

  const gender_overflow = add_obj_values(gender || {}) > TotalRespondents;
  const age_overflow = add_obj_values(age || {}) > TotalRespondents;
  const state_overflow = add_obj_values(location || {}) > TotalRespondents;
  const SocioEconomicClassification_overflow =
    add_obj_values(SocioEconomicClassification || {}) > TotalRespondents;

  const { user_data } = useSelector((state) => state?.authReducer);

  const create_study = () => {
    setloading(true);
    create_study_api(
      convertToArrayOfObjects({
        ...study_data,
        UserId: user_data?._id,
      })
    )
      .then((res) => {
        if (res.data.status == 1) {
          setloading(false);
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "Successfully created study!",
            })
          );
          console.warn(Object.keys(study_data?.SocioEconomicClassification));
          navigate("/select-study-type", {
            state: {
              studyid: res.data.Res?._id,
              studyname,
              sec: Object.keys(study_data?.SocioEconomicClassification),
            },
          });
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  const update_study = () => {
    setloading(true);
    update_study_api(
      convertToArrayOfObjects({
        ...study_data,
        UserId: user_data?._id,
      })
    )
      .then((res) => {
        if (res.data.status == 1) {
          setloading(false);
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "Successfully updated study!",
            })
          );
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
        dispatch(
          alert_action({
            type: "error",
            title: "Error",
            msg: "Some error occurred!",
          })
        );
      });
  };

  const handleSubmit = () => {
    const validated = validate_object(study_data);
    const sub_limits = user_data?.user_sub_lookup[0]?.sub_lookup[0];
    if (IncevtiveAmount > sub_limits?.coin_limit) {
      dispatch(
        alert_action({
          type: "error",
          title: "Coin overlow!",
          msg:
            "You cannot give incentive more than " +
            sub_limits?.coin_limit +
            "!",
        })
      );
    } else {
      if (
        validated?.status == true ||
        validated?.key == "avail_for_non_reg" ||
        validated?.key == "isClosed" ||
        validated?.key == "iscomplete"
      ) {
        if (
          gender_overflow ||
          age_overflow ||
          state_overflow ||
          SocioEconomicClassification_overflow
        ) {
          dispatch(
            alert_action({
              type: "error",
              title: "Respondent Count Overflow",
              msg: "Please make sure sum of respondents according to their demography is not more than total respondents.",
            })
          );
        } else {
          if (study_detail?._id) {
            update_study();
          } else {
            create_study();
          }
        }
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Missing field",
            msg: "Please enter " + validated?.key + " field!",
          })
        );
      }
    }
  };

  return (
    <div
      id="create_study_cont"
      style={{
        padding: "20px",
        maxHeight: "72.5vh",
        overflowY: "auto",
        // paddingBottom: "50px",
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        Create Study
      </span>
      <div className="responsive_width_35">
        <Select_box
          label={"Study on industry"}
          options={industry_arr}
          cont_style={{
            marginTop: "30px",
            // width: "100%"
          }}
          value={StudyOnInductry}
          onChange={(e) => {
            upd_study_data("StudyOnInductry", e.target.value);
          }}
        />
        <Select_box
          label={"Available for non-registered users?"}
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          cont_style={{
            marginTop: "20px",
          }}
          value={avail_for_non_reg}
          onChange={(e) => {
            upd_study_data("avail_for_non_reg", e.target.value);
          }}
        />
        <Text_Input
          label={"Study name"}
          input_style={
            {
              // width: "34.7vw"
            }
          }
          cont_style={{
            marginTop: "20px",
            width: "100%",
          }}
          value={studyname}
          onChange={(e) => {
            upd_study_data("studyname", e.target.value);
          }}
        />

        <Text_Input
          textArea={true}
          label={"Description"}
          placeholder={"Briefly describe what your study is about"}
          input_style={{
            // width: "34.7vw",
            height: "100px",
          }}
          cont_style={{
            marginTop: "20px",
          }}
          value={Description}
          onChange={(e) => {
            upd_study_data("Description", e.target.value);
          }}
        />
        <Select_box
          label={"Incentive Type"}
          input_style={
            {
              // width: "36vw"
            }
          }
          options={[
            {
              label: "Smart coins",
              value: "Smart coins",
            },
          ]}
          cont_style={{
            marginTop: "30px",
          }}
          value={IncentiveType}
          onChange={(e) => {
            upd_study_data("IncentiveType", e.target.value);
          }}
        />
        <Text_Input
          label={"Incentive Amount"}
          type={"number"}
          negative_less={true}
          cont_style={{
            marginTop: "20px",
          }}
          value={IncevtiveAmount}
          onChange={(e) => {
            upd_study_data("IncevtiveAmount", e.target.value);
          }}
          onFocus={(e) => e.target.select()}
        />
        <Text_Input
          label={"Close Date"}
          type={"date"}
          cont_style={{
            marginTop: "20px",
          }}
          value={close_date}
          onChange={(e) => {
            upd_study_data("close_date", e.target.value);
          }}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          lineHeight: 1.5,
        }}
      >
        <b>What kind of respondents do you need</b>
        <br />
        <span style={{ fontSize: "14px", fontWeight: 400 }}>
          Use the fields below to select the number of respondents you need for
          the study
        </span>
      </div>
      <Text_Input
        horizontalAlign={true}
        label={"Total Respondents"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "20px",
        }}
        value={TotalRespondents}
        onChange={(e) => {
          upd_study_data("TotalRespondents", e.target.value);
        }}
        onFocus={(e) => e.target.select()}
      />

      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Locations"}
      </p>
      <Flex_box
        style={{
          gap: 10,
          justifyContent: "start",
          flexWrap: "wrap",
          // marginTop: "-25px",
        }}
      >
        {state_arr.map((el, index) => {
          return (
            <>
              {/* <Text_Input
              key={index}
              horizontalAlign={true}
              type={"number"}
              negative_less={true}
              label={el.state}
              input_style={{
                width: "50px",
                border: state_overflow ? "2px solid red" : "",
              }}
              no_placeholder={true}
              cont_style={{
                marginTop: "15px",
                width: "200px",
              }}
              label_right_align={true}
              value={study_data?.location[el.state] || 0}
              onChange={(e) => {
                upd_study_data("location", {
                  ...study_data?.location,
                  [el.state]: e.target.value,
                });
              }}
              onFocus={(e) => e.target.select()}
            /> */}
              <Radio_Box
                style={{
                  width: "21%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 10,
                }}
                checked={study_data?.location[el.state] == 1}
                onClick={() => {
                  upd_study_data("location", {
                    // ...study_data?.location,
                    [el.state]: 1,
                  });
                }}
                label={el.state}
              />
            </>
          );
        })}
      </Flex_box>

      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Gender"}
      </p>
      <Flex_box
        style={{
          justifyContent: "start",
          marginTop: "20px",
          gap: 50,
        }}
      >
        <Text_Input
          horizontalAlign={true}
          type={"number"}
          negative_less={true}
          label={"Male"}
          input_style={{
            width: "50px",
            border: gender_overflow ? "2px solid red" : "",
          }}
          no_placeholder={true}
          label_right_align={true}
          value={study_data?.gender?.Male || 0}
          onChange={(e) => {
            setstudy_data({
              ...study_data,
              gender: {
                ...study_data?.gender,
                Male: e.target.value,
              },
            });
          }}
          onFocus={(e) => e.target.select()}
        />
        <Text_Input
          horizontalAlign={true}
          label={"Female"}
          type={"number"}
          negative_less={true}
          input_style={{
            width: "50px",
            border: gender_overflow ? "2px solid red" : "",
          }}
          no_placeholder={true}
          label_right_align={true}
          value={study_data?.gender?.Female || 0}
          onChange={(e) => {
            setstudy_data({
              ...study_data,
              gender: {
                ...study_data?.gender,
                Female: e.target.value,
              },
            });
          }}
          onFocus={(e) => e.target.select()}
        />
      </Flex_box>
      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Age: (Multiple Answer)"}
      </p>
      <Text_Input
        horizontalAlign={true}
        label={"Younger than 18 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "5px",
        }}
        label_right_align={true}
        value={study_data?.age?.YoungerThan18yearSold || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            YoungerThan18yearSold: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
      />
      <Text_Input
        horizontalAlign={true}
        label={"18 – 24 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old18_24years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old18_24years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
      />
      <Text_Input
        horizontalAlign={true}
        label={"25 – 34 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old25_34years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old25_34years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
      />
      <Text_Input
        horizontalAlign={true}
        label={"35 – 55 years old"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old35_55years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old35_55years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
      />
      <Text_Input
        horizontalAlign={true}
        label={"56 Years old and above"}
        type={"number"}
        negative_less={true}
        input_style={{
          width: "50px",
          border: age_overflow ? "2px solid red" : "",
        }}
        no_placeholder={true}
        cont_style={{
          marginTop: "15px",
        }}
        label_right_align={true}
        value={study_data?.age?.Old56years || 0}
        onChange={(e) => {
          upd_study_data("age", {
            ...study_data?.age,
            Old56years: e.target.value,
          });
        }}
        onFocus={(e) => e.target.select()}
      />
      <p style={{ fontSize: "14px", fontWeight: "700", marginTop: "20px" }}>
        {"Socio-Economic Classification"}
      </p>
      <Flex_box
        style={{
          gap: 10,
          justifyContent: "start",
          flexWrap: "wrap",
          marginTop: "-25px",
        }}
      >
        {secValues?.map((el, index) => {
          return (
            <Text_Input
              horizontalAlign={true}
              label={el.sec}
              type={"number"}
              negative_less={true}
              input_style={{
                width: "50px",
                border: SocioEconomicClassification_overflow
                  ? "2px solid red"
                  : "",
              }}
              no_placeholder={true}
              cont_style={{
                marginTop: "15px",
                width: "200px",
              }}
              label_right_align={true}
              value={study_data?.SocioEconomicClassification[el.sec] || 0}
              onChange={(e) => {
                upd_study_data("SocioEconomicClassification", {
                  ...study_data?.SocioEconomicClassification,
                  [el.sec]: e.target.value,
                });
              }}
              onFocus={(e) => e.target.select()}
            />
          );
        })}
      </Flex_box>
      <Button
        id="btn_comp"
        style={{
          marginTop: "40px",
          marginBottom: "20px",
        }}
        onClick={handleSubmit}
        loading={loading}
        label={"Create Study"}
      />
    </div>
  );
};

export default Create_study_comp;
