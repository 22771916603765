import React from 'react'
import Points_Comp from '../Components/Points_Comp';
import researcher_icon from '../../../assets/Group 48352.svg';

const Data_analysis = () => {
    return (
        <Points_Comp
            label={'Enhanced Data Analysis and Reporting:'}
            sub_text_arr={[
                { text: 'Gain instant insights into your data with interactive dashboards that visualize key metrics and trends from your studies.', bold: true }
            ]}
            points_arr={[
                {
                    heading: 'Advanced Reporting',
                    text: 'Generate comprehensive reports with detailed data breakdowns, customizable charts, and graphs to present your findings effectively.'
                },
                {
                    heading: '',
                    text: 'Demonstrate results to your stakeholders effectively!'
                },
            ]}
            image={researcher_icon}
            reverse={true}
        />
    )
}

export default Data_analysis
