import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Welcome_Comp from "./Welcome_Comp";
import Key_Features from "./Key_Features";
import Why_SmartQ from "./Why_SmartQ";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import The_Value from "./The_Value";
import SmartQDifference from "./SmartQDifference";
import Team_members from "./Team_members";
import FadeInSection from "../../../utilities/FadeInSection";

const Id_Cont = ({ id, children }) => {
  return (
    <FadeInSection>
      <div style={{ width: "100%" }} id={id}>
        {children}
      </div>
    </FadeInSection>
  );
};

const About_Screen = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>

      <Id_Cont id={"nav_comp"}>
        <div
          style={{
            width: "100%",
            height: "90px",
          }}
        ></div>
      </Id_Cont>
      <Id_Cont id={"welcome_comp"}>
        <Welcome_Comp open_modal={open_modal} />
      </Id_Cont>
      <Id_Cont id={"key_features"}>
        <Key_Features />
      </Id_Cont>
      <Id_Cont id={"team_members"}>
        <Team_members />
      </Id_Cont>
      <Id_Cont id={"the_value"}>
        <The_Value />
      </Id_Cont>
      <Id_Cont id={"why_smartq"}>
        <Why_SmartQ />
      </Id_Cont>
      <Id_Cont id={"smart_q_diff"}>
        <SmartQDifference />
      </Id_Cont>
      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default About_Screen;
