import React, { useState } from "react";
import eye_pic from "../assets/Icon_ionic_ios_eye.svg";
import eye_off_pic from "../assets/Icon_ionic_ios_eye_off.svg";
import down_arrow_icon from "../assets/arrow-right-bold.svg";

const Select_box = ({
  options,
  label,
  placeholder,
  select_cont_style,
  onChange,
  value,
  cont_style,
  type,
  textArea,
  input_style,
  suffix_img,
}) => {
  const cont_style_obj = cont_style || {};
  const select_cont_style_obj = select_cont_style || {};
  const input_style_obj = input_style || {};
  const [eye_opened, seteye_opened] = useState(false);

  // Check if an option other than the default is selected
  document
    .getElementById("select_input")
    ?.addEventListener("change", function () {
      this.classList.remove("placeholder");
    });

  // Add a class to style the placeholder
  document.addEventListener("DOMContentLoaded", function () {
    var select = document.getElementById("select_input");
    if (select.value === "") {
      select.classList.add("placeholder");
    }
  });

  return (
    <div
      className="removescrollbar responsive_width_35"
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        gap: 5,
        ...cont_style_obj,
      }}
    >
      <span style={{ fontSize: "10px", marginLeft: "10px" }}>{label}</span>
      <div
      className="removescrollbar"
        style={{
          position: "relative",
          width: "100%",
          ...select_cont_style_obj,
        }}
      >
        <select
        className="removescrollbar"
          id="select_input"
          onChange={onChange}
          value={value}
          style={{
            ...{ ...(input_style_obj || { height: "" }) },
            width: "100%",
          }}
        >
          <option value="" disabled selected hidden>
            <p
              style={{
                opacity: 0.6,
              }}
            >
              {placeholder}
            </p>
          </option>
          {options?.length > 0 && (
            options.map((el, index) => {
              return (
                <option key={index} value={el?.value}>
                  {el?.label}
                </option>
              );
            })
          )
          //  : (
          //   <>
          //     <option value="option1">Option 1</option>
          //     <option value="option2">Option 2</option>
          //     <option value="option3">Option 3</option>
          //   </>
          // )
          }
        </select>

        <img
          src={down_arrow_icon}
          style={{
            height: 8,
            position: "absolute",
            right: 10,
            top: 17,
          }}
        />
      </div>
    </div>
  );
};

export default Select_box;
