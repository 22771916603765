// reducers/userReducer.js
const initialState = {
  temp_user: '',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TEMP_USER":
      return {
        ...state,
        temp_user: {
          // ...state.alert_obj,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default appReducer;
