import React, { useEffect, useState } from "react";
import Layout from "../../../../utilities/Layout";
import Flex_box from "../../../../utilities/flex_box";
import Page_heading from "../../../../utilities/Page_heading";
import share_icon from "../../../../assets/Group 68128.svg";
import pdf_icon from "../../../../assets/Group 68130.svg";
import excel_icon from "../../../../assets/Group 68129.svg";
import Graph_Comp from "./Graph_Comp";
import Question_analysis from "./Question_analysis";
import { get_survey_analysis_api } from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import { sum_of_array_elem } from "../../../../utilities/Add_array_elem";
import { useDispatch } from "react-redux";
import { loading_action } from "../../../../redux_prog/actions/base_action";
import Print_Report from "../Print_Report";

const Survey_Analysis = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { study_id, studyname } = state;

  const [study_analysis_data, setstudy_analysis_data] = useState({});

  const get_surveyanalysis = () => {
    dispatch(loading_action(true));
    get_survey_analysis_api(study_id)
      .then((res) => {
        dispatch(loading_action(false));
        const analysis_data = res.data.Res.map((el) => {
          if (el?.question?.questiontype == 4) {
            return {
              questiontitle: el?.question?.questiontitle,
              sub_questions: el?.options?.map((li) => {
                return {
                  subquestiontitle: li?.subquestion?.questiontitle,
                  total_answers: sum_of_array_elem(li.subansw, "attempt"),
                  graph_data: li?.subansw?.map((elem) => {
                    return {
                      label: elem?.suboption?.optiontitle,
                      y: parseInt(elem?.attempt),
                    };
                  }),
                };
              }),
            };
          } else {
            return {
              questiontitle: el?.question?.questiontitle,
              inp_ans: el?.inp_ans,
              total_answers: sum_of_array_elem(el.options, "attempt"),
              graph_data: el?.options.map((li) => {
                return {
                  label: li?.option?.optiontitle,
                  y: parseInt(li?.attempt),
                };
              }),
            };
          }
        });

        console.warn(analysis_data);

        setstudy_analysis_data(analysis_data);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(get_surveyanalysis, []);

  return (
    <Layout
      style={{
        padding: "20px",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <Page_heading label={studyname + ` Survey's Analysis`} />
        <Flex_box
          style={{
            width: "",
            padding: "0px",
          }}
        >
          <img
            src={share_icon}
            style={{
              height: "50px",
            }}
          />
          <img
            src={excel_icon}
            style={{
              height: "50px",
            }}
          />
          <Print_Report
            elem={
              <img
                src={pdf_icon}
                style={{
                  height: "50px",
                }}
              />
            }
            studyname={studyname}
            study_id={study_id}
          />
        </Flex_box>
      </Flex_box>
      {study_analysis_data?.length > 0 &&
        study_analysis_data?.map((el, index) => {
          return <Question_analysis key={index} index={index} item={el} />;
        })}
    </Layout>
  );
};

export default Survey_Analysis;
