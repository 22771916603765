import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import Text_Input from "../../../../../utilities/Text_Input";
import Select_box from "../../../../../utilities/Select_box";
import Flex_box from "../../../../../utilities/flex_box";
import blue_circle from "../../../../../assets/Group 38514.svg";
import green_check from "../../../../../assets/check-circle-solid.svg";
import MCQ_Box from "./MCQ_Box";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../../../redux_prog/actions/base_action";

const Add_question = () => {
  const { state } = useLocation();

  console.warn(state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const question_type_options = [
    { label: "Input", value: 1 },
    { label: "Multi Select", value: 2 },
    { label: "Single Select", value: 3 },
    { label: "Open ended", value: 4 },
  ];

  const q_l_data = localStorage.getItem("question_data")
    ? JSON.parse(localStorage.getItem("question_data"))
    : {};
  const [question_data, setquestion_data] = useState({
    questiontitle: "",
    questiontype: 1,
    serial_no: 1,
    Option: [],
  });
  const [question_arr, setquestion_arr] = useState(q_l_data?.Questions || []);
  const surveyData = state ? state?.state : q_l_data?.SurveyData || {};

  const upd_question_data = (key, val, sub_key, sub_id) => {
    console.warn({ key, val, sub_key, sub_id });
    const new_obj =
      key == "Option"
        ? {
            ...question_data,
            Option: question_data?.Option.map((li, ind) => {
              if (sub_id == ind) {
                return {
                  ...li,
                  [sub_key]: val,
                };
              } else {
                return li;
              }
            }),
          }
        : {
            ...question_data,
            [key]: val,
          };
    setquestion_data(new_obj);
  };

  const add_option = (type) => {
    if (type == 4) {
      const option_has_value =
        question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
      if (option_has_value) {
        const new_obj = {
          ...question_data,
          Option: [
            ...question_data?.Option,
            {
              optiontitle: "",
              sub_options: [
                {
                  optiontitle: "",
                  answer: false,
                },
              ],
            },
          ],
        };
        setquestion_data(new_obj);
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Empty option",
            msg: "Please fill the option first!",
          })
        );
      }
    } else {
      const option_has_value =
        question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
      if (option_has_value) {
        const new_obj = {
          ...question_data,
          Option: [
            ...question_data?.Option,
            {
              optiontitle: "",
              answer: false,
              switch_to: 0,
            },
          ],
        };
        setquestion_data(new_obj);
      } else {
        dispatch(
          alert_action({
            type: "error",
            title: "Empty option",
            msg: "Please fill the option first!",
          })
        );
      }
    }
  };

  const add_sub_option = (o_index, option_data) => {
    const option_has_value =
      option_data?.sub_options[option_data?.sub_options?.length - 1]
        ?.optiontitle;
    if (option_has_value) {
      const new_obj = {
        ...option_data,
        sub_options: [
          ...option_data?.sub_options,
          {
            optiontitle: "",
            sub_options: [
              {
                optiontitle: "",
                answer: false,
              },
            ],
          },
        ],
      };
      setquestion_data({
        ...question_data,
        Option: Option.map((el, op_index) => {
          if (op_index == o_index) {
            return new_obj;
          } else {
            return el;
          }
        }),
      });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Empty option",
          msg: "Please fill the option first!",
        })
      );
    }
  };

  const select_question_question = (type) => {
    // // debugger;
    var noOfQuestion = question_arr.length + 1;
    if (type == 1) {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: type,
        serial_no: noOfQuestion,
        // next_question: 0
      });
    } else if (type == 4) {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: 4,
        serial_no: noOfQuestion,
        // next_question: 0,
        Option: [
          {
            optiontitle: "",
            sub_options: [
              {
                optiontitle: "",
                answer: false,
              },
            ],
          },
        ],
      });
    } else {
      setquestion_data({
        questiontitle: questiontitle,
        questiontype: type,
        serial_no: noOfQuestion,
        // next_question: 0,
        Option: [
          {
            optiontitle: "",
            answer: false,
            switch_to: 0,
          },
        ],
      });
    }
  };

  const add_question = () => {
    const option_has_value =
      questiontype == 1
        ? true
        : question_data?.Option[question_data?.Option?.length - 1]?.optiontitle;
    // debugger;
    if (questiontitle && option_has_value) {
      // alert();
      setquestion_arr([...question_arr, question_data]);
      var noOfQuestion = question_arr.length + 1;
      if (question_data.questiontitle) {
        noOfQuestion += 1;
      }
      setquestion_data({
        questiontitle: "",
        questiontype: 1,
        serial_no: noOfQuestion,
      });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing fields!",
          msg: "Please fill the option and question title!",
        })
      );
    }
  };

  const { questiontitle, questiontype, Option } = question_data;

  const handelRemoveOption = (index) => {
    const updatedQuestionData = { ...question_data };
    updatedQuestionData.Option.splice(index, 1);
    setquestion_data(updatedQuestionData);
  };

  const handelRemoveSubOption = (p_index, index) => {
    const updatedQuestionData = { ...question_data };
    updatedQuestionData.Option[p_index]?.sub_options.splice(index, 1);
    setquestion_data(updatedQuestionData);
  };
  const handelAddSubOption = (p_index, index) => {
    const updatedQuestionData = { ...question_data };
    const option_has_value =
      updatedQuestionData?.Option[p_index]?.sub_options[
        updatedQuestionData?.Option[p_index]?.sub_options?.length - 1
      ]?.optiontitle;
    if (option_has_value) {
      updatedQuestionData.Option[p_index]?.sub_options.push({
        optiontitle: "",
        answer: false,
      });
      setquestion_data(updatedQuestionData);
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing fields!",
          msg: "Please fill the option and question title!",
        })
      );
    }
  };

  const handelDependentQuestion = (value, questionIndex, optionIndex) => {
    // alert(questionIndex);
    // debugger;
    if (!question_arr[questionIndex].Option[optionIndex]?.dependent_question) {
      question_arr[questionIndex].Option[optionIndex]["dependent_question"] =
        value;
    } else {
      question_arr[questionIndex].Option[optionIndex].dependent_question =
        value;
    }
    setquestion_arr([...question_arr]);
    console.log("dependent questions", question_arr);
    //alert(value);
  };

  return (
    <div
      style={{
        padding: "20px",
        height: "73vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        Add Question
      </span>
      <div
        className="question_wrapper"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Text_Input
          label={"Question"}
          input_style={{
            width: "35vw",
          }}
          value={questiontitle}
          onChange={(e) => {
            upd_question_data("questiontitle", e.target.value);
          }}
        />

        {/* <div className="checkbox" style={{ margin: "0 10px", position: "relative", top: "8px" }}>
          <label htmlFor="">
            <input type="checkbox" />
            <span style={{ marginLeft: "5px" }}>Checkbox</span>
          </label>
        </div> */}
      </div>
      <Select_box
        label={"Type of question"}
        input_style={{
          width: "36.5vw",
        }}
        options={question_type_options}
        value={questiontype}
        onChange={(e) => {
          select_question_question(e.target.value);
        }}
      />
      {questiontype != 4 &&
        Option?.length > 0 &&
        Option.map((el, index) => {
          return (
            <>
              <div
                className="responsive_width_35"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Text_Input
                  label={index == 0 ? "Create options below" : ""}
                  placeholder={"Option " + (index + 1)}
                  value={el?.optiontitle}
                  onChange={(e) => {
                    upd_question_data(
                      "Option",
                      e.target.value,
                      "optiontitle",
                      index
                    );
                  }}
                  input_style={{
                    width: "35vw",
                    marginTop: index == 0 ? "10px" : "",
                  }}
                />
                {Option?.length > 1 && (
                  <div
                    onClick={() => handelRemoveOption(index)}
                    style={{
                      position: "absolute",
                      top: index == 0 ? "50%" : "30%",
                      right: "-58px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                      border: "1px solid red",
                      padding: "0px 5px 0px 6px",
                      borderRadius: "50%",
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                )}
              </div>
            </>
          );
        })}
      {questiontype == 4 &&
        Option?.length > 0 &&
        Option.map((el, index) => {
          return (
            <>
              <div
                className="responsive_width_35"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Text_Input
                  label={index == 0 ? "Create options below" : ""}
                  placeholder={"Option " + (index + 1)}
                  value={el?.optiontitle}
                  onChange={(e) => {
                    upd_question_data(
                      "Option",
                      e.target.value,
                      "optiontitle",
                      index
                    );
                  }}
                  input_style={{
                    width: "35vw",
                    marginTop: index == 0 ? "10px" : "",
                  }}
                />
                {Option?.length > 1 && (
                  <div
                    onClick={() => handelRemoveOption(index)}
                    style={{
                      position: "absolute",
                      top: index == 0 ? "50%" : "30%",
                      right: "-58px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                      border: "1px solid red",
                      padding: "0px 5px 0px 6px",
                      borderRadius: "50%",
                    }}
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                )}
              </div>
              {el.sub_options?.length > 0 &&
                el?.sub_options?.map((elem, ind_el) => {
                  return (
                    <div
                      className="responsive_width_35"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Text_Input
                        label={ind_el == 0 ? "Create sub options below" : ""}
                        placeholder={"Sub-Option " + (ind_el + 1)}
                        value={elem?.optiontitle}
                        onChange={(e) => {
                          upd_question_data(
                            "Option",
                            el?.sub_options?.map((elemen, ele_ind) => {
                              if (ele_ind == ind_el) {
                                return {
                                  ...elemen,
                                  optiontitle: e.target.value,
                                };
                              } else {
                                return elemen;
                              }
                            }),
                            "sub_options",
                            index
                          );
                        }}
                        input_style={{
                          width: "30vw",
                          marginTop: ind_el == 0 ? "10px" : "",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: ind_el == 0 ? "50%" : "30%",
                          right: "-58px",
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        {
                          // Option?.length > 1 &&
                          <div
                            onClick={() => handelAddSubOption(index, ind_el)}
                            style={{
                              color: "#000",
                              fontSize: "20px",
                              cursor: "pointer",
                              border: "1px solid #000",
                              padding: "0px 5px 0px 6px",
                              borderRadius: "50%",
                            }}
                          >
                            <i class="fa-solid fa-plus"></i>
                          </div>
                        }
                        {el.sub_options?.length > 1 && (
                          <div
                            onClick={() => handelRemoveSubOption(index, ind_el)}
                            style={{
                              color: "red",
                              fontSize: "20px",
                              cursor: "pointer",
                              border: "1px solid red",
                              padding: "0px 5px 0px 6px",
                              borderRadius: "50%",
                            }}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </>
          );
        })}
      <Flex_box
        style={{
          justifyContent: "start",
          gap: 20,
        }}
      >
        {questiontype != 1 && (
          <button
            id="btn_comp"
            style={{
              backgroundColor: theme_bg,
              border: "2px solid " + theme_color,
              color: theme_color,
              fontWeight: 600,
              padding: "8px",
              width: "150px",
            }}
            onClick={() => {
              add_option(questiontype);
            }}
          >
            Add Option
          </button>
        )}

        <button
          id="btn_comp"
          style={{
            fontWeight: 600,
            padding: "10px",
            width: "150px",
          }}
          onClick={() => {
            // navigate("/select-study-type");
            add_question();
          }}
        >
          Add Question
        </button>
      </Flex_box>

      {question_arr?.length > 0 && (
        <>
          <b>Questions</b>
          <MCQ_Box
            setquestion_arr={setquestion_arr}
            question_arr={question_arr}
            handelDependentQuestion={handelDependentQuestion}
          />
          <Flex_box
            className="responsive_width_35"
            style={{
              width: "",
              justifyContent: "space-between",
              marginTop: "40px",
              // gap: 20,
            }}
          >
            <button
              id="btn_comp"
              style={{
                backgroundColor: theme_bg,
                border: "2px solid " + theme_color,
                color: theme_color,
                fontWeight: 600,
              }}
              onClick={() => {
                localStorage.removeItem("question_data");
                navigate("/create-survey", { state: state?.state });
              }}
            >
              Cancel
            </button>
            <button
              id="btn_comp"
              style={{
                fontWeight: 600,
              }}
              onClick={() => {
                localStorage.setItem(
                  "question_data",
                  JSON.stringify({
                    SurveyData: surveyData,
                    Questions: question_arr,
                    image: state?.image || q_l_data?.image,
                  })
                );
                navigate("/preview-survey", {
                  state: {
                    state: state?.state,
                    studyname: state?.studyname,
                    sec: state?.sec,
                  },
                });
              }}
            >
              Save & Preview
            </button>
          </Flex_box>
        </>
      )}
    </div>
  );
};

export default Add_question;
