import React, { useState } from "react";
import logo from "../../../assets/Group 40944@2x.png";
import { theme_color } from "../../../utilities/colors";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import logo_white from "../../../assets/Group 40944@2x.png";
import Flex_box from "../../../utilities/flex_box";
import down_arrow from "../../../assets/down_arrow_white.png";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Button from "../../../utilities/Button";
import landing_bg from "../../../assets/Path1.png";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import zIndex from "@mui/material/styles/zIndex";
import Welcome_Comp from "./Welcome_Comp";
import Key_Features from "./Key_Features";
// import Contact_Form from "./Contact_Form";
import Contact_Form from "./New_Contact_Form";
import Why_SmartQ from "./Why_SmartQ";
import Subscriptions from "./Subscriptions";
import Footer from "../Nav_Comp/Footer";
import Nav_Comp from "../Nav_Comp";
import Other_Features from "./Other_Features";
import FadeInSection from "../../../utilities/FadeInSection";

const Id_Cont = ({ id, children }) => {
  return (
    <div style={{ width: "100%" }} id={id}>
      {children}
    </div>
  );
};

const Landing_Screen = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>
      <FadeInSection>
        <Id_Cont id={"nav_comp"}>
          <div
            style={{
              width: "100%",
              height: "90px",
            }}
          ></div>
        </Id_Cont>
      </FadeInSection>
      <FadeInSection>
        <Id_Cont id={"welcome_comp"}>
          <Welcome_Comp open_modal={open_modal} />
        </Id_Cont>
      </FadeInSection>
      <FadeInSection>
        <Id_Cont id={"key_features"}>
          <Key_Features />
        </Id_Cont>
      </FadeInSection>
      <FadeInSection>
        <Id_Cont id={"other_features"}>
          <Other_Features />
        </Id_Cont>
      </FadeInSection>
      <FadeInSection>
      <Id_Cont id={"why_smartq"}>
        <Why_SmartQ />
      </Id_Cont>
      </FadeInSection>
      <FadeInSection>
      <Id_Cont id={"subscriptions"}>
        <Subscriptions />
      </Id_Cont>
      </FadeInSection>
      <FadeInSection>
      <Id_Cont id={"contact_us"}>
        <Contact_Form open_modal={open_modal} />
      </Id_Cont>
      </FadeInSection>
      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Landing_Screen;
