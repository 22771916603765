import React from "react";
import researcher_icon from "../../../assets/Group 48352.svg";
import Points_Comp from "../Components/Points_Comp";
import Button from "../../../utilities/Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Plans = () => {
  const navigate = useNavigate();
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  const br = max_w_1000 && <br />;

  return (
    <>
      <Points_Comp
        label={"Starter (Free):"}
        sub_text_arr={[
          {
            text: "This free plan is perfect for researchers getting started with Yaarnbox or conducting small-scale research projects.",
          },
        ]}
        points_arr={[
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>Features:</b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Create and manage basic surveys (up to 10 questions)",
          },
          {
            heading: "",
            text: "Limited quota management (up to 50 responses per survey)",
          },
          {
            heading: "",
            text: "Access to basic reporting and data visualization tools",
          },
          {
            heading: "",
            text: "Conduct 5 group discussions per month (Maximum of 3 Participants each)",
          },
          {
            heading: "",
            text: "Incentive Credit: 10,000 Smart-Coins",
          },
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>
                Ideal for:
              </b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Individual researchers",
          },
          {
            heading: "",
            text: "Academic research projects",
          },
          {
            heading: "",
            text: "Pilot surveys and feasibility studies",
          },
        ]}
        image={researcher_icon}
        button_elem={
          <Button
            label={"Sign Up"}
            onClick={() => {
              navigate("/signup");
            }}
          />
        }
      />
      {br}
      <Points_Comp
        label={"Standard Plan: ($29/month)"}
        sub_text_arr={[
          {
            text: "This plan is ideal for researchers conducting regular studies and requiring more advanced features",
          },
        ]}
        points_arr={[
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>Features:</b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Create and manage unlimited surveys of any complexity",
          },
          {
            heading: "",
            text: "Advanced quota management (up to 250 responses per survey)",
          },
          {
            heading: "",
            text: "Comprehensive reporting and data visualization tools",
          },
          {
            heading: "",
            text: "Conduct up to 10 group discussions per month (Maximum of 10 Participants each)",
          },
          {
            heading: "",
            text: "Dedicated customer support",
          },
          {
            heading: "",
            text: "Incentive Credit: 100,000 Smart-Coins",
          },
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>
                Ideal for:
              </b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Small research teams",
          },
          {
            heading: "",
            text: "Marketing and product research professionals",
          },
          {
            heading: "",
            text: "Freelancers and independent researchers",
          },
        ]}
        image={researcher_icon}
        button_elem={
          <Button
            label={"Sign Up"}
            onClick={() => {
              navigate("/signup");
            }}
          />
        }
        reverse={true}
      />
      {br}

      <Points_Comp
        label={"Premium Plan: ($99/month)"}
        sub_text_arr={[
          {
            text: "This plan is designed for high-volume research projects and teams requiring maximum functionality.",
          },
        ]}
        points_arr={[
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>Features:</b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Create and manage unlimited surveys of any complexity",
          },
          {
            heading: "",
            text: "Advanced quota management (up to 1000 responses per survey)",
          },
          {
            heading: "",
            text: "In-depth data analysis and reporting with advanced customization options",
          },
          {
            heading: "",
            text: "Host up to 15 group discussions per month (Up to 15 Participants per session)",
          },
          {
            heading: "",
            text: "Priority customer support",
          },
          {
            heading: "",
            text: "Team collaboration tools",
          },
          {
            elem: (
              <b style={{ fontSize: "18px", fontStyle: "italic" }}>
                Ideal for:
              </b>
            ),
            text: "",
          },
          {
            heading: "",
            text: "Large research agencies",
          },
          {
            heading: "",
            text: "Enterprise-level market research teams",
          },
          {
            heading: "",
            text: "Academic institutions conducting large-scale studies",
          },
        ]}
        image={researcher_icon}
        button_elem={
          <Button
            label={"Sign Up"}
            onClick={() => {
              navigate("/signup");
            }}
          />
        }
      />
      {br}

      <Points_Comp
        label={"All Plans Include:"}
        sub_text_arr={[
          {
            text: "Secure and encrypted platform with robust data security measures.",
            bold: true,
          },
          {
            text: "Access to our comprehensive knowledge base and support resources.",
            bold: true,
          },
          { text: "Free trial available.", bold: true },
        ]}
        bg={"true"}
      />
    </>
  );
};

export default Plans;
