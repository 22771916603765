
import { useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import type {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng/esm";

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from "agora-rtc-sdk-ng/esm";
import { Modal } from "../../../utilities/Modal";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import { theme_color } from "../../../utilities/colors";
import phone_icon from "../../../assets/4801092.png";
import { generate_token_api } from "../../../apis/apis";

console.log("Current SDK VERSION: ", VERSION);

onCameraChanged((device) => {
  console.log("onCameraChanged: ", device);
});
onMicrophoneChanged((device) => {
  console.log("onMicrophoneChanged: ", device);
});

const client: IAgoraRTCClient = createClient({
  mode: "rtc",
  codec: "vp8",
});

let audioTrack: IMicrophoneAudioTrack;
let videoTrack: ICameraVideoTrack;

function V_Call({ isVideoOn, setIsVideoOn,room_id }) {
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [video_off, setvideo_off] = useState(false);
  // const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);

  const turnOnCamera = async (flag: boolean) => {
    setvideo_off(false);
    videoTrack = await createCameraVideoTrack();
    videoTrack.play("camera-video");
  };

  const turnOffCamera = () => {
    setvideo_off(true);
    if (videoTrack) {
      videoTrack.setEnabled(false);
    }
  };

  const turnOnMicrophone = async (flag?: boolean) => {
    flag = flag ?? !isAudioOn;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };

  const [isJoined, setIsJoined] = useState(false);
  const channel = useRef(room_id);
  // you can apply appid follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const appid = useRef("5bb5312ea3d6442793fa0ff00903d662");
  // you can apply token follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const token = useRef(
    "007eJxTYGDqKdRRzDVlbl368m9g1tu57187ewepV12RmBfirSf3+5oCg2lSkqmxoVFqonGKmYmJkbmlcVqiQVqagYGlAVDEzOjSnRVpDYGMDAseajMzMkAgiM/CUJJaXMLAAAAQFh9U"
  ); // temp token

  const joinChannel = async () => {
    if (!channel.current) {
      channel.current = "react-room";
    }

    if (isJoined) {
      await leaveChannel();
    }

    const token_res = await generate_token_api({
      channelName:room_id,
      uid: Math.floor(Math.random() * 100)
    });

    // console.warn(token_res);

    client.on("user-published", onUserPublish);

    await client.join(
      appid.current,
      channel.current,
    //   token.current,
      token_res?.data?.token || null,
      null
    );

    setIsJoined(true);
  };

  const [remote_users_arr, setremote_users_arr] = useState({});

  const leaveChannel = async () => {
    setIsJoined(false);
    setIsAudioPubed(false);
    setIsVideoPubed(false);
    await client.leave();
  };

  useEffect(() => {
    Object.keys(remote_users_arr).forEach((remoteTrackId) => {
      const remoteTrack = remote_users_arr[remoteTrackId];
      remoteTrack.play(`remote-video-${remoteTrackId}`);
    });
  }, [remote_users_arr]);

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio"
  ) => {
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      const remoteTrackId = user.uid.toString();
      setremote_users_arr((prevRemoteTracks) => ({
        ...prevRemoteTracks,
        [remoteTrackId]: remoteTrack,
      }));

      // setTimeout(() => {
      //   remoteTrack.play(`remote-video-${remoteTrackId}`);
      // }, 2000);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
    }
  };

  const publishVideo = async () => {
    try {

      await turnOnCamera(true);

      if (!isJoined) {
        await joinChannel();
      }
      console.log("Publishing");
      await client.publish(videoTrack);
      setIsVideoPubed(true);
      console.log("Published");
    } catch (error) {
      console.error(error);
    };
  }

    const publishAudio = async () => {
      try {
        await turnOnMicrophone(true);
        await client.publish(audioTrack);
        setIsAudioPubed(true);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      const turn_on = async () => {
        try {
          await turnOnCamera(false);
          turnOnCamera(true);
        } catch (error) {
          console.error(error);

        }

      };

      turn_on();
      // if(isVideoOn == true){
      // }
    }, [isVideoOn]);

    return (
      <>
        {
          <Modal isOpen={true} onClose={() => setIsVideoOn(false)}>
            <Flex_box
              style={{
                // width: "450px",
                flexWrap: "wrap",
                gap: 10,
                position: "relative",
              }}
            >
              <span
                className="close"
                style={{
                  top: -10,
                  right: -5,
                }}
                onClick={() => {
                  turnOffCamera();
                  setIsJoined(false);
                  setIsVideoPubed(false);
                  setIsVideoOn(false);
                }}
              >
                &times;
              </span>
              {video_off && (
                <img
                  style={{
                    height: "200px",
                  }}
                  src={phone_icon}
                />
              )}
              <video
                id="camera-video"
                style={{
                  height: "200px",
                  width: "200px",
                }}
                hidden={video_off}
              ></video>

              {Object.keys(remote_users_arr).map((remoteTrackId) => (
                <video
                  key={remoteTrackId}
                  id={`remote-video-${remoteTrackId}`}
                  style={{
                    height: "200px",
                    width: "200px",
                  }}
                ></video>
              ))}
            </Flex_box>

            <Flex_box
              style={{
                gap: 20,

                marginTop: "20px",
              }}
            >
              <Button
                label={isJoined && isVideoPubed ? "Leave" : "Join"}
                style={{
                  backgroundColor: isJoined && isVideoPubed ? "red" : "green",
                  color: "#fff",
                  width: "150px",
                }}
                onClick={async () => {
                  if (isJoined && isVideoPubed) {
                    turnOffCamera();
                    setIsJoined(false);
                    setIsVideoPubed(false);
                    setIsVideoOn(false);
                  } else {
                    await publishVideo();
                    publishAudio();
                  }
                }}
              />
              <Button
                label={"Video"}
                style={{
                  backgroundColor: !video_off ? theme_color : "white",
                  border: !video_off ? "" : "2px solid " + theme_color,
                  color: !video_off ? "#fff" : "#000",
                  height: "43px",
                  width: "150px",
                }}
                onClick={() => {
                  setvideo_off(!video_off);
                }}
              />
              <Button
                label={"Mute"}
                style={{
                  backgroundColor: isAudioOn ? theme_color : "white",
                  border: isAudioOn ? "" : "2px solid " + theme_color,
                  color: isAudioOn ? "#fff" : "#000",
                  height: "43px",
                  width: "150px",
                }}
                onClick={() => {
                  turnOnMicrophone();
                }}
              />
            </Flex_box>
          </Modal>
        }
      </>
    );
  }

  export default V_Call;
