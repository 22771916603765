import React from 'react'
import Points_Comp from '../Components/Points_Comp'
import Button from '../../../utilities/Button'
import { useNavigate } from 'react-router-dom'

const Unsure = () => {

    const navigate = useNavigate();

    return (
        <Points_Comp
            label={'Still Unsure?'}
            sub_text_arr={[
                { text: 'Try Before You Buy!', bold: true }
            ]}
            points_arr={[
                {
                    text: `Sign up for our free trial and experience the power of Yaarnbox firsthand. Explore the platform's functionalities, create your first survey, and see how Yaarnbox can revolutionize your research approach.`
                },
            ]}
            button_elem={
                <Button
                    label={'Start Your Free Trial Now!'}
                    onClick={() => {
                        navigate('/signup');
                    }}
                />
            }
            reverse={true}
            bg={'true'}
        />
    )
}

export default Unsure
