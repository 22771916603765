import React from 'react'
import Layout from '../../../utilities/Layout'
import FAQ_Comp from './FAQ_Comp'
import Contact_Comp from './Contact_Comp'

const FAQ = () => {
    return (
        <Layout>
            <div
            className="removescrollbar"
                style={{
                    maxHeight: "79vh",
                    overflowY: "auto",
                    width:"100%"
                }}
            >
                <Contact_Comp />
                <FAQ_Comp />
            </div>
            {/* <iframe src={'http://18.213.11.105:2020/1/6593bee4cb51949db5de3ba8/Level%202/B/1/18'} title="Web View" width="100%" height="500"></iframe> */}
        </Layout>
    )
}

export default FAQ
