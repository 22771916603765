import React from 'react'
import Layout from '../../../utilities/Layout'
import Page_heading from '../../../utilities/Page_heading';
import user_logo from "../../../assets/user_dummy.png";
import { theme_color } from '../../../utilities/colors';
import Text_Input from '../../../utilities/Text_Input';
import Flex_box from '../../../utilities/flex_box';
import edit_icon from "../../../assets/edit.svg";

const Edit_Profile = () => {
    return (
        <Layout>
            <div
                style={{
                    padding: "20px"
                }}
            >
                <Page_heading label={'Edit Profile'} />
                <Flex_box
                    style={{
                        flexWrap: "wrap",
                        gap: 40,
                        justifyContent: "start",
                        marginTop: "20px"
                    }}
                >

                    <Flex_box
                        className="responsive_width"
                        style={{
                            padding: "20px",
                            gap: 20,
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            width: "",
                            boxShadow: "0px 10px 20px #0000000D",
                            borderRadius: "15px",
                            // backgroundColor:"red"
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                // backgroundColor:"red"
                            }}
                        >
                            <img
                                src={user_logo}
                                style={{
                                    height: "90px",
                                    borderRadius: "50%",
                                    border: "2px solid " + theme_color
                                }}
                            />
                            <Flex_box
                                style={{
                                    padding: "5px",
                                    backgroundColor: theme_color,
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: "0px",
                                    right: "5px",
                                    width: '12px',
                                    height: '12px'
                                }}
                            >
                                <img
                                    src={edit_icon}
                                    style={{
                                        height: "11px",
                                    }}
                                />
                            </Flex_box>
                        </div>
                        <Text_Input
                            label={'First Name'}
                            cont_style={{
                                width: "100%",
                                // backgroundColor:"red"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Last Name'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Username'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Email'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />

                    </Flex_box>

                    <Flex_box
                        className="responsive_width"
                        style={{
                            padding: "20px",
                            gap: 20,
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            width: "",
                            boxShadow: "0px 10px 20px #0000000D",
                            borderRadius: "15px"
                        }}
                    >
                        <b>Business Detail's</b>

                        <Text_Input
                            label={'Business Name'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Business Description'}
                            textArea={true}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%",
                                minHeight: "60px"
                            }}
                        />
                        <Text_Input
                            label={'Business Address'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Industry'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />
                        <Text_Input
                            label={'Conduct Industry Studies in'}
                            cont_style={{
                                width: "100%"
                            }}
                            input_style={{
                                width: "90%"
                            }}
                        />

                    </Flex_box>

                </Flex_box>
                <button
                    id="btn_comp"
                    style={{
                        marginTop: "20px"
                    }}
                >
                    Save
                </button>
            </div>
        </Layout>
    )
}

export default Edit_Profile
