import React, { useEffect, useRef } from "react";
import "./sidebar.css";
import logo from "../../../../assets/Group 40944@2x.png";
import Flex_box from "../../../../utilities/flex_box";
import { Link, useNavigate } from "react-router-dom";
import {
  researcher_route_labels,
  respondent_route_labels,
} from "../../../../config/route_labels";
import { useDispatch, useSelector } from "react-redux";
import { sidebar_action } from "../../../../redux_prog/actions/base_action";
import { useMediaQuery } from "@mui/material";

const Sidebar = ({ no_sidebar }) => {
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const max_w_700 = useMediaQuery("(max-width:700px)");

  const is_researcher = login_type == 2;

  const max_w_700_researcher_routes =
    max_w_700 && is_researcher
      ? [
          {
            path: "/create-study",
            label: "Create Study",
          },
        ]
      : [];

  const routes = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/about-us",
      label: "Abouts Us",
    },
    {
      path: "/features",
      label: "Features",
    },
    {
      path: "/pricing",
      label: "Pricing",
    },
    {
      path: "/support",
      label: "Support",
    },
  ];

  const pathname = window?.location?.hash?.split("/")[1];
  const navigate = useNavigate();

  const Nav_Item = [...routes, ...max_w_700_researcher_routes].map(
    (el, index) => {
      return (
        <div
          onClick={() => {
            navigate(el.path);
          }}
          onMouseOver={() => {
            document.getElementById("link_comp" + index).style.textDecoration =
              "underline";
          }}
          onMouseOut={() => {
            document.getElementById("link_comp" + index).style.textDecoration =
              "none";
          }}
          style={{ width: "100%" }}
        >
          <Flex_box
            key={index}
            style={{
              backgroundColor: pathname
                ? el.path?.split("/").includes(pathname)
                  ? "#c9c9c9"
                  : "rgb(2, 103, 193, 0.03)"
                : el.path == "/"
                ? "#c9c9c9"
                : "rgb(2, 103, 193, 0.03)",
              height: "50px",
              position: "relative",
              marginTop: "3px",
              cursor: "pointer",
            }}
          >
            <span
              id={"link_comp" + index}
              className="navlink_comp"
              to={el.path}
            >
              <p
                style={{
                  fontWeight: pathname
                    ? el.path?.split("/").includes(pathname)
                      ? "700"
                      : "500"
                    : el.path == "/"
                    ? "700"
                    : "500",
                }}
              >
                {el.label}
              </p>
            </span>
          </Flex_box>
        </div>
      );
    }
  );

  const visible = useSelector((state) => state?.baseReducer?.sidebar_open);
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      dispatch(sidebar_action(false));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Sidebar_comp = ({ className }) => {
    const fixed_sidebar = className == "fixed_sidebar";
    return (
      <div
        ref={componentRef}
        className={className}
        style={
          no_sidebar
            ? {
                backgroundColor: "transparent",
              }
            : {}
        }
      >
        <Flex_box
          style={{
            padding: "35px 0px",
            backgroundColor: "#e6eff8",
            position: "relative",
          }}
        >
          {fixed_sidebar && (
            <p
              style={{
                position: "absolute",
                top: "-10px",
                right: "10px",
                fontWeight: "700",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(sidebar_action(false));
              }}
            >
              &times;
            </p>
          )}
          <img src={logo} className="side_logo" />
        </Flex_box>
        {!no_sidebar && Nav_Item}
      </div>
    );
  };

  useEffect(() => {
    if (!max_w_700) {
      dispatch(sidebar_action(false));
    }
  }, [max_w_700]);

  return <>{visible && <Sidebar_comp className={"fixed_sidebar"} />}</>;
};

export default Sidebar;
