// reducers/baseReducer.js
const initialState = {
    alert_obj: {
        type: '',
        msg: '',
        title: '',
        btn_text: '',
        isopen: false
    },
    loading: false,
    sidebar_open: false,
};

const baseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALERT':
            return {
                ...state,
                alert_obj: {
                    // ...state.alert_obj,
                    ...action.payload,
                    isopen: true
                }
            };
        case 'CLOSE_ALERT':
            return {
                ...state,
                alert_obj: {
                    isopen: false,
                    is_confirm:false
                }
            };
        case 'LOADING':
            return {
                ...state,
                loading: action.payload
            };
            case "SIDEBAR_ACTION":
              return {
                ...state,
                sidebar_open: action.payload,
              };
        default:
            return state;
    }
};

export default baseReducer;
