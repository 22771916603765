import React from "react";
import Flex_box from "../../../utilities/flex_box";
import features_bg from "../../../assets/Group48288.png";
import { theme_bg, theme_color } from "../../../utilities/colors";
import study_icon from "../../../assets/Group 48355.svg";
import communication_icon from "../../../assets/Group 48358.svg";
import analysis_icon from "../../../assets/Group 48359.svg";
import { borderRadius } from "@mui/system";
import Button from "../../../utilities/Button";
import feature_bg from "../../../assets/Group 48354.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Key_Features = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <Flex_box
      className="auth_box_cont intro_comp_cont"
      style={{
        flexDirection: "column",
        height: max_w_1000 ? "120px" : "250px",
        width: "",
        // padding:"20px",
        backgroundImage: `url(${features_bg})`,
        marginTop: "15px",
      }}
    >
      <div
        style={{
          width: max_w_1000 ? "90%" : "65%",
        }}
      >
        <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
          Our Mission
        </b>
        <br className="max_100_hidden" />
        <br />
        <span className="intro_sub_text" style={{ fontStyle: "italic" }}>
          We will empower researchers with innovative tools and connect them
          with a diverse pool of engaged respondents, ultimately shaping the
          future of market research through data-driven insights.
        </span>
      </div>
    </Flex_box>
  );
};

export default Key_Features;
