
export const set_country_action = (payload) => ({
    type: 'SET_COUNTRY',
    payload
});

export const intro_completed_action = (payload) => ({
    type: 'INTRO_COMPLETE',
    payload
});

export const set_school_ddl_action = (payload) => ({
    type: 'SET_SCHOOL_DDL',
    payload
});

export const alert_action = (payload) => ({
    type: 'ALERT',
    payload
});

export const close_alert_action = (payload) => ({
    type: 'CLOSE_ALERT',
    payload
});

export const loading_action = (payload) => ({
    type: 'LOADING',
    payload
});

export const sidebar_action = (payload) => ({
  type: "SIDEBAR_ACTION",
  payload,
});

