import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Graph_Comp = ({ graph_data }) => {
  const options = {
    animationEnabled: true,
    theme: "light2", //"light1", "dark1", "dark2"
    title: {
      text: "",
    },
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "bar", //change type to bar, line, area, pie, etc
        //indexLabel: "{y}", //Shows y value on all Data Points
        indexLabelFontColor: "#5A5757",
        indexLabelPlacement: "outside",
        dataPoints: graph_data,
      },
    ],
  };

  return (
    <div
      className="responsive_width_35"
      style={{
        marginTop: "5px",
      }}
    >
      <CanvasJSChart
        options={options}
        containerProps={{ width: "100%", height: "300px" }}
      />
    </div>
  );
};

export default Graph_Comp;
