





import React from 'react'
import Flex_box from '../../../../utilities/flex_box'
import { theme_bg } from '../../../../utilities/colors';
import phone_icon from "../../../../assets/phone.svg";
import video_icon from "../../../../assets/Group 32928.svg";
import user_icon from "../../../../assets/user_dummy.png";
import Chat_Footer from './Chat_Footer';

const Msg_comp = ({ reverse }) => {

    const top_b_radius = !reverse ? { borderTopLeftRadius: "0px" } : { borderTopRightRadius: "0px" }

    return (
        <Flex_box
            style={reverse ? {
                justifyContent: "end",
                alignItems: "start",
            } : {
                justifyContent: "start",
                alignItems: "start",
                // backgroundColor:"red"
            }}
        >
            {
                !reverse &&
                <img src={user_icon}
                    style={{
                        height: "20px",
                        marginTop: "9px",
                        marginRight: "10px"
                    }}
                />
            }
            <div>
                <p style={{ fontSize: "10px" }}>Adam Warlock</p>
                <div
                    style={{
                        backgroundColor: "rgb(2, 103, 193,0.1)",
                        fontSize: "12px",
                        padding: "10px",
                        borderRadius: "8px",
                        marginTop: "-5px",
                        ...top_b_radius
                    }}
                >
                    Lorem Ipsum
                </div>
            </div>

            {
                reverse &&
                <img src={user_icon}
                    style={{
                        height: "20px",
                        marginTop: "9px",
                        marginLeft: "10px"
                    }}
                />
            }
        </Flex_box>
    )
};

const Chat_Box = ({ width, top_less }) => {
    return (
        <Flex_box
        className="remove_scrollbar"
        style={{
                width: width || "100%",
                backgroundColor: theme_bg,
                marginTop: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px #0000000D",
                flexDirection: "column",
                paddingBottom: "10px"
            }}
        >
            {
                !top_less &&
                <Flex_box
        className="remove_scrollbar"
        style={{
                        justifyContent: "end",
                        gap: 15,
                        padding: "15px 0px",
                        width: "98%"
                    }}
                >
                    <img
                        src={phone_icon}
                        style={{
                            height: "15px"
                        }}
                    />
                    <img
                        src={video_icon}
                        style={{
                            height: "15px",
                        }}
                    />
                </Flex_box>
            }
            <Flex_box
        className="remove_scrollbar"
        style={{
                    width: "97.5%",
                    height: "54vh",
                    maxHeight: "60vh",
                    backgroundColor: "#fff",
                    flexDirection: "column",
                    // alignItems: 'end',
                    justifyContent: "end",
                    flexFlow: 'column nowrap',

                    padding: "20px 10px",
                    overflowY: "auto"
                }}
            >
                <Msg_comp reverse={true} />
                <Msg_comp />
                <Msg_comp />
            </Flex_box>

            <Chat_Footer />

        </Flex_box>
    )
}

export default Chat_Box