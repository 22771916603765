import { useEffect, useMemo, useRef, useState } from "react";
import "./App.css";
import type {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng/esm";

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from "agora-rtc-sdk-ng/esm";
import { Modal } from "../../../utilities/Modal";
import phone_icon from "../../../assets/4801092.png";
import Button from "../../../utilities/Button";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import { agora_app_id, generate_token_api } from "../../../apis/apis";

console.log("Current SDK VERSION: ", VERSION);

onCameraChanged((device) => {
  console.log("onCameraChanged: ", device);
});
onMicrophoneChanged((device) => {
  console.log("onMicrophoneChanged: ", device);
});

const client: IAgoraRTCClient = createClient({
  mode: "rtc",
  codec: "vp8",
});

let audioTrack: IMicrophoneAudioTrack;
let videoTrack: ICameraVideoTrack;

function A_Call({ isAudioOn, setIsAudioOn,room_id }) {
  // const [isAudioOn, setIsAudioOn] = useState(false);
  // const [isVideoOn, setIsVideoOn] = useState(false);
  const [mute, setmute] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);

  const turnOnMicrophone = async () => {
    // setIsAudioOn(true);

    // if (audioTrack) {
    //     return audioTrack.setEnabled(true);
    // }

    audioTrack = await createMicrophoneAudioTrack();
    setmute(false);
    audioTrack.play();
  };

  const mute_audio = () => {
    setmute(true);
    if (audioTrack) {
      return audioTrack.setEnabled(true);
    }
  };
  const [isJoined, setIsJoined] = useState(false);
  const channel = useRef(room_id);
  // you can apply appid follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const appid = useRef(agora_app_id);
  // you can apply token follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const token = useRef(
    "007eJxTYJg04dCv+qIO7Y8BSdWWb9cZ8wenOPyJyLsh+XdTCK/VqZMKDKapqcaGBpYWqeZmFiapyUaWhgZmJkmGppbJRoamiYkmXsoZaQ2BjAzFzLuZGRkgEMTnYzA0jM8qzYsvS45PTszJYWAAAJeaIhY="
  );

  const joinChannel = async () => {
    if (!channel.current) {
      channel.current = "react-room";
    }

    if (isJoined) {
      await leaveChannel();
    }

    const token_res = await generate_token_api({
      channelName: room_id,
      uid: Math.floor(Math.random() * 100),
    });

    client.on("user-published", onUserPublish);

    await client.join(
      appid.current,
      channel.current,
      token_res?.data.token || null,
      null
    );
    setIsJoined(true);
  };

  const leaveChannel = async () => {
    setIsJoined(false);
    setIsAudioPubed(false);

    await client.leave();
  };

  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "audio"
  ) => {
    const remoteTrack = await client.subscribe(user, mediaType);
    remoteTrack.play();
  };

  const publishAudio = async () => {
    await turnOnMicrophone(true);

    if (!isJoined) {
      await joinChannel();
    }

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  useEffect(() => {
    if (isAudioOn) {
      turnOnMicrophone(true);
    }
  }, [isAudioOn]);

  return (
    <>
      {
        <Modal isOpen={true} onClose={() => setIsAudioOn(false)}>
          <Flex_box
          // style={{
          //     height: "480px",
          //     width: "500px"
          // }}
          >
            <img
              style={{
                height: "200px",
              }}
              src={phone_icon}
            />
          </Flex_box>
          <Flex_box
            style={{
              gap: 20,

              marginTop: "20px",
            }}
          >
            <Button
              label={"Leave"}
              style={{
                backgroundColor: "red",
                color: "#fff",
                width: "150px",
              }}
              onClick={() => {
                setIsAudioOn(false);
                mute_audio();
              }}
            />
            <Button
              label={"Mute"}
              style={{
                backgroundColor: mute ? theme_color : "white",
                border: mute ? "" : "2px solid " + theme_color,
                color: mute ? "#fff" : "#000",
                height: "43px",
                width: "150px",
              }}
              onClick={() => {
                if (mute) {
                  turnOnMicrophone();
                } else {
                  mute_audio();
                }
              }}
            />
          </Flex_box>
        </Modal>
      }
    </>
  );
}

export default A_Call;
