import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import Welcome from "./Welcome";
import User_Experiance from "./User_Experiance";
import Powerful_research from "./Powerful_research";
import Data_analysis from "./Data_analysis";
import Table_Comp from "./Table_Comp";
import Diff_exp from "./Diff_exp";

const Features = () => {
  const [isOpen, setisOpen] = useState(false);

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>
      <div
        style={{
          width: "100%",
          height: "90px",
        }}
      ></div>
      <div>
        <Welcome open_modal={open_modal} />
        <User_Experiance />
        <Powerful_research />
        <Data_analysis />
        <Table_Comp />
        <Diff_exp />
      </div>
      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Features;
