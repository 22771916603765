import React from 'react'
import Flex_box from './flex_box'

const Progress_bar = ({ progress, style }) => {
    const style_obj = style || {};
    return (
        <Flex_box
            style={{
                height: "7px",
                width: "20vw",
                borderRadius: "20px",
                justifyContent: "start",
                backgroundColor: "#dfe3e8",
                ...style_obj
            }}
        >
            <div
                style={{
                    height: "100%",
                    width: progress,
                    backgroundColor: "#00d82b",
                    borderRadius: '8px'
                }}
            >

            </div>
        </Flex_box>
    )
}

export default Progress_bar
