import React, { useEffect, useMemo, useRef, useState } from "react";
import Flex_box from "../../../../utilities/flex_box";
import { theme_bg } from "../../../../utilities/colors";
import phone_icon from "../../../../assets/phone.svg";
import video_icon from "../../../../assets/Group 32928.svg";
import user_icon from "../../../../assets/user_dummy.png";
import Chat_Footer from "./Chat_Footer";
import axios from "axios";
import { host } from "../../../Chat_Screens/utils/APIRoutes.js";
import { v4 as uuidv4 } from "uuid";
import V_Call from "../../../Video_Chat/agora_ng_version/V_Call.tsx";
import A_Call from "../../../Video_Chat/agora_ng_version/A_Call.tsx";
import Chat_Header from "./Chat_Header.js";

import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  delete_message_api,
  get_polls_api,
  get_room_messages_api,
  image_url,
  store_message_api,
} from "../../../../apis/apis.js";
import Poll_Form from "./Poll/Poll_Form.js";
import poll_icon from "../../../../assets/poll_icon.png";
import Poll_Details from "./Poll/Poll_Box/Poll_Details.js";
import Poll_Box from "./Poll/Poll_Box/index.js";
import dot_menu from "../../../../assets/dot_menu.png";

// const ENDPOINT = "http://localhost:5000";
let socket = io(host);

const Msg_comp = ({ reverse, message, del_message }) => {
  const top_b_radius = !reverse
    ? { borderTopLeftRadius: "0px" }
    : { borderTopRightRadius: "0px" };

  const user_pic = message?.user_image
    ? image_url + message?.user_image
    : user_icon;

  return (
    <div className="removescrollbar" style={{ width: "100%" }}>
      <Flex_box
        className="removescrollbar"
        style={
          reverse
            ? {
                justifyContent: "end",
                alignItems: "start",
              }
            : {
                justifyContent: "start",
                alignItems: "start",
                // backgroundColor:"red"
              }
        }
      >
        {!reverse && (
          <img
            // src={`data:image/svg+xml;base64,${currentUser.avatarImage}`}
            src={user_pic}
            style={{
              height: "20px",
              marginTop: "9px",
              marginRight: "10px",
            }}
          />
        )}
        <div>
          <p style={{ fontSize: "10px" }}>{reverse ? "You" : message?.user}</p>
          <Flex_box
            style={{
              alignItems: "",
              marginTop: "-5px",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(2, 103, 193,0.1)",
                fontSize: "12px",
                padding: "10px",
                borderRadius: "8px",
                ...top_b_radius,
              }}
            >
              {message?.text}
            </div>

            {reverse && (
              <img
                src={dot_menu}
                style={{
                  height: "17px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (window.confirm("Do you want to delete this message?")) {
                    del_message(
                      message?._id,
                      message?.text,
                      message?.createdAt
                    );
                  }
                }}
              />
            )}
          </Flex_box>
        </div>

        {reverse && (
          <img
            // src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
            src={user_pic}
            style={{
              height: "20px",
              marginTop: "9px",
              marginLeft: "10px",
            }}
          />
        )}
      </Flex_box>
    </div>
  );
};

const Chat_Box = ({ width, is_gd }) => {
  const [isVideoOn, setisVideoOn] = useState(false);
  const [isAudioOn, setisAudioOn] = useState(false);
  const scrollRef = useRef();
  const [messages, setMessages] = useState([]);
  const [user_joined, setuser_joined] = useState(false);

  const state = useLocation();
  const { user_data } = useSelector((state) => state?.authReducer);
  const [currentUser, setcurrentUser] = useState(user_data || {});
  const [poll_form_modal, setpoll_form_modal] = useState(false);
  const room_id = state?.state?.study_detail?._id;

  const store_message = (message, createdAt) => {
    store_message_api(
      {
        message,
        room_id,
        user_id: user_data?._id,
        user: currentUser?.username,
        user_image: user_data?.profilepictuer,
        createdAt,
      },
      user_data?.role
    )
      .then((res) => {
        console.warn(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_messages = () => {
    get_room_messages_api(
      {
        room_id,
      },
      user_data?.role
    )
      .then((res) => {
        setMessages(
          res?.data?.msg_res?.map((el) => {
            return {
              text: el?.message,
              ...el,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(get_messages, []);

  const join_room = async () => {
    socket.emit(
      "join",
      {
        name: user_data?.username,
        room: room_id,
        message: state?.state?.gd_data?.groupdiscussionname,
        user_image: user_data?.profilepictuer,
        user_id: user_data?._id,
      },
      (error) => {
        if (error) {
          alert(error);
        }
        setuser_joined(true);
      }
    );
  };

  const [effect_func, seteffect_func] = useState(false);

  useEffect(() => {
    const navigationTo = async () => {
      if (effect_func == true) {
        await join_room();
      } else {
        seteffect_func(true);
      }
    };
    navigationTo();
  }, [effect_func, socket]);

  useEffect(() => {
    if (effect_func == true) {
      socket.on("message", (message) => {
        // console.warn(message);

        if (message?.delete == true) {
          setMessages([
            ...new Set(
              messages?.filter((el) => {
                console.warn({ el, message });

                return el?.user_id == message?.user_id
                  ? el?.text == message?.text
                    ? el?.createdAt != message?.createdAt
                    : true
                  : true;
              })
            ),
          ]);
        } else if (
          messages
            ?.map((item) => {
              return item?.text;
            })
            ?.includes(message?.text) &&
          message?.text?.includes("poll~")
        ) {
          setMessages([...new Set(messages)]);
        } else {
          setMessages([...new Set([...messages, message])]);
        }
      });
    } else {
      seteffect_func(true);
    }
  }, [effect_func, socket, messages]);

  const handleSubmit = (e, message, nopreventDefault) => {
    if (!nopreventDefault) {
      e.preventDefault();
    }
    if (message) {
      const date = new Date();
      socket.emit("sendMessage", { message, createdAt: date });
      store_message(message, date);
    } else alert("empty input");
  };

  const del_message = (_id, message, createdAt) => {
    delete_message_api(
      {
        _id,
      },
      user_data?.role
    )
      .then((res) => {
        socket.emit("delMessage", { message, createdAt });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const vid_call = useMemo(
    () =>
      isVideoOn && (
        <V_Call
          room_id={room_id}
          isVideoOn={isVideoOn}
          setIsVideoOn={setisVideoOn}
        />
      ),
    [isVideoOn]
  );

  const aud_call = useMemo(
    () =>
      isAudioOn && (
        <A_Call
          room_id={room_id}
          isAudioOn={isAudioOn}
          setIsAudioOn={setisAudioOn}
        />
      ),
    [isAudioOn]
  );

  return (
    <>
      {
        <Chat_Header
          gd_data={state?.state?.gd_data}
          study_detail={state?.state?.study_detail}
          user_detail={state?.state?.user_detail}
          setpoll_form_modal={setpoll_form_modal}
        />
      }
      <Flex_box
        className="removescrollbar"
        style={{
          width: width || "100%",
          backgroundColor: theme_bg,
          // marginTop: "10px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px #0000000D",
          flexDirection: "column",
          paddingBottom: "10px",
        }}
      >
        {user_joined && (
          <>
            <Flex_box
              className="removescrollbar"
              style={{
                justifyContent: "end",
                gap: 15,
                padding: "14px 0px",
                width: "98%",
              }}
            >
              {user_data?.role == 2 && (
                <img
                  src={poll_icon}
                  onClick={() => {
                    setpoll_form_modal(true);
                  }}
                  style={{
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              )}
              <img
                src={phone_icon}
                onClick={() => {
                  setisAudioOn(true);
                }}
                style={{
                  height: "15px",
                  cursor: "pointer",
                }}
              />
              <img
                src={video_icon}
                onClick={() => {
                  setisVideoOn(true);
                }}
                style={{
                  height: "15px",
                  cursor: "pointer",
                }}
              />
            </Flex_box>
            <div
              style={{
                height: "35vh",
                overflowY: "auto",
                width: "97.5%",
                padding: "20px 10px",
              }}
              onFocus={(e) => {}}
              onLoad={() => {
                var target = document.getElementById("target");
                target.scrollIntoView({ behavior: "smooth" });
              }}
            >
              {messages?.length > 0 &&
                messages.map((message) => {
                  return message?.text?.split("~")[0] == "poll" ? (
                    <>
                      <Poll_Box
                        ref={scrollRef}
                        key={uuidv4()}
                        message={message}
                        reverse={user_data?._id == message.user_id}
                        setMessages={setMessages}
                        messages={messages}
                        gd_data={state?.state?.gd_data}
                        seteffect_func={seteffect_func}
                        effect_func={effect_func}
                        handleSubmit={handleSubmit}
                        socket={socket}
                        del_message={del_message}
                      />
                    </>
                  ) : (
                    <>
                      <Msg_comp
                        ref={scrollRef}
                        key={uuidv4()}
                        message={message}
                        reverse={user_data?._id == message.user_id}
                        del_message={del_message}
                      />
                    </>
                  );
                })}
              <div
                id="target"
                style={{
                  width: "100%",
                  height: "1px",
                }}
              ></div>
            </div>
            {/* </Flex_box> */}
            <Chat_Footer onClick={handleSubmit} />
          </>
        )}
        {!user_joined && (
          <Flex_box
            className="removescrollbar"
            style={{
              width: "97.5%",
              height: "60vh",
              // maxHeight: "65vh",
              backgroundColor: "#fff",
              padding: "20px 10px",
            }}
          >
            <b>No room created yet!!</b>
          </Flex_box>
        )}
      </Flex_box>
      {vid_call}
      {aud_call}
      <Poll_Form
        poll_form_modal={poll_form_modal}
        setpoll_form_modal={setpoll_form_modal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Chat_Box;
