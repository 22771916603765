import React from "react";
import Flex_box from "../../../../utilities/flex_box";
import share_icon from "../../../../assets/Icon open-share-boxed.svg";
import { get_industry_name } from "../../../../utilities/select_arr";

const Chat_Header = ({ gd_data, study_detail, setpoll_form_modal }) => {

  return (
    <Flex_box
      className="remove_scrollbar"
      style={{
        justifyContent: "space-between",
      }}
    >
      <b
        style={{
          fontSize: "22px",
        }}
      >
        {gd_data?.groupdiscussionname}
      </b>
      <button
        id="btn_comp"
        style={{
          width: "40px",
          height: "40px",
          // padding:"0px"
        }}
      >
        <img
          src={share_icon}
          style={{
            height: "15px",
          }}
        />
      </button>
    </Flex_box>
  );
};

export default Chat_Header;
