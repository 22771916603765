// reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import baseReducer from './base_reducer';
import appReducer from "./app_reducer";

const rootReducer = combineReducers({
    authReducer: authReducer,
    baseReducer: baseReducer,
    appReducer:appReducer
});

export default rootReducer;
