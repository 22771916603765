import React from 'react'
import logo from "../../../assets/Group 40944@2x.png"
import { theme_color } from '../../../utilities/colors'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Button from '../../../utilities/Button'

const Pre_login = () => {

    const navigate = useNavigate();

    const matches_500 = useMediaQuery('(max-width:500px)');

    return (
        // <div
        //     className='auth_box_cont'
        // >
        <div
            className='auth_box'
            style={{
                width: matches_500 ? '90vw' : "40vw",
            }}
        >
            <img
                src={logo}
                style={{
                    height: matches_500 ? '65px' : '80px',
                }}
            />
            <span
                style={{
                    fontSize: matches_500 ? '20px' : '26px',
                    textAlign: 'center',
                    marginTop: '25px'
                }}>
                <span>Get ready to unlock impactful insights</span>
                <br />
                <b
                    style={{
                        fontStyle: 'italic'
                    }}
                >
                    from human coversations
                </b>
            </span>
            <Button
                style={{
                    marginTop: "40px"
                }}
                onClick={() => {
                    navigate('/signup');
                }}
                label={'Sign Up'}
                >
                Sign Up
            </Button>
            <button
                style={{
                    border: '1px solid ' + theme_color,
                    color: theme_color,
                    padding: '13px 0px',
                    textAlign: 'center',
                    borderRadius: 10,
                    width: '210px',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginTop: '20px',
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigate('/login');
                }}
            >
                Log In
            </button>
        </div>
        // </div >
    )
}

export default Pre_login
