import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Sidebar from "../../Nav_Comp/Sidebar";
import Navbar from "../../Nav_Comp/Navbar";
import survey_pic from "../../../assets/Group 67979.png";
import gd_pic from "../../../assets/Group 68039.png";
import "./select_study.css";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Nav_Comp/Footer";

const Select_Study_Type = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  console.warn(state);

  return (
    <Flex_box
      style={{
        justifyContent: "start",
      }}
    >
      <Sidebar />
      <div
        className="comp_container"
        style={{
          position: "relative",
        }}
      >
        <Navbar />
        <Flex_box
          style={{
            gap: 40,
            height: "78.5vh",
            // padding:"20px 0px"
            // backgroundColor:"red"
          }}
        >
          <Flex_box
            className="select_st_box"
            style={{
              gap: 20,
              width: "40%",
            }}
          >
            <img
              src={survey_pic}
              style={{
                height: "200px",
              }}
            />
            <span
              style={{
                fontSize: "22px",
              }}
            >
              Create a Survey
            </span>
            <button
              id="btn_comp"
              onClick={() => {
                navigate("/create-survey", { state: state });
              }}
            >
              <span style={{ fontWeight: 520, fontSize: "14px" }}>
                Continue
              </span>
            </button>
          </Flex_box>

          <Flex_box
            className="select_st_box"
            style={{
              gap: 20,
              width: "40%",
            }}
          >
            <img
              src={gd_pic}
              style={{
                height: "200px",
              }}
            />
            <span
              style={{
                fontSize: "22px",
              }}
            >
              Create a Group Discussion
            </span>
            <button
              id="btn_comp"
              onClick={() => {
                navigate("/create-gd", { state: state });
              }}
            >
              <span style={{ fontWeight: 520, fontSize: "14px" }}>
                Continue
              </span>
            </button>
          </Flex_box>
        </Flex_box>
        <Footer />
      </div>
    </Flex_box>
  );
};

export default Select_Study_Type;
