import React, { useEffect, useState } from "react";
import bell_icon from "../../../assets/Component 37 – 3.svg";
import { get_resp_noti_api } from "../../../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../redux_prog/actions/base_action";
import { Modal } from "../../../utilities/Modal";
import Flex_box from "../../../utilities/flex_box";

const Notifications = ({ get_resp_noti, setmodal_open }) => {
  const dispatch = useDispatch();
  const login_type = useSelector((state) => state?.authReducer?.login_type);

  return (
    <>
      <img
        src={bell_icon}
        style={{
          height: "30px",
          cursor: "pointer",
        }}
        onClick={() => {
          // if (login_type == 3) {
            get_resp_noti();
          // }
        }}
      />
    </>
  );
};

export default Notifications;
