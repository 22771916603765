import React from "react";
import "./study_box.css";
import { useNavigate } from "react-router-dom";
import Flex_box from "../../../../utilities/flex_box";
import Progress_bar from "../../../../utilities/Progress_bar";
import green_check from "../../../../assets/Mask Group 4.png";
import { get_industry_name } from "../../../../utilities/select_arr";

const Study_Box = ({ progress, type, completed, item }) => {
  const navigate = useNavigate();

  const is_gd = item?.type == "gd";
  const { study } = item || {};

  return (
    <div
      className="study_box_cont"
      style={{
        gap: 8,
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        {study?.studyname}
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(study?.studyoninductry)}
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 500 }}>
          Male & Female, 25 - 50 Years Old
        </span>
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Close Date </span>: 12 January 2024
      </span>
      {item.type && (
        <span
          style={{
            fontSize: "15px",
          }}
        >
          <span style={{ fontWeight: 600 }}>Study type </span>:{" "}
          {is_gd ? "Group Discussion" : "Survey"}
        </span>
      )}
      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Incentive amount </span>: $
        {study?.incevtiveamount}
      </span>
      <p
        style={{
          fontSize: "11px",
          lineHeight: 1.5,
          height:"80px",
          overflowY:"auto",
          textWrap:"wrap",
          whiteSpace:"wrap",
          overflowX:"hidden",
          width:"100%"
        }}
      >
        {study?.description}
      </p>

      <Flex_box
        style={{
          width: "",
          gap: 25,
          justifyContent: "start",
          marginTop: "10px",
        }}
      >
        {completed && (
          <Flex_box
            style={{
              // width: '',
              justifyContent: "start",
              gap: 10,
            }}
          >
            <b
              style={{
                fontSize: "18px",
              }}
            >
              Completed
            </b>
            <img
              style={{
                height: "20px",
              }}
              src={green_check}
            />
          </Flex_box>
        )}
        <button
          id="btn_comp"
          style={{ fontSize: "16px", fontWeight: "500" }}
          onClick={() => {
            if (item?.type) {
              navigate("/study-details", {
                state: {
                  type: type,
                  completed,
                  id: study?._id,
                },
              });
            }
          }}
        >
          View Details
        </button>
        {!completed && (
          <button
            id="btn_comp"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              backgroundColor: "#FFCC99",
              color: "#000",
            }}
            onClick={() => {
              navigate("/survey-analysis");
            }}
          >
            Rate Study
          </button>
        )}
      </Flex_box>
    </div>
  );
};

export default Study_Box;
