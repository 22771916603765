import React, { useState } from 'react'
import Flex_box from '../../../../utilities/flex_box'
import msg_icon from "../../../../assets/001-ui.svg";
import { theme_color } from '../../../../utilities/colors';
import pin_icon from "../../../../assets/attach.svg";

const Chat_Footer = ({ onClick }) => {

    const [msg, setmsg] = useState('');

    return (
        <Flex_box
            style={{
                justifyContent: "space-between",
                padding: "15px",
                boxShadow: "0px -5px 15px #53B1FF0D",
                backgroundColor: "#fff",
                width: "97%",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px"

            }}
        >
            <input
                placeholder='Message...'
                className="transaparent_input"
                style={{
                    border: "0px",
                    width: "70%",
                    color: "#000",
                    fontSize: "16px"
                }}
                value={msg}
                onChange={(e) => {
                    setmsg(e.target.value);
                }}
            />
            <Flex_box
                style={{
                    width: '',
                    gap: 20
                }}
            >
                <Flex_box
                    style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: "rgb(2, 103, 193,0.08)",
                    }}
                >
                    <img src={pin_icon}
                        style={{
                            height: "15px"
                        }}
                    />
                </Flex_box>
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundColor: theme_color
                    }}
                    onClick={(e) => {
                        if (msg && onClick) {
                            onClick(e,msg);
                            setmsg('');
                        }
                    }}
                >
                    <img src={msg_icon}
                        style={{
                            height: "15px",
                            cursor: "pointer"
                        }}
                    />
                </div>
            </Flex_box>
        </Flex_box>
    )
}

export default Chat_Footer
