import GD_Chat from "../screens/Respondant_screens/My_Studies/GD_Chat";
import Balance from "../screens/Respondant_screens/Balance";
import Dashboard from "../screens/Respondant_screens/Dashboard";
import My_Studies from "../screens/Respondant_screens/My_Studies/My_Studies";
import Study_Details from "../screens/Respondant_screens/My_Studies/Study_Details";
import Survey_Response from "../screens/Respondant_screens/Survey_Response";
import Take_Survey from "../screens/Respondant_screens/Take_Survey"
import Terms_conditions from "../screens/Terms_conditions";
import Privacy_Policy from "../screens/Privacy_Policy";
import FAQ from "../screens/Respondant_screens/FAQ";
import My_Profile from "../screens/Respondant_screens/Profile/My_Profile";
import Edit_Profile from "../screens/Respondant_screens/Profile/Edit_Profile";
import Chats from "../screens/Chat_Screens/pages/Chats";

export const respondent_routes = [
    {
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        path: '/my-studies',
        element: <My_Studies />
    },
    {
        path: '/study-details',
        element: <Study_Details />
    },
    {
        path: '/take-survey',
        element: <Take_Survey />
    },
    {
        path: '/gd-chat',
        element: <GD_Chat />
    },
    {
        path: "terms-conditions",
        element: <Terms_conditions />,
    },
    {
        path: "privacy-policy",
        element: <Privacy_Policy />,
    },
    {
        path: "faq",
        element: <FAQ />,
    },
    {
        path: "my-balance",
        element: <Balance />,
    },
    {
        path: "survey-response",
        element: <Survey_Response />,
    },
    {
        path: '/my-profile',
        element: <My_Profile />
    },
    {
        path: '/edit-profile',
        element: <Edit_Profile />
    },
    {
        path: '/chat-comp',
        element: <Chats />
    },
];