import React from "react";
import Flex_box from "../flex_box";

const Title = ({ title, subtitle, border_b_null }) => {
  return (
    <>
      <div
        style={{
          padding: "10px 0px",
          width: "100%",
          borderBottom: border_b_null ? "" : "1px solid grey",
        }}
      >
        <Flex_box>
          <b
            style={{
              color: "#000",
              fontSize: "20px",
              // fontWeight: "600",
            }}
          >
            {title}
          </b>
        </Flex_box>
        {subtitle && (
          <Flex_box style={{ fontSize: "13px", color: "#000" }}>
            {subtitle}
          </Flex_box>
        )}
      </div>
    </>
  );
};

export default Title;
