import { theme_bg } from "./colors";
import Flex_box from "./flex_box";

export const Modal = ({
  isOpen,
  label,
  onClose,
  children,
  style,
  content_style,
  footer
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal" style={style || {}}>
      <div className="modal-content" style={content_style || {}}>
        {/* <span className="close" onClick={onClose}>&times;</span> */}
        {label && (
          <Flex_box
            style={{
              backgroundColor: theme_bg,
              padding: "10px",
              justifyContent: "space-between",
              width: "97.5%",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              {label}
            </span>
            {onClose && (
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
                onClick={onClose}
              >
                &times;
              </span>
            )}
          </Flex_box>
        )}
        {children}
        {footer || null}
      </div>
    </div>
  );
};
