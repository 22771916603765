// reducers/userReducer.js
const initialState = {
    is_authenticated: false,
    token: '',
    user_data: {},
    login_type: '3',  //1=ADMIN, 2= RESEARCHER, 3= RESPONDENT, 4=MODERATOR,
    intro_completed: false,
    auth_otp: ''
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                is_authenticated: true
            };
        case 'LOGOUT':
            return {
                ...state,
                is_authenticated: false
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload
            };
        case 'SET_USER':
            return {
                ...state,
                user_data: action.payload
            };
        case 'SET_LOGIN_TYPE':
            return {
                ...state,
                login_type: action.payload
            };
        case 'SET_INTRO_STATUS':
            return {
                ...state,
                intro_completed: action.payload
            };
        case 'SET_OTP':
            return {
                ...state,
                auth_otp: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;
