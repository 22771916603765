import React from "react";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Flex_box
      style={{
        width: "102%",
        gap: 20,
        // padding: "5px 0px",
        color: "#fff",
        backgroundColor: theme_color,
        zIndex: 1000,
        // position: "absolute",
        bottom: "-13px",
        position: "fixed",
        bottom: "0px",
        right:'0px'
      }}
    >
      <p
        style={{
          fontSize: "12px",
          fontWeight: 500,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/terms-conditions");
        }}
      >
        Terms & Conditions
      </p>
      <p
        style={{
          fontSize: "12px",
          fontWeight: 500,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/privacy-policy");
        }}
      >
        Privacy Policy
      </p>
    </Flex_box>
  );
};

export default Footer;
