import React, { useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import Auth_header from "../Auth_header";
import { useDispatch, useSelector } from "react-redux";
import { signup_api, verify_otp_api, verifyotp_api } from "../../../apis/apis";
import { alert_action } from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import { set_user_action } from "../../../redux_prog/actions/auth_action";
import axios from "axios";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";

const OTP_Verify_Comp = () => {
  const { login_type, auth_otp, user_data } =
    useSelector((state) => state?.authReducer) || {};
  const path_state = useLocation();
  const study_id = path_state?.state?.study_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const [otp_arr, setotp_arr] = useState([]);

  // const otp_arr = auth_otp?.split("");
  // alert(otp_arr);

  const [otp1, setotp1] = useState(otp_arr[0] || "");
  const [otp2, setotp2] = useState(otp_arr[1] || "");
  const [otp3, setotp3] = useState(otp_arr[2] || "");
  const [otp4, setotp4] = useState(otp_arr[3] || "");

  const verify_otp = () => {
    if (otp1 && otp2 && otp3 && otp4) {
      const input_otp = otp1 + otp2 + otp3 + otp4;
      // console.log({ input_otp, auth_otp, user_data });
      if (input_otp) {
        if (login_type == "2") {
          setloading(true);
          verifyotp_api({
            index: parseInt(auth_otp),
            otp: input_otp,
          })
            .then(async (otp_res) => {
              setloading(false);
              if (otp_res.data.status == 1) {
                signup_api({
                  ...user_data,
                })
                  .then(async (res) => {
                    setloading(false);
                    if (res.data.status == 1) {
                      // console.log(res.data);
                      dispatch(set_user_action(res.data.Res));
                      dispatch(set_temp_user_action(""));
                      // if (login_type == '2') {
                      navigate("/researcher_auth", {
                        state: { user_id: res.data.Res?._id },
                      });
                    } else {
                      dispatch(
                        alert_action({
                          title: `Invalid Credentials`,
                          type: "error",
                          msg: res.data.msg,
                        })
                      );
                    }
                  })
                  .catch((err) => {
                    console.log({ err });
                    setloading(false);
                    dispatch(
                      alert_action({
                        title: `Invalid Credentials`,
                        type: "error",
                        msg: "Some error occurred!",
                      })
                    );
                  });
              } else {
                dispatch(
                  alert_action({
                    title: `Invalid OTP`,
                    type: "error",
                    msg: "Enter the correct OTP!",
                  })
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          verifyotp_api({
            index: parseInt(auth_otp),
            otp: input_otp,
          })
            .then(async (res) => {
              setloading(false);
              if (res.data.status == 1) {
                navigate("/respondent_auth", {
                  state: { study_id },
                });
              } else {
                dispatch(
                  alert_action({
                    title: `Invalid OTP`,
                    type: "error",
                    msg: "Enter the correct OTP!",
                  })
                );
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        dispatch(
          alert_action({
            title: `Invalid OTP`,
            type: "error",
            msg: "Enter the correct OTP!",
          })
        );
      }
    } else {
      dispatch(
        alert_action({
          title: `Invalid OTP`,
          type: "error",
          msg: "Enter all 4 digits!",
        })
      );
    }
  };

  return (
    <div className="auth_box_cont">
      <div
        className="auth_box"
        style={{
          padding: "15px",
          paddingBottom: "30px",
        }}
      >
        <Auth_header label={"Verification"} />

        <span
          className="responsive_Width"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            padding: "25px 0px",
          }}
        >
          We have sent a 4-digit verification code to your Email address.
        </span>

        <span
          className="responsive_width"
          style={{
            fontSize: "10px",
            marginLeft: "-7px",
            // padd
          }}
        >
          Your code here
        </span>
        <Flex_box
          className="responsive_width"
          style={{ width: "", gap: 30, marginTop: "10px" }}
        >
          <input
            type="text"
            maxlength="1"
            value={otp1}
            onChange={(e) => {
              setotp1(e.target.value);
              document.getElementById("hop_1").focus();
            }}
            class="otp-input"
            style={{ color: theme_color }}
          />
          <input
            id="hop_1"
            type="text"
            maxlength="1"
            value={otp2}
            onChange={(e) => {
              setotp2(e.target.value);
              document.getElementById("hop_2").focus();
            }}
            class="otp-input"
            style={{ color: theme_color }}
          />
          <input
            id="hop_2"
            type="text"
            maxlength="1"
            value={otp3}
            onChange={(e) => {
              setotp3(e.target.value);
              document.getElementById("hop_3").focus();
            }}
            class="otp-input"
            style={{ color: theme_color }}
          />
          <input
            id="hop_3"
            type="text"
            maxlength="1"
            value={otp4}
            onChange={(e) => setotp4(e.target.value)}
            class="otp-input"
            style={{ color: theme_color }}
          />
        </Flex_box>
        <Flex_box
          className="responsive_width"
          style={{
            fontSize: "10px",
            justifyContent: "space-between",
            width: "",
            marginTop: "10px",
            marginLeft: "-7px",
            // padd
          }}
        >
          <span>Expires in 10s</span>
          <b style={{ fontStyle: "italic", color: theme_color }}>Resend</b>
        </Flex_box>

        <Button
          style={{
            marginTop: "40px",
          }}
          onClick={() => {
            verify_otp();
          }}
          label={"Confirm"}
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default OTP_Verify_Comp;
