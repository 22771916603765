import React from "react";
import pdf_icon from "../../../../assets/bxs-file-pdf.svg";
import share_icon from "../../../../assets/share.svg";
import { theme_bg } from "../../../../utilities/colors";
import Flex_box from "../../../../utilities/flex_box";
import Progress_bar from "../../../../utilities/Progress_bar";
import { get_industry_name } from "../../../../utilities/select_arr";
import edit_icon from "../../../../assets/edit.svg";
import { theme_color } from "../../../../utilities/colors.js";
import { useNavigate } from "react-router-dom";
import { close_study_api, page_url } from "../../../../apis/apis.js";
import {
  alert_action,
  loading_action,
} from "../../../../redux_prog/actions/base_action.js";
import { useDispatch } from "react-redux";
import { get_percentage } from "../../../../utilities/Get_percentage";
import Print_Report from "../Print_Report/index.js";

const Study_header = ({ study_detail, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    description,
    studyname,
    studyoninductry,
    totalrespondents,
    groupdiscussionname,
    _id,
  } = study_detail;

  const surveys_lookup = study_detail?.surveys_lookup || [];
  const surveyres_lookup = surveys_lookup[0]?.surveyres_lookup || [];
  const max_resp = parseInt(totalrespondents);
  const totalResponse = surveyres_lookup?.length;
  const totalResponse_perc = get_percentage(totalResponse, max_resp) + "%";
  const completedResponse =
    surveyres_lookup?.length > 0 &&
    surveyres_lookup?.filter((el) => {
      return el?.iscompleted == true;
    })?.length;
  const completedResponse_perc =
    get_percentage(completedResponse, max_resp) + "%";

  const del_study = () => {
    dispatch(
      alert_action({
        type: "success",
        title: "Close?",
        msg: "Do you want to Close this study?",
        is_confirm: true,
        onSuccess: () => {
          dispatch(loading_action(true));
          close_study_api({
            StudyId: _id,
          })
            .then((res) => {
              dispatch(loading_action(false));
              dispatch(
                alert_action({
                  type: "success",
                  title: "Success",
                  msg: "Study deleted successfully!",
                })
              );
              navigate(-1);
            })
            .catch((err) => {
              dispatch(loading_action(false));
              console.log(err);
            });
        },
      })
    );
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme_bg,
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "20px",
      }}
    >
      <Flex_box
        style={{
          justifyContent: "space-between",
        }}
      >
        <Flex_box
          style={{
            width: "",
            gap: 10,
          }}
        >
          <b
            style={{
              fontSize: "22px",
            }}
          >
            {groupdiscussionname || studyname}
          </b>
          <button
            onClick={() => {
              const new_obj = {
                ...study_detail,
                iscomplete: study_detail?.iscomplete ? "true" : "false",
                __v: "0",
              };

              navigate("/create-study", {
                state: {
                  study_detail: new_obj,
                },
              });
            }}
            id="btn_comp"
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: theme_color,
              boxShadow: "0px 2px 6px #0000000D",
              paddingTop: "7px",
            }}
          >
            <img
              src={edit_icon}
              style={{
                height: "15px",
              }}
            />
          </button>
        </Flex_box>
        <Flex_box
          style={{
            width: "",
            gap: 20,
          }}
        >
          <button
            id="btn_comp"
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "#fff",
              boxShadow: "0px 2px 6px #0000000D",
              // padding:"0px"
            }}
            onClick={() => {
              if (type == "gd") {
                navigator.clipboard
                  .writeText(page_url + "study-details?" + _id)
                  .then((res) => {
                    alert("Link copied successfully!");
                  })
                  .catch((err) => {
                    console.warn(err);
                  });
              } else {
                navigator.clipboard
                  .writeText(page_url + "take-survey?" + _id)
                  .then((res) => {
                    alert(
                      "Survey link copied successfully! People with whom you share this can give survey!"
                    );
                  })
                  .catch((err) => {
                    console.warn(err);
                  });
              }
            }}
          >
            <img
              src={share_icon}
              style={{
                height: "15px",
              }}
            />
          </button>

          <Print_Report studyname={studyname} study_id={study_detail?._id} />
        </Flex_box>
      </Flex_box>
      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(studyoninductry)}
      </span>
      <p
        style={{
          fontSize: "15px",
          marginTop: "8px",
        }}
      >
        <span style={{ fontWeight: 700 }}>
          {totalResponse}/{max_resp}{" "}
        </span>{" "}
        <span style={{ fontWeight: 500 }}>Respondents</span>
      </p>
      <Flex_box
        style={{
          gap: 20,
          justifyContent: "start",
        }}
      >
        <div
          style={{
            width: "70%",
          }}
        >
          <span style={{ fontSize: "11px" }}>{description}</span>
          <br />
          <button
            id="btn_comp"
            style={{
              marginTop: "20px",
            }}
            onClick={del_study}
          >
            Close Study
          </button>
        </div>
        {type == "survey" && (
          <div
            style={{
              width: "29%",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "15px",
              boxSizing: "border-box",
            }}
          >
            <Flex_box
              style={{
                justifyContent: "start",
                alignItems: "",
                gap: 10,
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {totalResponse_perc}
              </span>
              <Flex_box
                style={{
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 8,
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Response rate
                </span>
                <Progress_bar
                  style={{ width: "90%" }}
                  progress={totalResponse_perc}
                />
              </Flex_box>
            </Flex_box>
            <Flex_box
              style={{
                justifyContent: "start",
                alignItems: "",
                gap: 10,
                marginTop: "15px",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {completedResponse_perc}
              </span>
              <Flex_box
                style={{
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 8,
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Completion rate
                </span>
                <Progress_bar
                  style={{ width: "90%" }}
                  progress={completedResponse_perc}
                />
              </Flex_box>
            </Flex_box>
          </div>
        )}
      </Flex_box>
    </div>
  );
};

export default Study_header;
