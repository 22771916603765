import React from "react";
import footer_bg from "../../../assets/Group2255.svg";
import bg2 from "../../../assets/Path11389.png";
import logo from "../../../assets/Group 40944.png";
import Flex_box from "../../../utilities/flex_box";
import Nav_Link from "./Nav_Link";
import facebook_icon from "../../../assets/Group 48347.svg";
import linkedin_icon from "../../../assets/Group 48348.svg";
import insta_icon from "../../../assets/Group 48349.svg";
import { theme_color } from "../../../utilities/colors";
import zIndex from "@mui/material/styles/zIndex";
import { useMediaQuery } from "@mui/material";
// import logo from "../../../assets/Group 40944.png";

const Footer = () => {
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <>
      {/* <Flex_box
        style={{
          backgroundImage: `url(${footer_bg})`,
          backgroundPosition: "center bottom",
          padding: "50px 0px",
          flexDirection: "column",
          gap: 30,
          position: "relative",
        }}
      >
        <img
          src={bg2}
          style={{
            width: "100%",
            position: "absolute",
            bottom: "-50px",
            right: 0,
            zIndex: 100,
          }}
        />
        <img
          src={logo}
          style={{
            height: "50px",
          }}
        />
        {!max_w_1000 && (
          <Flex_box
            style={{
              boxSizing: "border-box",
              gap: 40,
              zIndex: 100,
            }}
          >
            <Nav_Link label="Home" to="nav_comp" />
            <Nav_Link label="Abouts Us" />
            <Nav_Link label="Features" to={"subscriptions"} />
            <Nav_Link label="Pricing" />
            <Nav_Link label="Support" to={"contact_us"} />
          </Flex_box>
        )}
        <Flex_box
          style={{
            boxSizing: "border-box",
            gap: 20,
            zIndex: 101,
          }}
        >
          <img
            src={facebook_icon}
            style={{
              height: "40px",
            }}
          />
          <img
            src={linkedin_icon}
            style={{
              height: "40px",
            }}
          />
          <img
            src={insta_icon}
            style={{
              height: "40px",
            }}
          />
        </Flex_box>
        <Flex_box
          style={{
            backgroundColor: theme_color,
            padding: "15px 0px",
            fontSize: "12px",
            color: "#fff",
            zIndex: 101,
            position: "absolute",
            bottom: "-50px",
          }}
        >
          <span>© 2024 - All Rights Reserved - Yaarnbox</span>
        </Flex_box>
      </Flex_box> */}
      <Flex_box
        style={{
          justifyContent: "center",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "",
          gap: 40,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <img
            src={logo}
            style={{
              height: "40px",
            }}
          />
          <p
            style={{
              fontSize: "13px",
              width: "280px",
            }}
          >
            Yaarnbox enables businesses to verify, authenticate, and engage
            customers globally.
          </p>
          <Flex_box
            style={{
              boxSizing: "border-box",
              gap: 20,
              zIndex: 101,
              justifyContent: "start",
            }}
          >
            <img
              src={facebook_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={linkedin_icon}
              style={{
                height: "30px",
              }}
            />
            <img
              src={insta_icon}
              style={{
                height: "30px",
              }}
            />
          </Flex_box>
        </div>
        <div
          style={{
            fontSize: "13px",
            width: max_w_1000 ? "100%" : "",
          }}
        >
          <b
            style={{
              fontSize: "15px",
            }}
          >
            Company
          </b>
          <p>About Yaarnbox</p>
          <p>Careers</p>
          <p>Terms of service</p>
          <p>Privacy Policies</p>
          <p>Compliance</p>
          <p>Annual Report (2023)</p>
        </div>
        <Flex_box
          style={{
            width: "",
            flexWrap: "wrap",
            gap: 10,
            justifyContent: "start",
            alignItems: "",
          }}
        >
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/us.122a7364.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>SAN JOSE, CA</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/nig.b73fdda9.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>LAGOS, NG</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
          <div
            style={{
              maxWidth: "250px",
            }}
          >
            <img
              src="https://www.termii.com/_nuxt/Cote.d6c74cee.svg"
              style={{
                height: "40px",
              }}
            />
            <br />
            <b>ABIDJAN, CI</b>
            <div
              style={{
                fontSize: "13px",
              }}
            >
              <p>2880 Zanker Road Suite 203 San Jose CA 95134</p>
              <p>1-669-241-1742</p>
              <p>xyz@mail.com</p>
            </div>
          </div>
        </Flex_box>
      </Flex_box>
    </>
  );
};

export default Footer;
