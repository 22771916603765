import React from "react";
import loading_img from "../assets/loading.gif";
import Flex_box from "./flex_box";

const Button = ({ style, disabled, loading, onClick, label }) => {
  const style_obj = style || {};
  const load_height = loading ? { minHeight: "43px" } : {};

  return (
    <button
      id="btn_comp"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      style={{
        ...style_obj,
        position: "relative",
        opacity: disabled || loading ? 0.5 : 1,
        cursor: disabled ? "default" : "pointer",
        ...load_height,
      }}
      disabled={disabled}
    >
      {loading && (
        <Flex_box
          style={{
            width: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
          }}
        >
          <img
            src={loading_img}
            style={{
              height: "20px",
            }}
          />
        </Flex_box>
      )}
      <span style={{ visibility: !loading ? "visible" : "hidden" }}>
        {label}
      </span>
    </button>
  );
};

export default Button;
