import { useLocation, useNavigate } from "react-router-dom";
import { theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import Sub_Menu from "./Sub_Menu";
import { useState } from "react";

const Nav_Link = ({ label, isActive, to, id }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [hover_nav, sethover_nav] = useState("");
  return (
    <div
      onMouseOver={() => {
        document.getElementById(id).style.color = "#fff";
        sethover_nav(to);
      }}
      onMouseOut={() => {
        document.getElementById(id).style.color = "#000";
        sethover_nav("");
      }}
    >
      <Flex_box
        className="nav_link_cont"
        style={{
          width: "",
          // gap: 6,
          flexDirection: "column",
          position: "relative",
        }}
      >
        <p
          onClick={() => {
            to && navigate(to);
          }}
          id={id}
          className="nav_link_text"
          style={{
            fontSize: "14px",
            color: isActive ? theme_color : "",
            fontWeight: 600,
            cursor: "pointer",
            zIndex: 200,
          }}
        >
          {label}
        </p>
        {to == pathname && (
          <div
            style={{
              padding: "4px",
              backgroundColor: theme_color,
              borderRadius: "50%",
              position: "absolute",
              bottom: "0px",
            }}
          ></div>
        )}
        {/* {hover_nav == to && <Sub_Menu />} */}
      </Flex_box>
    </div>
  );
};

export default Nav_Link;
