import React, { useEffect, useState } from "react";
import Flex_box from "../../../../../utilities/flex_box";
import blue_circle from "../../../../../assets/Group 38514.svg";
import green_check from "../../../../../assets/check-circle-solid.svg";
import Text_Input from "../../../../../utilities/Text_Input";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import { Checkbox, Radio } from "@mui/material";
import Select_box from "../../../../../utilities/Select_box";
import Sub_Option_MCQ_Box from "./Sub_Option_MCQ_Box";

// const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

const Option_Box = ({ item, type }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        gap: 15,
        marginTop: "15px",
        width: "100%",
      }}
    >
      {type == 2 && (
        <Checkbox
          // checked={checked}
          // onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      )}
      {type == 3 && (
        <Radio
          // checked={true}
          // onChange={handleChange}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
      )}
      <div
        id="text_input"
        className="removescrollbar"
        style={{
          textWrap: "wrap",
          whiteSpace: "wrap",
          overflowY: "auto",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "87%",
        }}
      >
        <p>{item?.optiontitle}</p>
      </div>
    </Flex_box>
  );
};

const MCQ_Comp = ({
  item,
  index,
  allQuestions,
  setquestion_arr,
  handelDependentQuestion,
  selectedQuestionIndex,
  setselectedQuestionIndex,
}) => {
  // console.log("question list", question_data);

  const [questionList, setQuestionList] = useState([
    { label: "Remove", value: "" },
  ]);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const handelChedkedBox = (e, serial_no, index) => {
    var questions = [];
    console.log("qstn lst", allQuestions);
    for (let i = 0; i < allQuestions.length; i++) {
      const existing_qs = [].concat(
        ...allQuestions?.map((el) => {
          return el?.Option?.map((li) => {
            return parseInt(li.dependent_question);
          });
        })
      );

      console.log(existing_qs, allQuestions[i].serial_no)

      if (allQuestions[i].serial_no > serial_no && !existing_qs?.includes(allQuestions[i].serial_no)) {
        questions.push({
          label: allQuestions[i].questiontitle,
          value: allQuestions[i].serial_no,
        });
      }
      //}
    }
    setQuestionList(questions);

    if (selectedQuestionIndex != index) {
      // setSelectedQuestionIndex(index);
      setselectedQuestionIndex(index);
    } else {
      setselectedQuestionIndex(null);
    }
  };

  const handelSelectedQuestion = (ind, val, serial_no) => {
    console.log({ allQuestions, id: item.serial_no, ind, val });

    setquestion_arr(
      allQuestions.map((el) => {
        if (el.serial_no == item?.serial_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  switch_to: val,
                };
              } else {
                return {
                  ...li,
                };
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Flex_box
          style={{
            marginTop: index == 0 ? "20px" : "30px",
            width: "98%",
            gap: 10,
          }}
        >
          <Text_Input
            label={"Question " + (index + 1)}
            cont_style={{
              width: "98%",
            }}
            value={item?.questiontitle}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </Flex_box>
        {item?.questiontype == 3 && (
          <div
            className="checkbox"
            style={{ margin: "0 10px", position: "relative", top: "18px" }}
          >
            <label htmlFor="" style={{ display: "flex" }}>
              <input
                type="checkbox"
                checked={selectedQuestionIndex == index}
                onClick={(e) => handelChedkedBox(e, item.serial_no, index)}
              />
              <span style={{ marginLeft: "5px" }}>Related</span>
            </label>
          </div>
        )}
      </div>
      {item?.Option?.length > 0 && (
        <>
          <span
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            Select Correct Answer Below
          </span>

          {item?.Option?.map((li, ind) => {
            return (
              <>
                <div className="dropdown_wrap">
                  <Option_Box item={li} type={item.questiontype} key={ind} />
                  {selectedQuestionIndex == index && (
                    <div className="dropdown" style={{ marginLeft: "10px" }}>
                      <Select_box
                        options={questionList}
                        placeholder={"Switch to"}
                        //  value={questionList?.filter((elem)=>{return elem?.value == li.dependent_question})[0]}
                        value={li.dependent_question}
                        onChange={(e) => {
                          console.log(e.target.value);
                          handelSelectedQuestion(
                            ind,
                            e.target.value,
                            item.serial_no
                          );
                          handelDependentQuestion(e.target.value, index, ind);
                        }}
                        input_style={{
                          height: "46px",
                        }}
                        cont_style={{
                          marginTop: "7px",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

const MCQ_Box = ({
  question_arr,
  setquestion_arr,
  handelDependentQuestion,
}) => {
  console.log("all question", question_arr);
  // const handelDependentQuestion = (value) => {
  // alert(value);
  // }
  const [selectedQuestionIndex, setselectedQuestionIndex] = useState(null);

  return (
    <Flex_box
      className="responsive_width_35"
      style={{
        padding: "15px",
        paddingTop: "0px",
        borderRadius: "8px",
        flexDirection: "column",
        width: "",
        justifyContent: "start",
        alignItems: "start",
        boxShadow: "0px 0px 10px #0000000D",
      }}
    >
      {question_arr.map((el, index) => {
        console.warn(el?.questiontype);
        return (
          <>
            {/* {el?.questiontype} */}
            {el?.questiontype != 4 ? <MCQ_Comp
              setquestion_arr={setquestion_arr}
              item={el}
              key={index}
              index={index}
              allQuestions={question_arr}
              handelDependentQuestion={handelDependentQuestion}
              selectedQuestionIndex={selectedQuestionIndex}
              setselectedQuestionIndex={setselectedQuestionIndex}
            />
              :
              <Sub_Option_MCQ_Box
                setquestion_arr={setquestion_arr}
                item={el}
                key={index}
                index={index}
                allQuestions={question_arr}
                handelDependentQuestion={handelDependentQuestion}
                selectedQuestionIndex={selectedQuestionIndex}
                setselectedQuestionIndex={setselectedQuestionIndex}
              />
            }
          </>
        );
      })}
      {/* <Flex_box
                style={{
                    paddingTop: "20px"
                }}
            >
                <button
                    id='btn_comp'
                    style={{
                        // marginTop: "40px",
                        backgroundColor: theme_bg,
                        border: "2px solid " + theme_color,
                        color: theme_color,
                        fontWeight: 600,
                        padding: "8px",
                        width: "150px"
                    }}
                    onClick={() => {
                        // navigate("/select-study-type");
                        window.scrollTo({
                            top:0,
                            behavior:"smooth"
                        })
                    }}
                >
                    Add Question
                </button>
            </Flex_box> */}
    </Flex_box>
  );
};

export default MCQ_Box;
