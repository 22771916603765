import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '../reducer/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authReducer','appReducer'], // Specify only 'authReducer' to be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware() // You can add middleware here if needed
);

const persistor = persistStore(store);

export { store, persistor };
