import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import Flex_box from "../../../utilities/flex_box";
import Chat_Box from "../../Researcher_screens/My_Studies/GD_Chat/Chat_Box_";

export default function Chats() {
  const state = useLocation();

  const is_gd = state?.state?.is_gd;

  return (
    <Layout
      style={{
        padding: "20px",
      }}
    >
      <Flex_box
        className="removescrollbar"
        style={{
          flexWrap: "wrap",
          justifyContent: "start",
          gap: 20,
        }}
      >
        <Chat_Box
          is_gd={is_gd}
          width={"97%"}
        />
      </Flex_box>
    </Layout>
  );
}
