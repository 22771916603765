import React from 'react'
import Welcome_Comp from '../Components/Welcome_Comp'

const Welcome = () => {
    return (
        <Welcome_Comp
            label={'Tailored to Your Research Needs'}
            sub_text_arr={[
                {
                    // bold: true,
                    text: `Yaarnbox offers a variety of flexible subscription plans designed to cater to the research needs of individuals and teams of all sizes. Choose the plan that best suits your budget and research volume:`
                }
            ]}
        />
    )
}

export default Welcome
