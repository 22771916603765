import React, { useEffect, useState } from "react";
import Flex_box from "./flex_box";
import { theme_color } from "./colors";

const Pagination = ({ totalRows, currentPage, pageSize, onPrev, onNext }) => {
  const navigator_link = (type) => {
    if (type == "prev") {
      return currentPage > 1
        ? { cursor: "pointer", opacity: 1 }
        : { opacity: 0.5 };
    } else {
      return currentPage < pagination_limit
        ? { cursor: "pointer", opacity: 1 }
        : { opacity: 0.5 };
    }
  };

  const pagination_limit = totalRows / pageSize;

  return (
    <Flex_box
      style={{
        padding: "10px 0px",
      }}
    >
      <Flex_box
        style={{
          width: "",
        }}
      >
        <b
          onClick={() => {
            if (currentPage > 1) {
              onPrev(currentPage - 1);
            }
          }}
          style={navigator_link("prev")}
        >
          PREV
        </b>
        <Flex_box
          style={{
            margin: "0px 20px",
            gap: 10,
          }}
        >
          <Flex_box
            style={{
              padding: "10px",
              borderRadius: "50%",
              backgroundColor: theme_color,
              width: "20px",
            }}
          >
            <b style={{ color: "#fff" }}>{currentPage}</b>
          </Flex_box>
        </Flex_box>
        <b
          onClick={() => {
            if (currentPage < pagination_limit) {
              onNext(currentPage + 1);
            }
          }}
          style={navigator_link("next")}
        >
          NEXT
        </b>
      </Flex_box>
    </Flex_box>
  );
};

export default Pagination;
