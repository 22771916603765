import React from "react";

const Footer = ({ pageNumber }) => {
  const today = new Date().toISOString().split("T");

  return (
    <>
      <div
        className="pdf_footer"
        style={{
          backgroundColor: "#fff",
          padding: "5px 10px",
          color: "#000",
          position: "fixed",
          bottom: "0",
          width: "97vw",
          fontSize: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid grey",
          borderBottom: "1px solid grey",
        }}
      >
        <span>POWERED BY NextOlive Technologies Pvt. Ltd.</span>
        {/*<span className='page_number'>Page</span>*/}
        <span>
          Printed on:{" "}
          {today[0] +
            " " +
            today[1].split(":")[0] +
            ":" +
            today[1].split(":")[1]}
        </span>
      </div>
      <div style={{ height: "25px", width: "100%" }}></div>
    </>
  );
};

export default Footer;
