import React from "react";
import Flex_box from "../../../utilities/flex_box";
import bg from "../../../assets/Path37581.png";
import Button from "../../../utilities/Button";
import researcher_icon from "../../../assets/Group 48352.svg";
import respondent_icon from "../../../assets/Group 48287.svg";
import { useDispatch } from "react-redux";
import { set_login_type_action } from "../../../redux_prog/actions/auth_action";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const For_Researchers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Flex_box
      style={{
        flexWrap: "wrap",
        position: "relative",
      }}
    >
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="intro_text_cont"
          style={{
            // width: "40%",
            // minWidth: "200px",
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            Benefits for Researchers
          </b>
          <br className="max_100_hidden" />
          <br />
          <b className="intro_sub_text" style={{ fontStyle: "italic" }}>
            Streamline your research process and collect high-quality data from
            a qualified pool of respondents.
          </b>
          <br className="max_100_hidden" />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Improved Data Quality:</b>&nbsp;
            <span>
              Utilize advanced targeting options to ensure you reach the right
              audience for your studies.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Deeper Insights:</b>&nbsp;
            <span>
              Leverage comprehensive data analysis tools to uncover hidden
              patterns and gain actionable insights from your data.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Cost-Effectiveness:</b>&nbsp;
            <span>
              Choose from flexible pricing plans to suit your research budget
              and maximize your return on investment.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Collaboration Tools:</b>&nbsp;
            <span>
              Share your research findings and collaborate seamlessly with
              colleagues within the platform.
            </span>
          </span>
        </div>

        <div
          className="max_100_hidden"
          style={{
            width: "40%",
            minWidth: "200px",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              gap: 20,
            }}
          >
            <img
              src={researcher_icon}
              style={{
                height: "55vh",
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

const For_Respondants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <Flex_box
      style={{
        flexWrap: "wrap",
        position: "relative",
        margin: max_w_1000 ? "20px 0px" : "",
        backgroundImage: `url(${bg})`,
      }}
    >
      <Flex_box
        style={{
          gap: 60,
          flexWrap: "wrap",
          zIndex: 102,
        }}
      >
        <div
          className="max_100_hidden"
          style={{
            width: "40%",
          }}
        >
          <Flex_box
            style={{
              height: "82vh",
              minWidth: "200px",
              gap: 20,
            }}
          >
            <img
              src={respondent_icon}
              style={{
                height: "55vh",
              }}
              className="hover_img"
            />
          </Flex_box>
        </div>
        <div
          className="intro_text_cont"
          style={{
            // width: "40%",
            // minWidth: "200px",
            textWrap: "wrap",
            whiteSpace: "wrap",
            gap: 20,
          }}
        >
          <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
            Benefits for Respondents
          </b>
          <br className="max_100_hidden" />
          <br />
          <b className="intro_sub_text" style={{ fontStyle: "italic" }}>
            Participate in studies from any device, at your own pace, and
            contribute your valuable opinions on the go.
          </b>
          <br className="max_100_hidden" />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Flexible Participation:</b>&nbsp;
            <span>
              Choose from a wide range of research topics that interest you and
              make your voice heard.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Rewarding Experience:</b>&nbsp;
            <span>
              Earn incentives for participating in studies and contribute to
              shaping the future of products and services.
            </span>
          </span>
          <br />
          <br />
          <span style={{ fontSize: "14px" }}>
            <b>Safe and Secure Platform:</b>&nbsp;
            <span>
              Enjoy a secure environment with robust data privacy measures in
              place to protect your information.
            </span>
          </span>
        </div>
      </Flex_box>
    </Flex_box>
  );
};

const Why_SmartQ = () => {
  return (
    <>
      <For_Researchers />
      <For_Respondants />
    </>
  );
};

export default Why_SmartQ;
