import React, { useEffect, useState } from "react";
import Layout from "../../../utilities/Layout";
import Resp_box from "./Resp_box";
import Page_heading from "../../../utilities/Page_heading";
import Flex_box from "../../../utilities/flex_box";
import {
  get_survey_analysis_api,
  get_survey_resp_api,
} from "../../../apis/apis";
import { useLocation } from "react-router-dom";
import { loading_action } from "../../../redux_prog/actions/base_action";
import { useDispatch } from "react-redux";
import { sum_of_array_elem } from "../../../utilities/Add_array_elem";
import Resp_Page from "./Resp_Page";

const Survey_Response = () => {
  const state = useLocation();

  const dispatch = useDispatch();

  const [study_analysis_data, setstudy_analysis_data] = useState({});
  const get_survey_resp = () => {
    dispatch(loading_action(true));
    get_survey_analysis_api(state?.state?.study_id)
      .then((res) => {
        dispatch(loading_action(false));
        const analysis_data = res.data.Res.map((el) => {
          if (el?.question?.questiontype == 4) {
            return {
              questiontitle: el?.question?.questiontitle,
              sub_questions: el?.options?.map((li) => {
                return {
                  questiontitle: li?.subquestion?.questiontitle,
                  total_answers: sum_of_array_elem(li.subansw, "attempt"),
                  graph_data: li?.subansw?.map((elem) => {
                    return {
                      label: elem?.suboption?.optiontitle,
                      y: parseInt(elem?.attempt),
                    };
                  }),
                };
              }),
            };
          } else {
            return {
              questiontitle: el?.question?.questiontitle,
              inp_ans: el?.inp_ans,
              total_answers: sum_of_array_elem(el.options, "attempt"),
              graph_data: el?.options.map((li) => {
                return {
                  label: li?.option?.optiontitle,
                  y: parseInt(li?.attempt),
                };
              }),
            };
          }
        });
        setstudy_analysis_data(analysis_data);
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(get_survey_resp, []);

  return (
    <Layout
      style={{
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <Page_heading label={"Survey Question Response"} />
      <Resp_Page arr={study_analysis_data} />
    </Layout>
  );
};

export default Survey_Response;
