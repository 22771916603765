import React, { useState } from "react";
import { Modal } from "../../../utilities/Modal";
import Pre_login from "../.././Auth_Screens/Pre_login";
import Nav_Comp from "../Nav_Comp";
import Footer from "../Nav_Comp/Footer";
import Welcome_Comp from "../Components/Welcome_Comp";
import Accordion from "../../../utilities/Accordian.js";
import Flex_box from "../../../utilities/flex_box.js";
import Points_Comp from "../Components/Points_Comp.js";
import Knowledge_base from "./Knowledge_base.js";
import { useMediaQuery } from "@mui/material";

const Documentation = () => {
  const [isOpen, setisOpen] = useState(false);
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  const open_modal = () => {
    setisOpen(true);
  };

  const close_modal = () => {
    setisOpen(false);
  };

  const researcher_data = [
    {
      title: "Sign Up and Create Your Profile:",
      content: [
        {
          text: 'Visit our homepage and click the "Sign Up" button.',
        },
        {
          text: "Choose your account type (Researcher) and complete the registration form with your basic information.",
        },
        {
          text: "Verify your email address to activate your account.",
        },
      ],
      open: false,
    },
    {
      title: "Explore the Researcher Dashboard:",
      content: [
        {
          text: "Upon logging in, you'll be greeted by your personalized researcher dashboard.",
        },
        {
          text: "This central hub provides an overview of your research projects, survey creation tools, data analysis features, and communication options.",
        },
      ],
      open: false,
    },
    {
      title: "Build Your First Survey:",
      content: [
        {
          text: 'Click on the "Create Survey" button to access our intuitive survey builder.',
        },
        {
          text: "Choose from a variety of question formats, including multiple-choice, open-ended, ranking scales, and logic branching.",
        },
        {
          text: "Craft engaging questions, utilize multimedia elements (images, videos), and personalize your survey with your branding.",
        },
        {
          text: "Set target demographics and quota management options to ensure you reach the right audience.",
        },
      ],
      open: false,
    },
    {
      title: "Launch Your Survey and Collect Data:",
      content: [
        {
          text: "Once satisfied with your survey design, preview it and test it before going live.",
        },
        {
          text: "Choose your preferred launch method: share the survey link directly, embed it on your website, or utilize our participant recruitment tools.",
        },
        {
          text: "Track survey completion rates and monitor real-time data collection progress within your dashboard.",
        },
      ],
      open: false,
    },
    {
      title: "Analyze Your Data and Gain Insights:",
      content: [
        {
          text: "Access powerful data visualization tools to transform raw data into clear and actionable insights.",
        },
        {
          text: "Utilize interactive dashboards to explore key metrics and trends.",
        },
        {
          text: "Generate comprehensive reports with customizable charts and graphs for effective communication.",
        },
      ],
      open: false,
    },
  ];

  const researcher_acc_arr = researcher_data?.map((el, index) => {
    return {
      ...el,
      content: (
        <Points_Comp
          no_point_gap={true}
          points_icon={true}
          comp_style={{
            padding: "",
            justifyContent: "start",
          }}
          points_arr={el?.content}
        />
      ),
    };
  });

  const respondant_data = [
    {
      title: "Create Your Free Account:",
      content: [
        {
          text: 'Visit our homepage and click the "Sign Up" button.',
        },
        {
          text: "Choose your account type (Respondent) and complete the registration form with your basic information.",
        },
        {
          text: "Verify your email address to activate your account.",
        },
      ],
      open: false,
    },
    {
      title: "Complete Your Profile (Optional):",
      content: [
        {
          text: "While not mandatory, providing some demographic information helps us match you with relevant research studies. The more information you share, the more opportunities you'll receive to participate in exciting research!",
        },
      ],
      open: false,
    },
    {
      title: "Explore Available Studies:",
      content: [
        {
          text: 'Navigate to the "Studies" tab on your dashboard to discover a variety of research opportunities based on your interests and demographics.',
        },
        {
          text: "Each study description will clearly outline the topic, incentive rewards, and estimated time commitment.",
        },
      ],
      open: false,
    },
    {
      title: "Participate in Surveys and Discussions:",
      content: [
        {
          text: "Click on a study that interests you and follow the easy on-screen instructions to complete the survey or join a group discussion.",
        },
        {
          text: "Be honest and thoughtful with your responses to contribute valuable insights to market research.",
        },
      ],
      open: false,
    },
    {
      title: "Earn Smart-Coins and Redeem Rewards:",
      content: [
        {
          text: "Upon completing surveys or participating in discussions, you'll earn Smart-Coins, our virtual currency.",
        },
        {
          text: "Accumulate Smart-Coins and redeem them as shopping vouchers from our respective partner outlets.",
        },
      ],
      open: false,
    },
  ];

  const respondant_acc_arr = respondant_data?.map((el, index) => {
    return {
      ...el,
      content: (
        <Points_Comp
          no_point_gap={true}
          points_icon={true}
          comp_style={{
            padding: "",
            justifyContent: "start",
          }}
          points_arr={el?.content}
        />
      ),
    };
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 500,
        }}
      >
        <Nav_Comp open_modal={open_modal} />
      </div>
      <div
        style={{
          width: "100%",
          height: "90px",
        }}
      ></div>
      <div>
        <Welcome_Comp
          label={
            <>
              <b style={{ fontSize: max_w_1000?"30px":"40px" }}>Documentation</b>
              <br />
              <b>Getting Started Guide</b>
            </>
          }
          sub_text_arr={[
            {
              text: "This one-page guide will equip you with the essential steps to set up your account and navigate the platform as a researcher or respondent.",
            },
          ]}
        />
      </div>
      {/* --------------Reasercher----------------- */}
      <Flex_box
        style={{
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
          Are you a Researcher?
        </b>
        <div
          style={{
            width: "70%",
            marginTop: "30px",
          }}
        >
          <Accordion items={researcher_acc_arr} />
        </div>
      </Flex_box>

      {/* --------------Respondant----------------- */}
      <Flex_box
        style={{
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
          Are you a Respondent?
        </b>
        <div
          style={{
            width: "70%",
            marginTop: "30px",
          }}
        >
          <Accordion items={respondant_acc_arr} />
        </div>
      </Flex_box>

      <Knowledge_base />
      <Footer />
      <Modal isOpen={isOpen} onClose={close_modal}>
        <div
          style={{
            position: "relative",
          }}
        >
          <span
            onClick={close_modal}
            style={{
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
          <Pre_login />
        </div>
      </Modal>
    </div>
  );
};

export default Documentation;
