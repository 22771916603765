import React, { useEffect, useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import File_input from "../../../utilities/File_input";
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from "../../../utilities/Text_Input";
import { useLocation, useNavigate } from "react-router-dom";
import Radio_Box from "../../../utilities/Radio_Box";
import Auth_header from "../Auth_header";
import { useDispatch, useSelector } from "react-redux";
import {
  login_action,
  set_login_type_action,
  set_otp_action,
  set_user_action,
} from "../../../redux_prog/actions/auth_action";
import {
  create_temp_user_api,
  get_eligibility_questions_api,
  signup_api,
} from "../../../apis/apis";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import { convert_into_base64 } from "../../../utilities/Image_conv";
import Button from "../../../utilities/Button";
import { generate_otp, read_stream } from "../../../utilities/Generate_OTP";
import Select_box from "../../../utilities/Select_box";
import { state_arr } from "../../../utilities/select_arr";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";

const Add_Temp_User = () => {
  const path_state = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const study_id = path_state?.search?.split("?")[1];

  const [user_data, setuser_data] = useState({});
  const [loading, setloading] = useState(false);
  const [gender_options, setgender_options] = useState([]);
  const [age_options, setage_options] = useState([]);

  const stateList = state_arr?.map((el) => {
    return {
      label: el.state,
      value: el.state,
    };
  });

  const get_eligibility_questions = () => {
    dispatch(loading_action(true));
    get_eligibility_questions_api()
      .then((res) => {
        // setEligibilityQuestions(res.data.Res || []);
        setgender_options(
          res.data.Res[0].options?.map((el) => {
            return {
              label: el,
              value: el,
            };
          })
        );
        setage_options(res.data.Res[1].options);
        dispatch(loading_action(false));
      })
      .catch((err) => {
        dispatch(loading_action(false));
        console.log(err);
      });
  };

  useEffect(get_eligibility_questions, []);

  const upd_user_data = (key, val) => {
    setuser_data({
      ...user_data,
      [key]: val,
    });
  };

  const { email, first_name, last_name, gender, age, state } = user_data;

  const add_user = () => {
    if (email && first_name && last_name && gender && age && state) {
      dispatch(loading_action(true))
      create_temp_user_api({
        temp_user_data: {
          email,
          first_name,
          last_name,
          gender,
          age,
          state,
          temp_user:true
        },
      })
        .then((res) => {
        //   console.warn(res);
        if(res.data.status==1){
            dispatch(
              set_temp_user_action(res.data.Res)
            );
          navigate("/take-survey?"+study_id);
        }
        else{
            if(res.data.error.keyPattern.email){
                dispatch(
                  alert_action({
                    title: `Duplicate Email`,
                    type: "error",
                    msg: "Email already exists!",
                  })
                );
            }
        }
      dispatch(loading_action(false))
    })
        .catch((err) => {
      dispatch(loading_action(false))
      console.error(err);
        });
    } else {
      dispatch(loading_action(false))
      dispatch(
        alert_action({
          title: `Invalid Credentials`,
          type: "error",
          msg: "Fill all the fields!",
        })
      );
    }
  };

  return (
    <div
      className="auth_box_cont"
      style={{
        overflowY: "auto",
        padding: "150px 0px",
        alignItems: "start",
        maxHeight: "60vh",
      }}
    >
      <div
        className="auth_box"
        style={{
          padding: "15px",
          // backgroundColor:"red"
        }}
      >
        <Auth_header no_back_icon={true} label={"Registration Yourself"} />

        <span
          className="responsive_width"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
            padding: "25px 0px",
          }}
        >
          We'll keep this private, like everything you share with us.
        </span>

        <Text_Input
          label={"First Name"}
          cont_style={{
            // marginTop: "30px",
            width: "90%",
          }}
          value={first_name}
          onChange={(e) => {
            upd_user_data("first_name", e.target.value);
          }}
        />
        <Text_Input
          label={"Last Name"}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          value={last_name}
          onChange={(e) => {
            upd_user_data("last_name", e.target.value);
          }}
        />
        <Text_Input
          label={"Email"}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          value={email}
          onChange={(e) => {
            upd_user_data("email", e.target.value);
          }}
        />

        <Select_box
          placeholder={"Gender"}
          label={"Gender"}
          options={gender_options}
          cont_style={{
            marginTop: "20px",
            width:"90%"
          }}
          value={gender}
          onChange={(e) => {
            upd_user_data("gender", e.target.value);
          }}
        />

        <Select_box
          label={"Age"}
          placeholder={"Age"}
          options={age_options}
          cont_style={{
            marginTop: "20px",
            width:"90%"
          }}
          value={age}
          onChange={(e) => {
            upd_user_data("age", e.target.value);
          }}
        />

        <Select_box
          label={"State"}
          placeholder={"State"}
          options={stateList}
          cont_style={{
            marginTop: "20px",
            width:"90%"
          }}
          value={state}
          onChange={(e) => {
            upd_user_data("state", e.target.value);
          }}
        />

        <Button
          id="btn_comp"
          style={{
            marginTop: "40px",
          }}
          onClick={add_user}
          label={"Submit"}
          loading={loading}
        ></Button>
      </div>
    </div>
  );
};

export default Add_Temp_User;
