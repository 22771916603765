import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import Flex_box from "../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../utilities/colors";
import share_arrow from "../../../assets/Group 68236.svg";
import Radio_Box from "../../../utilities/Radio_Box";
import Goto_Modal_Comp from "./Goto_Modal_Comp";
import { Checkbox, Radio } from "@mui/material";
import Text_Input from "../../../utilities/Text_Input";
import { useDispatch, useSelector } from "react-redux";
import {
  give_survey_response_api,
  set_survey_touched_api,
  submit_ans_api,
} from "../../../apis/apis";
import {
  alert_action,
  loading_action,
} from "../../../redux_prog/actions/base_action";
import loading_icon from "../../../assets/loading.gif";
import { set_temp_user_action } from "../../../redux_prog/actions/app_action";

const Question_Box = ({
  setintro_completed,
  setsurvey_completed,
  study_id,
  survey_details,
  last_question
}) => {
  const redux_user_data = useSelector((state) => state?.authReducer) || {};
  const { temp_user } = useSelector((state) => state?.appReducer) || {};
  const user_data = temp_user?._id? temp_user : redux_user_data?.user_data;

  const navigate = useNavigate();
  const path_state = useLocation();
  const state = path_state?.state || survey_details;

  const [isOpen, setisOpen] = useState(false);
  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();

  const { surveydata, questionsdata } = state;

  console.log("questions", state);
  const [questionList, setQuestionList] = useState();
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [questionNo, setQuestionNo] = useState(1);

  useEffect(() => {
    var qstnList = [];
    // debugger;
    console.log("not dependent question", questionsdata);
    for (let i = 0; i < questionsdata?.length; i++) {
      var question = questionsdata?.find((x) =>
        x?.Options.find(
          (y) => y.dependent_question == questionsdata[i].QuestionData.serial_no
        )
      );
      if (!question) {
        qstnList.push(questionsdata[i]);
        questionsdata[i]["depend_question"] = false;
      } else {
        questionsdata[i]["depend_question"] = true;
        qstnList.push(questionsdata[i]);
      }
    }
    console.log("dependent questions", qstnList);

    var noOfNotDependentQuestion = qstnList.filter(
      (x) => !x.depend_question
    )?.length;
    var noOfDependentQuestions = qstnList.filter((x) =>
      x?.Options.some((y) => y?.dependent_question)
    )?.length;
    console.log("no of not dependent question", noOfNotDependentQuestion);
    console.log("no of dependent question", noOfDependentQuestions);
    setNumberOfQuestion(noOfNotDependentQuestion + noOfDependentQuestions);
    // debugger;
    var firstIndexQuestion = qstnList.findIndex((x) => !x.depend_question);
    setcurrent_ques(last_question || firstIndexQuestion);
    // debugger;
    setQuestionList(qstnList);
  }, []);

  const [lengthOfAttemptQuestion, setLengthOfAttemptQuestion] = useState(0);

  const handelNextQuestion = () => {
    var qstnList = questionList;
    qstnList[current_ques]["question_attempt"] = true;
    var atmptQuestion = qstnList.filter((x) => x.question_attempt)?.length;
    // debugger;

    console.log({ atmptQuestion, numberOfQuestion });
    if (atmptQuestion != numberOfQuestion) {
      setLengthOfAttemptQuestion(atmptQuestion);
      setQuestionNo((prevQuestionNo) => prevQuestionNo + 1);
      if (qstnList[current_ques].QuestionData.questiontype == 3) {
        console.log("3rd Question type");
        var dependetn_Q_serial_no = qstnList[current_ques].Options.find(
          (x) => x.answer
        ).dependent_question;
        console.warn({ dependetn_Q_serial_no });
        if (dependetn_Q_serial_no) {
          var questionIndex = qstnList.findIndex(
            (x) => x.QuestionData.serial_no == dependetn_Q_serial_no
          );
          setcurrent_ques(questionIndex);
        } else {
          setcurrent_ques(current_ques + 1);
        }
      } else {
        console.log("Not 3rd Question type");
        setcurrent_ques(current_ques + 1);
      }
      setQuestionList([...qstnList]);
      console.log("question attempt", questionList);
    }
  };

  const [questions, setquestions] = useState(questionsdata);
  const [current_ques, setcurrent_ques] = useState(last_question || 0);

  

  const cur_question = questions?.filter((el, index) => {
    return index == current_ques;
  })[0];

  const set_touched = () => {
    // console.log(surveydata);lengthOfAttemptQuestion
    // if (current_ques == 0) {
    if (lengthOfAttemptQuestion == 0) {
     return set_survey_touched_api({
        UserId: user_data?._id,
        SurveyId: surveydata?._id,
      })
        // .then((res) => {})
        // .catch((err) => {
        //   console.log(err);
        // });
    }
    return ;
  };

  const submit_ans = async (end) => {
    var q_data = {};
    if (cur_question?.QuestionData?.questiontype == 1) {
      q_data = {
        questionid: cur_question?.QuestionData?._id,
        questiontype: 1,
        inputanswer: cur_question?.inputanswer,
        inputoptionid: cur_question?.Options[0]?._id,
      };
    } else if (cur_question?.QuestionData?.questiontype == 3) {
      q_data = {
        questionid: cur_question?.QuestionData?._id,
        questiontype: 3,
        Option: [
          {
            inputanswer: "true",
            inputoptionid: cur_question?.Options?.filter((el) => {
              return el?.answer == true;
            })[0]?._id,
          },
        ],
      };
    } else if (cur_question?.QuestionData?.questiontype == 2) {
      q_data = {
        questionid: cur_question?.QuestionData?._id,
        questiontype: 2,
        Option: cur_question?.Options?.filter((elem) => {
          return elem?.answer == true;
        })?.map((li) => {
          return {
            inputanswer: "true",
            inputoptionid: li?._id,
          };
        }),
      };
    } else {
      q_data = {
        questionid: cur_question?.QuestionData?._id,
        questiontype: 4,
        Option: cur_question?.Options?.map((li) => {
          return {
            ...li,
            SubOptions: li?.SubOptions?.filter((elem) => {
              return elem?.answer == true;
            }).map((element) => {
              return {
                inputanswer: "true",
                suboptionid: element?._id,
              };
            }),
          };
        }),
      };
    }
                    setloading(true);
    await set_touched();

    submit_ans_api({
      SurveyData: {
        userid: user_data?._id,
        studyid: surveydata?.studyid,
      },
      Questions: [q_data],
      end,
    })
      .then((res) => {
        if (res.data.status == 1) {
          if (end) {
            setloading(false);
            dispatch(
              alert_action({
                title: `Success`,
                type: "success",
                msg: "Response sent successfully!",
              })
            );
            if (study_id) {
              dispatch(set_temp_user_action(""));
              navigate("/");
            } else {
              navigate("/dashboard");
            }
          } else {
            handelNextQuestion();
          }
        }
                    setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Flex_box
        className="responsive_width_35"
        style={{
          flexDirection: "column",
          padding: "20px",
          width: "",
          borderRadius: "10px",
          boxShadow: "0px 5px 10px #0000000D",
          gap: 10,
          textAlign: "center",
          position: "relative",
          // backgroundColor:"red"
        }}
      >
        {/* <img
          onClick={() => {
            setisOpen(true);
            // alert('vhvhjv')
          }}
          src={share_arrow}
          style={{
            cursor: "pointer",
            zIndex: "100",
            height: "40px",
            position: "absolute",
            top: "15px",
            right: "20px",
          }}
        /> */}
        <b style={{ fontSize: "22px" }}>{surveydata?.surveytitle}</b>
        <div style={{ width: "100%", textAlign: "start" }}>
          <b style={{ fontSize: "14px" }}>
            {/* Q{current_ques + 1} {cur_question?.QuestionData?.questiontitle} */}
            Q{current_ques + 1} {cur_question?.QuestionData?.questiontitle}
          </b>
          <div
            style={{
              width: "100%",
              marginTop: "15px",
            }}
          >
            {cur_question?.QuestionData?.questiontype == 1 && (
              <Text_Input
                value={cur_question?.inputanswer || " "}
                textArea={true}
                onChange={(e) => {
                  setquestions(
                    questions?.map((el, index) => {
                      if (index == current_ques) {
                        return {
                          ...el,
                          inputanswer: e.target.value,
                        };
                      } else {
                        return el;
                      }
                    })
                  );
                }}
              />
            )}
            {cur_question?.QuestionData?.questiontype != 1 &&
              cur_question?.Options?.length > 0 &&
              cur_question?.Options?.map((el, index) => {
                return (
                  <>
                    <Flex_box
                      style={{
                        justifyContent: "start",
                        // gap: 5,
                        marginTop: "5px",
                        width: "100%",
                      }}
                    >
                      {cur_question?.QuestionData?.questiontype == 2 && (
                        <Checkbox
                          checked={el?.answer == true}
                          onChange={(e) => {
                            setquestions(
                              questions.map((li, ind) => {
                                if (ind == current_ques) {
                                  const option_arr = li?.Options;
                                  return {
                                    ...li,
                                    Options: option_arr?.map((elem, inde) => {
                                      if (inde == index) {
                                        return {
                                          ...elem,
                                          answer: el?.answer ? false : true,
                                        };
                                      } else {
                                        return {
                                          ...elem,
                                          // answer:
                                          //   elem?.answer == "true"
                                          //     ? false
                                          //     : elem?.answer,
                                        };
                                      }
                                    }),
                                  };
                                } else {
                                  return li;
                                }
                              })
                            );
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                      {cur_question?.QuestionData?.questiontype == 3 && (
                        <Radio
                          checked={el?.answer == true}
                          onChange={(e) => {
                            setquestions(
                              questions.map((li, ind) => {
                                if (ind == current_ques) {
                                  const option_arr = li?.Options;
                                  return {
                                    ...li,
                                    Options: option_arr?.map((elem, inde) => {
                                      if (inde == index) {
                                        return {
                                          ...elem,
                                          answer: true,
                                        };
                                      } else {
                                        return {
                                          ...elem,
                                          answer: false,
                                        };
                                      }
                                    }),
                                  };
                                } else {
                                  return li;
                                }
                              })
                            );
                          }}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      )}
                      {cur_question?.QuestionData?.questiontype != 4 && (
                        <span
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          {el?.optiontitle}
                        </span>
                      )}
                      {cur_question?.QuestionData?.questiontype == 4 && (
                        <span
                          style={{
                            fontSize: "11px",
                            fontWeight: "600",
                            marginLeft: "12px",
                          }}
                        >
                          {el?.Options?.questiontitle}
                        </span>
                      )}
                    </Flex_box>

                    {cur_question?.QuestionData?.questiontype == 4 &&
                      el?.SubOptions?.length > 0 &&
                      el?.SubOptions?.map((elem, elem_ind) => {
                        return (
                          <Flex_box
                            style={{
                              justifyContent: "start",
                              // gap: 5,
                              marginTop: "5px",
                              width: "100%",
                            }}
                          >
                            <Checkbox
                              checked={elem?.answer == true}
                              onChange={(e) => {
                                setquestions(
                                  questions.map((li, ind) => {
                                    if (ind == current_ques) {
                                      const option_arr = li?.Options;
                                      return {
                                        ...li,
                                        Options: option_arr?.map(
                                          (elemen, inde) => {
                                            if (inde == index) {
                                              return {
                                                ...elemen,
                                                SubOptions:
                                                  elemen?.SubOptions?.map(
                                                    (element, element_ind) => {
                                                      if (
                                                        element_ind == elem_ind
                                                      ) {
                                                        return {
                                                          ...element,
                                                          answer:
                                                            elem?.answer == true
                                                              ? false
                                                              : true,
                                                        };
                                                      } else {
                                                        return element;
                                                      }
                                                    }
                                                  ),
                                              };
                                            } else {
                                              return {
                                                ...elemen,
                                              };
                                            }
                                          }
                                        ),
                                      };
                                    } else {
                                      return li;
                                    }
                                  })
                                );
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              {elem?.optiontitle}
                            </span>
                          </Flex_box>
                        );
                      })}
                  </>
                );
              })}
          </div>
          <Flex_box
            style={{
              gap: 25,
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {/* <button
              id="btn_comp"
              style={{ fontSize: "16px", fontWeight: "500" }}
              onClick={() => {
                if (current_ques > 0) {
                  setcurrent_ques(current_ques - 1);
                }
              }}
            >
              Previous
            </button> */}
            <button
              id="btn_comp"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                backgroundColor: "#FFCC99",
                color: "#000",
                opacity: loading ? 0.6 : 1,
              }}
              disabled={loading}
              onClick={() => {
                const type_1_answer =
                  cur_question?.QuestionData?.questiontype == 2 ||
                  cur_question?.QuestionData?.questiontype == 3
                    ? cur_question?.Options?.filter((el) => {
                        return el?.answer == true;
                      })?.length > 0
                    : true;

                const type_4_answer =
                  cur_question?.QuestionData?.questiontype == 4
                    ? cur_question?.Options?.filter((el) => {
                        return (
                          el?.SubOptions.filter((li) => {
                            return li?.answer == true;
                          })?.length > 0
                        );
                      })?.length > 0
                    : true;

                const type_2_answer =
                  cur_question?.QuestionData?.questiontype == 1
                    ? cur_question?.inputanswer
                    : true;

                if (type_1_answer && type_2_answer && type_4_answer) {
                  if (current_ques + 1 < numberOfQuestion) {
                    submit_ans();
                  } else {
                    submit_ans("end");

                    // const q_data = questions.map((el) => {
                    //   if (el?.QuestionData?.questiontype == 1) {
                    //     return {
                    //       questionid: el?.QuestionData?._id,
                    //       questiontype: 1,
                    //       inputanswer: el?.inputanswer,
                    //       inputoptionid: el?.Options[0]?._id,
                    //     };
                    //   } else if (el?.QuestionData?.questiontype == 3) {
                    //     return {
                    //       questionid: el?.QuestionData?._id,
                    //       questiontype: 3,
                    //       Option: [
                    //         {
                    //           inputanswer: "true",
                    //           inputoptionid: el?.Options?.filter((el) => {
                    //             return el?.answer == true;
                    //           })[0]?._id,
                    //         },
                    //       ],
                    //     };
                    //   } else if (el?.QuestionData?.questiontype == 2) {
                    //     return {
                    //       questionid: el?.QuestionData?._id,
                    //       questiontype: 2,
                    //       Option: el?.Options?.filter((elem) => {
                    //         return elem?.answer == true;
                    //       })?.map((li) => {
                    //         return {
                    //           inputanswer: "true",
                    //           inputoptionid: li?._id,
                    //         };
                    //       }),
                    //     };
                    //   } else {
                    //     return {
                    //       questionid: el?.QuestionData?._id,
                    //       questiontype: 4,
                    //       Option: el?.Options?.map((li) => {
                    //         return {
                    //           ...li,
                    //           SubOptions: li?.SubOptions?.filter((elem) => {
                    //             return elem?.answer == true;
                    //           }).map((element) => {
                    //             return {
                    //               inputanswer: "true",
                    //               suboptionid: element?._id,
                    //             };
                    //           }),
                    //         };
                    //       }),
                    //     };
                    //   }
                    // });
                    // setloading(true);
                    // give_survey_response_api({
                    //   SurveyData: {
                    //     userid: user_data?._id,
                    //     studyid: surveydata?.studyid,
                    //   },
                    //   Questions: q_data,
                    // })
                    //   .then((res) => {
                    //     // debugger;
                    //     if (res.data.status == 1) {
                    //       setloading(false);
                    //       dispatch(
                    //         alert_action({
                    //           title: `Success`,
                    //           type: "success",
                    //           msg: "Response sent successfully!",
                    //         })
                    //       );
                    //       if (study_id) {
                    //         dispatch(set_temp_user_action(""));
                    //         navigate("/");
                    //       } else {
                    //         navigate("/dashboard");
                    //       }
                    //     }
                    //   })
                    //   .catch((err) => {
                    //     setloading(false);
                    //     console.log(err);
                    //   });
                  }
                } else {
                  alert("Please first answer the question!");
                }
              }}
            >
              <Flex_box
                style={{
                  gap: 10,
                }}
              >
                {loading && (
                  <img
                    src={loading_icon}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                )}
                {!loading && (
                  <b>
                    {current_ques + 1 == numberOfQuestion ? "Submit" : "Next"}
                  </b>
                )}
              </Flex_box>
            </button>
          </Flex_box>
        </div>
      </Flex_box>
      <Goto_Modal_Comp
        isOpen={isOpen}
        onClose={() => {
          setisOpen(false);
        }}
        setcurrent_ques={setcurrent_ques}
      />
    </>
  );
};

export default Question_Box;
