import React from "react";
import Flex_box from "./flex_box";

const Radio_Box = ({
  label,
  value,
  onChange,
  onClick,
  style,
  radio_style,
  checked,
  isMultipleSelection,
}) => {
  const style_obj = style || {};
  const radio_style_obj = radio_style || {};
  return (
    <Flex_box style={{ gap: 3, width: "", flexWrap: "wrap", ...style_obj }}>
      <label class="blue-radio">
        <input
          type="radio"
          style={radio_style_obj}
          checked={checked}
          value={value}
          onChange={(e) => {
            console.log("changed");
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        />
      </label>
      <span style={{ fontSize: "12px", fontWeight: 600 }}>{label}</span>
    </Flex_box>
  );
};

export default Radio_Box;
