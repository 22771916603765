import React from "react";
import Create_study_comp from "./Create_study_comp";
import Flex_box from "../../../../utilities/flex_box";
import Sidebar from "../../../Nav_Comp/Sidebar";
import Navbar from "../../../Nav_Comp/Navbar";
import Footer from "../../../Nav_Comp/Footer";

const Create_study = () => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        // paddingBottom: "70px",
      }}
    >
      <Sidebar />
      <div
        className="comp_container"
        style={{
          position: "relative",
        }}
      >
        <Navbar />
        <Create_study_comp />
        <Footer />
      </div>
    </Flex_box>
  );
};

export default Create_study;
