import React, { useEffect, useState } from "react";
import Flex_box from "../../../../../utilities/flex_box";
import blue_circle from "../../../../../assets/Group 38514.svg";
import green_check from "../../../../../assets/check-circle-solid.svg";
import Text_Input from "../../../../../utilities/Text_Input";
import { theme_bg, theme_color } from "../../../../../utilities/colors";
import { Checkbox, Radio } from "@mui/material";
import Select_box from "../../../../../utilities/Select_box";

// const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

const Option_Box = ({ item, type }) => {
  return (
    <Flex_box
      style={{
        justifyContent: "start",
        gap: 15,
        marginTop: "15px",
        width: "100%",
      }}
    >
      {type == 2 && (
        <Checkbox
          // checked={checked}
          // onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      )}
      {type == 3 && (
        <Radio
          // checked={true}
          // onChange={handleChange}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
      )}
      <div
        id="text_input"
        className="removescrollbar"
        style={{
          textWrap: "wrap",
          whiteSpace: "wrap",
          overflowY: "auto",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "87%",
        //   marginLeft: type ? 0 : "85px",
        }}
      >
        <p>{item?.optiontitle}</p>
      </div>
    </Flex_box>
  );
};

const Sub_Option_MCQ_Box = ({
  item,
  index,
  allQuestions,
  setquestion_arr,
  handelDependentQuestion,
  selectedQuestionIndex,
  setselectedQuestionIndex,
}) => {
  // console.log("question list", question_data);

  const [questionList, setQuestionList] = useState([
    { label: "Remove", value: "" },
  ]);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const handelChedkedBox = (e, serial_no, index) => {
    var questions = [];
    console.log("qstn lst", allQuestions);
    for (let i = 0; i < allQuestions.length; i++) {
      const existing_qs = [].concat(
        ...allQuestions?.map((el) => {
          return el?.Option?.map((li) => {
            return parseInt(li.dependent_question);
          });
        })
      );

      console.log(existing_qs, allQuestions[i].serial_no);

      if (
        allQuestions[i].serial_no != serial_no &&
        !existing_qs?.includes(allQuestions[i].serial_no)
      ) {
        questions.push({
          label: allQuestions[i].questiontitle,
          value: allQuestions[i].serial_no,
        });
      }
      //}
    }
    setQuestionList(questions);

    if (selectedQuestionIndex != index) {
      // setSelectedQuestionIndex(index);
      setselectedQuestionIndex(index);
    } else {
      setselectedQuestionIndex(null);
    }
  };

  const handelSelectedQuestion = (ind, val, serial_no) => {
    console.log({ allQuestions, id: item.serial_no, ind, val });

    setquestion_arr(
      allQuestions.map((el) => {
        if (el.serial_no == item?.serial_no) {
          return {
            ...el,
            Option: el.Option?.map((li, index) => {
              if (index == ind) {
                return {
                  ...li,
                  switch_to: val,
                };
              } else {
                return {
                  ...li,
                };
              }
            }),
          };
        } else {
          return el;
        }
      })
    );
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Flex_box
          style={{
            marginTop: index == 0 ? "20px" : "30px",
            width: "98%",
            gap: 10,
          }}
        >
          <Text_Input
            label={"Question " + (index + 1)}
            cont_style={{
              width: "98%",
            }}
            value={item?.questiontitle}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </Flex_box>
      </div>
      {item?.Option?.length > 0 && (
        <>
          <span
            style={{
              fontSize: "10px",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            Select Correct Answer Below
          </span>

          {item?.Option?.map((li, ind) => {
            return (
              <>
                <div
                  className="dropdown_wrap"
                  style={{
                    display: "block",
                  }}
                >
                  <Option_Box item={li} key={ind} />
                  {li.sub_options?.length > 0 &&
                    li?.sub_options?.map((elem, ind_el) => {
                      return <Option_Box item={elem} type={2} key={ind_el} />;
                    })}
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default Sub_Option_MCQ_Box;
