export const Remove_obj_keys = (obj, keys) => {
  const newObj = { ...obj }; // Create a shallow copy of the original object

  keys.forEach((key) => {
    delete newObj[key]; // Delete each specified key from the copied object
  });

  return newObj; // Return the modified object
};

export const add_obj_values = (obj) => {
  const values = Object.values(obj);

  // Converting values to numbers and summing them up
  const sum = values.reduce(
    (acc, currentValue) => acc + parseInt(currentValue),
    0
  );
  return sum;
};


export function convertToArrayOfObjects(data) {
  for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (typeof value === 'object' && !Array.isArray(value)) {
              data[key] = Object.entries(value).map(([subKey, subValue]) => ({ [key]: subKey, count: subValue }));
          }
      }
  }
  return data;
}

export function convertToObjectOfArrays(data) {
  for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object') {
              const obj = {};
              value.forEach(item => {
                  const subKey = item[key];
                  const count = item.count;
                  obj[subKey] = count;
              });
              data[key] = obj;
          }
      }
  }
  return data;
}