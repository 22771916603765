import React from "react";

const Content = (props) => {
  const { orientation } = props;
  const value = orientation || "portrait";

  return (
    <div style={{ width: "100%" }}>
      <style type="text/css" media="print">
        {` @page { size: ${value}; } `}
      </style>
      {props.children}
    </div>
  );
};

export default Content;
