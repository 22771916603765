import React, { useState } from "react";
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from "../../../utilities/colors";
import Flex_box from "../../../utilities/flex_box";
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from "../../../utilities/Text_Input";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Radio_Box from "../../../utilities/Radio_Box";
import Auth_header from "../Auth_header";
import { useDispatch, useSelector } from "react-redux";
import {
  login_action,
  set_login_type_action,
  set_user_action,
} from "../../../redux_prog/actions/auth_action";
import {
  login_api,
  reset_password_api,
  send_otp_api,
} from "../../../apis/apis";
import { alert_action } from "../../../redux_prog/actions/base_action";
import Button from "../../../utilities/Button";
import axios from "axios";

import check_icon from "../../../assets/check.png";
import accepted_icon from "../../../assets/accept.png";
import { generate_otp } from "../../../utilities/Generate_OTP";

const Forgot_Pass_Comp = () => {
  const navigate = useNavigate();
  const login_type = useSelector((state) => state?.authReducer?.login_type);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const [user_data, setuser_data] = useState({
    otp: generate_otp(4),
  });

  const upd_user_data = (key, val) => {
    setuser_data({
      ...user_data,
      [key]: val,
    });
  };

  const {
    email,
    password,
    c_password,
    inp_otp,
    otp,
    email_verified,
    otp_verified,
    id,
  } = user_data;

  const send_otp = () => {
    send_otp_api({
      email,
      otp,
    })
      .then((res) => {
        console.log(res.data);
        // upd_user_data("email_verified", true);
        setuser_data({
          ...user_data,
          email_verified: true,
          id: res.data.Res.UserId,
          // id
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reset_password = () => {
    if (c_password == password && password) {
      reset_password_api({ NewPassword: password, Id: id })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        alert_action({
          title: `Password Mismatch`,
          type: "error",
          msg: "Please enter a valid passwords!",
        })
      );
    }
  };

  return (
    <div className="auth_box_cont">
      <div
        className="auth_box"
        style={{
          padding: "15px",
          paddingBottom: "30px",
        }}
      >
        <Auth_header label={"Reset Password"} />

        <Text_Input
          label={"Email"}
          value={email}
          cont_style={{
            width: "90%",
            marginTop: "15px",
          }}
          onChange={(e) => {
            upd_user_data("email", e.target.value);
          }}
          suffix_img={email_verified ? accepted_icon : check_icon}
          extra_func={() => {
            send_otp();
            // upd_user_data("email_verified", true);
          }}
        />
        <Text_Input
          label={"OTP"}
          value={inp_otp}
          cont_style={{
            marginTop: "18px",
            width: "90%",
          }}
          onChange={(e) => {
            upd_user_data("inp_otp", e.target.value);
          }}
          suffix_img={otp_verified ? accepted_icon : check_icon}
          disabled={!email_verified}
          extra_func={() => {
            if (email_verified) {
              if (otp == inp_otp) {
                upd_user_data("otp_verified", true);
              } else {
                dispatch(
                  alert_action({
                    title: `Invalid OTP`,
                    type: "error",
                    msg: "Please enter a valid OTP!",
                  })
                );
              }
            }
          }}
        />
        <div
          style={{
            width: "90%",
            borderTop: "1px dashed #c9c9c9",
            marginTop: "20px",
          }}
        >
          <Text_Input
            label={"Password"}
            cont_style={{
              marginTop: "18px",
              width: "100%",
            }}
            type={"Password"}
            value={password}
            disabled={!(otp_verified && email_verified)}
            onChange={(e) => {
              upd_user_data("password", e.target.value);
            }}
          />
          <Text_Input
            label={"Confirm Password"}
            cont_style={{
              marginTop: "18px",
              width: "100%",
            }}
            type={"Password"}
            disabled={!(otp_verified && email_verified)}
            value={c_password}
            onChange={(e) => {
              upd_user_data("c_password", e.target.value);
            }}
          />
        </div>

        <Button
          id="btn_comp"
          style={{
            marginTop: "40px",
          }}
          onClick={reset_password}
          label={"Reset Password"}
          loading={loading}
          disabled={!(otp_verified && email_verified)}
        />
      </div>
    </div>
  );
};

export default Forgot_Pass_Comp;
