import React from 'react'
import Points_Comp from '../Components/Points_Comp';
import researcher_icon from '../../../assets/Group 48352.svg';

const Powerful_research = () => {
    return (
        <Points_Comp
            label={'Powerful Research Tools for Researchers:'}
            sub_text_arr={[
                { text: 'Create engaging surveys with various question types, including multiple-choice, open-ended, ranking scales, and logic branching for a dynamic survey experience.', bold: true }
            ]}
            points_arr={[
                {
                    heading: 'Multimedia Integration',
                    text: 'Enhance your surveys with images, videos, and audio to capture respondent attention and improve data quality.'
                },
                {
                    heading: 'Advanced Targeting',
                    text: 'Reach the right audience for your studies by utilizing demographic filters, location targeting, and other criteria to ensure you gather data from qualified respondents.'
                },
                {
                    heading: 'Quota Management',
                    text: 'Set quotas for specific demographics within your surveys to ensure you receive the desired number of responses from each target group.'
                },
                {
                    heading: 'Group Discussions',
                    text: 'Foster interactive discussions with real-time text chat, audio conferencing, and video capabilities.'
                },
                {
                    heading: 'Collaboration Tools',
                    text: 'Share research findings and collaborate seamlessly with colleagues within the platform, facilitating teamwork and efficient project management.'
                },
            ]}
            bg={'true'}
            image={researcher_icon}
        />
    )
}

export default Powerful_research
