import React, { useState } from "react";
import Flex_box from "../../../utilities/flex_box";
import Button from "../../../utilities/Button";
import landing_mask from "../../../assets/Mask Group 1.svg";
import right_mask from "../../../assets/Group 47599.svg";
import welcome_icon from "../../../assets/Group 47601.png";
import landing_bg from "../../../assets/Path1.png";
import { useMediaQuery } from "@mui/material";
import FadeInSection from "../../../utilities/FadeInSection";

const Welcome_Comp = ({ open_modal, label, sub_text_arr }) => {
  const max_w_850 = useMediaQuery("(max-width:850px)");

  return (
    <FadeInSection>
      <Flex_box
        className="auth_box_cont intro_comp_cont"
        style={{
          flexWrap: "wrap",
          position: "relative",
          backgroundImage: `url(${landing_bg})`,
        }}
      >
        {!max_w_850 && (
          <>
            <img
              style={{
                position: "absolute",
                top: "-80px",
                left: "0px",
                height: "700px",
                zIndex: 100,
              }}
              className="hover_img"
              src={landing_mask}
            />
            <img
              style={{
                position: "absolute",
                right: "0px",
                width: "47%",
                height: "100vh",
                zIndex: 101,
              }}
              className="hover_img"
              src={right_mask}
            />
          </>
        )}
        <Flex_box
          style={{
            gap: 20,
            flexWrap: "wrap",
            zIndex: 102,
          }}
        >
          <div
            className="intro_text_cont"
            style={{
              textWrap: "wrap",
              whiteSpace: "wrap",
              gap: 20,
            }}
          >
            <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
              {label}
            </b>
            <br className="max_100_hidden" />
            <br />
            {sub_text_arr?.length > 0 &&
              sub_text_arr?.map((el, index) => {
                return (
                  <>
                    <span
                      key={index}
                      className="intro_sub_text"
                      style={{
                        fontStyle: "italic",
                        fontWeight: el?.bold ? 600 : 400,
                      }}
                    >
                      {el?.text}
                    </span>
                    <br className="max_100_hidden" />
                    <br />
                  </>
                );
              })}
          </div>
          <div
            className="max_100_hidden"
            style={{
              width: "40%",
              minWidth: "200px",
            }}
          >
            <Flex_box
              style={{
                height: "82vh",
                gap: 20,
              }}
            >
              <img
                src={welcome_icon}
                style={{
                  height: "60vh",
                }}
                className="hover_img"
              />
            </Flex_box>
          </div>
        </Flex_box>
      </Flex_box>
    </FadeInSection>
  );
};

export default Welcome_Comp;
