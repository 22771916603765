import React from "react";
import Flex_box from "../../../utilities/flex_box";
import { theme_bg, theme_color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { buy_subscription_api } from "../../../apis/apis";
import { addDays } from "../../../utilities/Date_Util";
import { set_user_action } from "../../../redux_prog/actions/auth_action";
import { alert_action } from "../../../redux_prog/actions/base_action";

const Price_Box = ({ price, subtext, duration, content, _id, item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state?.authReducer);

  const Duration_arr = duration?.split("/");
  const duration_suffix = Duration_arr[0] > 1 ? "s" : "";

  const inc_days =
    Duration_arr[1] == "Year"
      ? Duration_arr[0] * 365
      : Duration_arr[1] == "Month"
      ? Duration_arr[0] * 30
      : Duration_arr[0] * 7;

  const buy_subscription = () => {
    const user_sub_data = {
      plan_id: _id,
      user_id: user_data?._id,
      transaction_id: `7634ui78f357`,
      exp_date: addDays(inc_days),
    };

    buy_subscription_api({ user_sub_data })
      .then((res) => {
        if (res.data.status == 1) {
          dispatch(
            set_user_action({
              ...user_data,
              subscription_data: res.data.Res,
              user_sub_lookup: [
                {
                  ...res.data.Res,
                  sub_lookup: [item],
                },
              ],
            })
          );
          dispatch(
            alert_action({
              type: "success",
              title: "Success",
              msg: "You can now access the features of SmartQ application!",
            })
          );
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Flex_box
      className="responsive_width"
      style={{
        width: "",
        flexDirection: "column",
        lineHeight: 0.9,
        boxShadow: "0px 10px 20px #0000000D",
        padding: "20px 10px",
      }}
    >
      <span style={{ fontSize: "28px", fontWeight: 520 }}>{price}</span>
      <br />
      <span style={{ fontSize: "20px" }}>{subtext}</span>
      <Flex_box
        style={{
          flexDirection: "column",
          backgroundColor: theme_color,
          textAlign: "center",
          borderRadius: "10px",
          color: "#fff",
          gap: 15,
          padding: "25px 10px",
          boxSizing: "border-box",
          marginTop: "20px",
        }}
      >
        <span style={{ fontSize: "28px", fontWeight: 600 }}>
          {Duration_arr[0] + " " + Duration_arr[1] + duration_suffix}
        </span>
        {/* <span style={{lineHeight:1.5}}>{content}</span> */}
        <div
          style={{ lineHeight: 1.5 }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <button
          id="btn_comp"
          style={{
            fontWeight: 600,
            backgroundColor: "#fff",
            color: "#000",
            marginTop: "20px",
          }}
          onClick={() => {
            buy_subscription();
          }}
        >
          Start now
        </button>
      </Flex_box>
    </Flex_box>
  );
};

export default Price_Box;
