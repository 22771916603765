import React, { useState } from 'react'
import back_icon from "../../../assets/chevron_back_circle.svg";
import { theme_bg, theme_color } from '../../../utilities/colors'
import Flex_box from '../../../utilities/flex_box';
import File_input from '../../../utilities/File_input';
import upload_img from "../../../assets/Group 33339.png";
import Text_Input from '../../../utilities/Text_Input';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useLocation, useNavigate } from 'react-router-dom';
import map_icon from "../../../assets/location-filled.svg";
import phone_icon from "../../../assets/phone.svg";
import down_arrow_icon from "../../../assets/arrow-right-bold.svg";
import calender_icon from "../../../assets/calendar-week-fill.svg";
import Select_box from '../../../utilities/Select_box';
import Auth_header from '../Auth_header';
import Button from '../../../utilities/Button';
import { create_business_detail_api } from '../../../apis/apis';
import validate_object from '../../../utilities/Validate_object';
import { useDispatch } from 'react-redux';
import { alert_action } from '../../../redux_prog/actions/base_action';
import { login_action } from '../../../redux_prog/actions/auth_action';
import { set_temp_user_action } from '../../../redux_prog/actions/app_action';

const Auth_Text = ({ val, onChange, label, suffix_img, type }) => {
    return (
        <Text_Input
            label={label}
            cont_style={{
                marginTop: '20px',
                width: "84%"
            }}
            input_cont_style={{
                width: "100%"
            }}
            input_style={{
                width: "100%"
            }}
            suffix_img={suffix_img}
            type={type}
            value={val}
            onChange={onChange}
        />
    )
}

const Researcher_authentication = () => {

    const state = useLocation();

    const UserId = state?.state?.user_id;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [researcher_data, setresearcher_data] = useState({
        "Businessname": "test1",
        "BusinessDescription": "test2",
        "BusinessAddress": "luckonw",
        "Industry": "test3",
        "ConductIndustryStudiesIn": "luckonw",
        "SurName": "sukla",
        "FirstName": "rohit",
        "OtherName": "jonh",
        "Mobileno": "1234567890",
        "Address": "aliganj",
        "Email": "test@gmail.com",
        "Gender": "male",
        "ProfessionalAcadeMicQualification": "b.tech",
        "StateOfOrigin": "test",
        "LGAoForigin": "test",
        "Signature": "rohit",
        "Country": "lko",
        "State": "up",
        "Dop": "",
        Gender: 'male'
    });

    const [loading, setloading] = useState(false);

    const upd_researcher_data = (key, val) => {
        setresearcher_data({
            ...researcher_data,
            [key]: val
        });
    };

    const {
        Businessname,
        BusinessDescription,
        BusinessAddress,
        Industry,
        ConductIndustryStudiesIn,
        SurName,
        FirstName,
        OtherName,
        Mobileno,
        Address,
        Email,
        Gender,
        Dop,
        ProfessionalAcadeMicQualification,
        StateOfOrigin,
        LGAoForigin,
        Signature,
        Country,
        State
    } = researcher_data;

    const create_business_detail = () => {
        const validated = validate_object(researcher_data);
        if (validated?.status == true) {
            setloading(true);
            create_business_detail_api({ ...researcher_data, UserId: UserId }).then((res) => {
                setloading(false);
                dispatch(alert_action({
                    type: "success",
                    title: 'Success',
                    msg: "Successfully created researcher!"
                }));
            dispatch(set_temp_user_action(""));
            dispatch(login_action());
                // setTimeout(() => {
                    navigate("/select-sub");
                // }, 500);
                console.log(res);
            }).catch((err) => {
                setloading(false);
                console.log(err);
            })
        }
        else {
            dispatch(alert_action({
                type: 'error',
                title: 'Missing Field',
                msg: 'Please enter ' + validated?.key + " field!"
            }));
        }
    };

    return (
        <div
            className='auth_box_cont'
            style={{
                overflowY: "auto",
                padding: '150px 0px',
                alignItems: "start",
                maxHeight:"60vh"
            }}
        >
            <div
                className='auth_box'
                style={{
                    padding: '15px',
                    paddingBottom: "50px"
                }}
            >
                <Auth_header label={'Researcher Authentication'} />
                <span
                    className='responsive_Width'
                    style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        textAlign: "center",
                        padding: "25px 0px"
                    }}
                >
                    We need some more information abut you and your business before you can be onboarded to Yaarnbox as a Researcher.
                </span>
                <div
                    className='responsive_width'
                    style={{
                        textAlign: 'start'
                    }}
                >
                    <b>
                        Business Details
                    </b>
                </div>
                <Auth_Text label={'Business Name'}
                    val={Businessname}
                    onChange={(e) => {
                        upd_researcher_data('Businessname', e.target.value);
                    }}
                />
                <Text_Input
                    label={'Business Description'}
                    cont_style={{
                        marginTop: '20px',
                        width: "84%"
                    }}
                    input_cont_style={{
                        width: "100%"
                    }}
                    textArea={true}
                    input_style={{
                        height: '60px',
                        maxHeight: "60px",
                        width: "100%"
                    }}
                    value={BusinessDescription}
                    onChange={(e) => {
                        upd_researcher_data('BusinessDescription', e.target.value);
                    }}
                />
                <Auth_Text label={'Business Address'} suffix_img={map_icon}
                    val={BusinessAddress}
                    onChange={(e) => {
                        upd_researcher_data('BusinessAddress', e.target.value);
                    }} />
                <Auth_Text label={'Industry'}
                    val={Industry}
                    onChange={(e) => {
                        upd_researcher_data('Industry', e.target.value);
                    }} />
                <Select_box
                    cont_style={{
                        marginTop: '20px',
                        width: "84%"
                    }}
                    input_style={{
                        width: "100%"
                    }}
                    select_cont_style={{
                        width: "100%"
                    }}
                    label={'Conduct Industry Studies in'}
                    value={ConductIndustryStudiesIn}
                    onChange={(e) => {
                        upd_researcher_data('ConductIndustryStudiesIn', e.target.value);
                    }}
                />

                <div
                    className='responsive_width'
                    style={{
                        textAlign: 'start',
                        marginTop: '25px'
                    }}
                >
                    <b>
                        Authentication Form
                    </b>
                </div>
                <Flex_box
                    // className="responsive_width"
                    style={{
                        justifyContent: "space-between",
                        width: '83%',
                        boxSizing: "border-box",
                        // paddingRight: '20px',
                        // gap: 30,
                        // backgroundColor: "red"
                    }}
                >
                    <Text_Input
                        label={'Surname'}
                        cont_style={{
                            marginTop: '18px',
                            width: "47%"
                        }}
                        input_style={{
                            width: "100%"
                        }}
                        input_cont_style={{
                            width: "100%"
                        }}
                        value={SurName}
                        onChange={(e) => {
                            upd_researcher_data('SurName', e.target.value);
                        }}
                    // cont_class_name={'responsive_width_35'}
                    />
                    <Text_Input
                        label={'First Name'}
                        cont_style={{
                            marginTop: '18px',
                            width: "47%"
                        }}
                        input_style={{
                            width: "100%"
                        }}
                        input_cont_style={{
                            width: "100%"
                        }}
                        value={FirstName}
                        onChange={(e) => {
                            upd_researcher_data('FirstName', e.target.value);
                        }}
                    />
                </Flex_box>
                <Auth_Text label={'Mobile No.'} suffix_img={phone_icon}
                    val={Mobileno}
                    onChange={(e) => {
                        upd_researcher_data('Mobileno', e.target.value);
                    }} />
                <Auth_Text label={'Address'} suffix_img={map_icon}
                    val={Address}
                    onChange={(e) => {
                        upd_researcher_data('Address', e.target.value);
                    }} />
                <Auth_Text label={'Email'}
                    val={Email}
                    onChange={(e) => {
                        upd_researcher_data('Email', e.target.value);
                    }} />
                <div
                    className='responsive_width'
                    style={{
                        marginTop: '12px'
                    }}
                >
                    <span style={{ fontSize: "10px" }}>{'Gender'}</span>
                </div>
                <Flex_box
                    className="responsive_width"
                    style={{
                        justifyContent: "space-between",
                        width: '84%',
                        // marginRight: '20px',
                        gap: 20,
                        marginTop: '5px'
                    }}
                >
                    <Button
                        onClick={() => {
                            upd_researcher_data('Gender', 'male');
                        }}
                        style={{
                            width: "45%",
                            backgroundColor: Gender != 'male' ? "#F9F9F9" : theme_color,
                            color: Gender != 'male' ? "#000" : "#fff"
                        }}
                        label={'Male'}
                    >
                        Male
                    </Button>
                    <Button
                        onClick={() => {
                            upd_researcher_data('Gender', 'female');
                        }}
                        id='btn_comp'
                        style={{
                            width: "45%",
                            backgroundColor: Gender != 'female' ? "#F9F9F9" : theme_color,
                            color: Gender != 'female' ? "#000" : "#fff"
                        }}
                        label={'Female'}
                    >
                        Female
                    </Button>
                </Flex_box>


                <Auth_Text
                    label={'DOB'}
                    suffix_img={calender_icon}
                    type={'date'}
                    val={Dop}
                    onChange={(e) => {
                        upd_researcher_data('Dop', e.target.value);
                    }}
                />
                <Text_Input
                    label={'Professional/Acade Mic Qualification'}
                    cont_style={{
                        marginTop: '18px',
                        width: "84%"
                    }}
                    textArea={true}
                    input_style={{
                        height: '60px',
                        maxHeight: "60px",
                        width: "100%"
                    }}
                    input_cont_style={{
                        width: "100%"
                    }}
                    value={ProfessionalAcadeMicQualification}
                    onChange={(e) => {
                        upd_researcher_data('ProfessionalAcadeMicQualification', e.target.value);
                    }}
                />


                <Auth_Text
                    label={'State of Origin'}
                    val={StateOfOrigin}
                    onChange={(e) => {
                        upd_researcher_data('StateOfOrigin', e.target.value);
                    }}
                />

                <Auth_Text
                    label={'LGA of Origin'}
                    val={LGAoForigin}
                    onChange={(e) => {
                        upd_researcher_data('LGAoForigin', e.target.value);
                    }}
                />

                <Auth_Text
                    label={'Signature'}
                    val={Signature}
                    onChange={(e) => {
                        upd_researcher_data('Signature', e.target.value);
                    }}
                />

                <Flex_box
                    className="responsive_width"
                    style={{
                        justifyContent: "start",
                        width: '83%',
                        // paddingRight: '20px',
                        gap: 30
                    }}
                >
                    <Text_Input
                        label={'Country'}
                        cont_style={{
                            marginTop: '18px',
                            width: "47%"
                        }}
                        input_style={{
                            width: "100%"
                        }}
                        input_cont_style={{
                            width: "100%"
                        }}
                        value={Country}
                        onChange={(e) => {
                            upd_researcher_data('Country', e.target.value);
                        }}
                    />
                    <Text_Input
                        label={'State (Location)'}
                        cont_style={{
                            marginTop: '18px',
                            width: "47%"
                        }}
                        input_style={{
                            width: "100%"
                        }}
                        input_cont_style={{
                            width: "100%"
                        }}
                        value={State}
                        onChange={(e) => {
                            upd_researcher_data('State', e.target.value);
                        }}
                    />
                </Flex_box>

                <Button
                    style={{
                        marginTop: "40px"
                    }}
                    onClick={create_business_detail}
                    label='Proceed'
                    loading={loading}
                />

            </div>
        </div >
    )
}

export default Researcher_authentication
