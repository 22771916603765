import React from "react";
import Flex_box from "../../../utilities/flex_box";
import { theme_color } from "../../../utilities/colors";

const Member_Comp = () => {
  return (
    <Flex_box
      style={{
        width: "",
        flexDirection: "column",
      }}
    >
      <img
        style={{
          height: "130px",
          width: "130px",
          objectFit: "cover",
        }}
        src="https://res.cloudinary.com/termii-inc/image/upload/v1631195075/teampage/Gb_mxsz83.png"
        className="hover_img"
      />
      <b
        style={{
          fontSize: "14px",
        }}
      >
        Emmanuel Gbolade
      </b>
      <p
        style={{
          fontSize: "13px",
          marginTop: "-6px",
        }}
      >
        CoFounder & Chairman
      </p>
    </Flex_box>
  );
};

const Team_members = () => {
  return (
    <Flex_box
      style={{
        flexDirection: "column",
        padding: "15px",
        boxSizing: "border-box",
      }}
    >
      <b className="intro_heading_text">
        Our{" "}
        <span
          style={{
            color: theme_color,
          }}
        >
          Team Members
        </span>
      </b>
      <p
        className="intro_sub_text"
        style={{
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        The Termii Board of Directors brings years of experience in
        telecommunications, financial technology services and software.
      </p>
      <Flex_box
        style={{
          gap: 10,
          width: "70%",
          minWidth: "280px",
          flexWrap: "wrap",
        }}
      >
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
        <Member_Comp />
      </Flex_box>
    </Flex_box>
  );
};

export default Team_members;
