import React from 'react'
import Layout from '../../../../utilities/Layout'
import GD_Form from './GD_Form'

const Create_GD = () => {
    return (
        <Layout>
            <GD_Form />
        </Layout>
    )
}

export default Create_GD
