
export const login_action = () => ({
    type: 'LOGIN',
});


export const logout_action = () => ({
    type: 'LOGOUT',
});

export const set_token_action = (payload) => ({
    type: 'SET_TOKEN',
    payload
});

export const set_user_action = (payload) => ({
    type: 'SET_USER',
    payload
});

export const set_login_type_action = (payload) => ({
    type: 'SET_LOGIN_TYPE',
    payload
});

export const set_intro_status_action = (payload) => ({
    type: 'SET_INTRO_STATUS',
    payload
});

export const set_otp_action = (payload) => ({
    type: 'SET_OTP',
    payload
});



