import React from "react";
import { theme_bg } from "./colors";
import Flex_box from "./flex_box";

const Table = ({ style, heads, rows, fontSize, row_click_func }) => {
  const style_obj = style || {};

  const th_col_style = {
    padding: "18px 0px 18px 15px",
    textAlign: "start",
    borderRadius: "10px",
  };

  const td_col_style = {
    padding: "10px 0px 0px 15px",
    textAlign: "start",
  };

  return (
    <table
      style={{
        width: "100%",
        padding: "0px",
        ...style_obj,
      }}
    >
      <thead
        style={{
          backgroundColor: theme_bg,
          color: "#000",
          fontWeight: "600",
          fontSize: fontSize || "11px",
        }}
      >
        {heads?.length > 0 &&
          heads.map((el, index) => {
            return (
              <th key={index} style={th_col_style}>
                {el?.label}
              </th>
            );
          })}
      </thead>
      <tbody
        style={{
          backgroundColor: "#FFF",
          color: "#000",
          fontSize: fontSize || "11px",
        }}
      >
        {rows?.length > 0 &&
          rows.map((el, index) => {
            return (
              <tr
                key={index}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (row_click_func) {
                    row_click_func(el);
                  }
                }}
              >
                {heads?.length > 0 &&
                  heads.map((li, ind) => {
                    const style_obj = li.tr_style || {};
                    return (
                      <td
                        key={ind}
                        onClick={() => {
                          if (li?.td_func) {
                            li.td_func(el);
                          }
                        }}
                        style={{ ...td_col_style, ...style_obj }}
                      >
                        {li.element
                          ? li.element(el, index)
                          : el[li.accessor] || "-"}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Table;
