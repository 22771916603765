import React from 'react'
import Sidebar from '../../Nav_Comp/Sidebar'
import Flex_box from '../../../utilities/flex_box'
import Navbar from '../../Nav_Comp/Navbar'
import Dash_comp from './Dash_comp';
import Layout from "../../../utilities/Layout";
import { theme_color } from '../../../utilities/colors';
import Support_Chat from '../../Support_Chat';

const Dashboard = () => {
    return (
        <Layout
            style={{
                padding: "20px"
            }}
        >
            <span
                style={{
                    color: theme_color,
                    fontWeight: 800,
                    fontSize: '22px'
                }}
            >
                ALL AVAILABLE STUDIES
            </span>
            <Dash_comp />
            <Support_Chat />
        </Layout>
    )
}

export default Dashboard
