import React from "react";
import Flex_box from "../../../utilities/flex_box";
import features_bg from "../../../assets/Group48288.png";
import { theme_bg, theme_color } from "../../../utilities/colors";
import study_icon from "../../../assets/Group 48355.svg";
import communication_icon from "../../../assets/Group 48358.svg";
import analysis_icon from "../../../assets/Group 48359.svg";
import { borderRadius } from "@mui/system";
import Button from "../../../utilities/Button";
import feature_bg from "../../../assets/Group 48354.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Feature_Comp = ({ icon, label, desc, ismiddle }) => {
  const navigate = useNavigate();

  return (
    <div className="feature_box">
      <Flex_box
        style={{
          flexDirection: "column",
          justifyContent: "start",
          position: "relative",
          height: "300px",
          padding: "0px 15px",
        }}
      >
        {ismiddle && (
          <img
            src={feature_bg}
            style={{
              height: "350px",
              width: "350px",
              position: "absolute",
              top: -40,
              left: "10%",
            }}
            className="hover_img"
          />
        )}
        <Flex_box
          style={{
            backgroundColor: theme_bg,
            border: "2px solid " + theme_color,
            padding: "20px 26px",
            width: "",
            borderRadius: "50%",
          }}
        >
          <img src={icon} style={{ height: "50px", width: "40px" }} 
          className="hover_img"
          />
        </Flex_box>
        <b style={{ marginTop: "10px", marginBottom: "7px" }}>{label}</b>
        <span style={{ fontSize: "12px", textAlign: "center" }}>{desc}</span>
        {ismiddle && (
          <Button
            style={{
              width: "120px",
              marginTop: "40px",
            }}
            onClick={() => {
              navigate("/signup");
            }}
            label={"Sign Up Now"}
          />
        )}
      </Flex_box>
    </div>
  );
};

const Key_Features = () => {
  const navigate = useNavigate();
  const max_w_1000 = useMediaQuery("(max-width:1000px)");

  return (
    <Flex_box
      className="auth_box_cont intro_comp_cont"
      style={{
        flexDirection: "column",
        backgroundImage: `url(${features_bg})`,
        marginTop: "15px",
      }}
    >
      <b className="intro_heading_text" style={{ fontStyle: "italic" }}>
        Key Features
      </b>
      <br />
      <b className="intro_sub_text" style={{ fontStyle: "italic" }}>
        Designed for the ease of the researcher and respondent
      </b>
      <span className="intro_sub_text" style={{ fontStyle: "italic" }}>
        Yaarnbox makes conducting and participating in research a breeze.
      </span>

      <Flex_box
        style={{
          //   flexWrap: "wrap",
          gap: 10,
          marginTop: "30px",
          width: "100vw",
          overflowX: "auto",
          overflowY: "hidden",
          justifyContent: max_w_1000 ? "start" : "center",
        }}
      >
        <Feature_Comp
          icon={study_icon}
          label={"Diverse Study Formats"}
          desc={
            "Choose from engaging surveys with branching logic, rich multimedia content, and interactive group discussions with text chat, audio, and video conferencing."
          }
        />
        <Feature_Comp
          ismiddle={true}
          icon={communication_icon}
          label={"Secure and Reliable"}
          desc={
            "Yaarnbox employs industry-standard security measures to ensure the confidentiality and integrity of your data."
          }
        />
        <Feature_Comp
          icon={analysis_icon}
          label={"Advanced Data Analytics"}
          desc={
            "Access powerful dashboards and reporting tools to uncover hidden patterns and gain an in-depth understanding of your data."
          }
        />
      </Flex_box>
    </Flex_box>
  );
};

export default Key_Features;
