import React, { useState } from "react";
import Text_Input from "../../../../utilities/Text_Input";
import Select_box from "../../../../utilities/Select_box";
import Flex_box from "../../../../utilities/flex_box";
import { useLocation, useNavigate } from "react-router-dom";
import { theme_bg, theme_color } from "../../../../utilities/colors";
import File_input from "../../../../utilities/File_input";
import upload_img from "../../../../assets/Group 33339.png";
import validate_object from "../../../../utilities/Validate_object";
import { useDispatch } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";
import { convert_into_base64 } from "../../../../utilities/Image_conv";

const Survey_Form = () => {
  const { state } = useLocation();
  console.warn(state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [survey_data, setsurvey_data] = useState({
    surveytitle: "",
    surveydescription: "",
    studyid: state?.studyid,
  });

  const [image, setimage] = useState(null);

  const upd_survey_data = (key, val) => {
    setsurvey_data({
      ...survey_data,
      [key]: val,
    });
  };

  const { surveytitle, surveydescription } = survey_data;

  const add_questions = () => {
    localStorage.removeItem("question_data");
    const validated = validate_object(survey_data);
    if (validated?.status == true) {
      navigate("/add-survey-questions", {
        state: {
          state: survey_data,
          image,
          studyname: state?.studyname,
          sec: state?.sec,
        },
      });
    } else {
      dispatch(
        alert_action({
          type: "error",
          title: "Missing Field",
          msg: "Please enter " + validated?.key + " field!",
        })
      );
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        boxSizing: "border-box",
        // maxHeight: "77vh",
        height: "78.3vh",
        overflowY: "auto",
      }}
    >
      <div
        className="removescrollbar responsive_width_35"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <span
          style={{
            fontSize: "26px",
            fontStyle: "italic",
            fontWeight: "700",
          }}
        >
          Create surveys
        </span>
        <File_input
          onChange={(e) => {
            convert_into_base64(e[0], (base64) => {
              setimage(base64);
            });
          }}
          renderItem={
            image ? (
              <img
                src={image}
                style={{
                  height: "130px",
                  width: "240px",
                  border: "2px dashed " + theme_color,
                  borderRadius: 10,
                }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 10,
                }}
              >
                <span style={{ fontSize: "10px", marginLeft: "10px" }}>
                  {"Uplaod Survey Image"}
                </span>
                <button
                  style={{
                    background: theme_bg,
                    border: "2px dashed " + theme_color,
                    borderRadius: 10,
                    padding: "35px 0px",
                    width: "240px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={upload_img}
                    style={{
                      height: "50px",
                    }}
                  />
                </button>
              </div>
            )
          }
        />
        <Text_Input
          label={"Survey Title"}
          cont_style={{
            width: "100%",
          }}
          value={surveytitle}
          onChange={(e) => {
            upd_survey_data("surveytitle", e.target.value);
          }}
        />
        <Text_Input
          label={"Survey Description"}
          cont_style={{
            width: "100%",
          }}
          textArea={true}
          value={surveydescription}
          onChange={(e) => {
            upd_survey_data("surveydescription", e.target.value);
          }}
        />
        <button
          id="btn_comp"
          style={{
            marginTop: "40px",
            backgroundColor: theme_bg,
            border: "2px solid " + theme_color,
            color: theme_color,
            fontWeight: 600,
          }}
          onClick={add_questions}
        >
          Add Question
        </button>
      </div>
    </div>
  );
};

export default Survey_Form;
