import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../utilities/Layout";
import Flex_box from "../../../utilities/flex_box";
import { useState, useEffect } from "react";

const Intro_Comp = ({ seteligibility_question, survey_details, study_id,setintro_completed }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log("state is null", state);
  const { surveydata, questionsdata } = state || survey_details;

  // const [questionList, setQuestionList] = useState(questionsdata);
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);

  useEffect(() => {
    

    var qstnList = [];
    // debugger;
    console.log("not dependent question", questionsdata);
    for (let i = 0; i < questionsdata?.length; i++) {
      var question = questionsdata?.find((x) =>
        x?.Options.find(
          (y) => y.dependent_question == questionsdata[i].QuestionData.serial_no
        )
      );
      if (!question) {
        qstnList.push(questionsdata[i]);
        questionsdata[i]["depend_question"] = false;
      } else {
        questionsdata[i]["depend_question"] = true;
        qstnList.push(questionsdata[i]);
      }
    }
    console.log("dependent questions", qstnList);

    var noOfNotDependentQuestion = qstnList.filter(
      (x) => !x.depend_question
    ).length;
    var noOfDependentQuestions = qstnList.filter((x) =>
      x?.Options.some((y) => y?.dependent_question)
    ).length;
    console.log("no of not dependent question", noOfNotDependentQuestion);
    console.log("no of dependent question", noOfDependentQuestions);
    setNumberOfQuestion(noOfNotDependentQuestion + noOfDependentQuestions);
  });

  return (
    <Flex_box
      className="responsive_width_35"
      style={{
        flexDirection: "column",
        padding: "20px",
        width: "",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px #0000000D",
        gap: 10,
        textAlign: "center",
      }}
    >
      <b style={{ fontSize: "22px" }}>{surveydata?.surveytitle}</b>
      <span
        style={{
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        Total Questions : {numberOfQuestion}
      </span>
      <span
        style={{
          fontSize: "13px",
        }}
      >
        You are at the start of a Survey. When ready to begin, please click the
        Next button below to continue.
        <br />
        <br />
        At the end, you will have the opportunity to submit your responses.
      </span>
      <button
        id="btn_comp"
        style={{
          marginTop: "20px",
        }}
        onClick={() => {
          // navigate('/create-study');
          if (study_id) {
            setintro_completed(true);
          }
          else{
            seteligibility_question(true);
          }
        }}
      >
        Next
      </button>
    </Flex_box>
  );
};

export default Intro_Comp;
