import React from "react";
import Flex_box from "../../../utilities/flex_box";
import Progress_bar from "../../../utilities/Progress_bar";
import "./study_box.css";
import { useNavigate } from "react-router-dom";
import Button from "../../../utilities/Button";
import { get_industry_name } from "../../../utilities/select_arr";

const Study_Box = ({ progress, item }) => {
  const { study } = item;

  const navigate = useNavigate();

  return (
    <div
      className="study_box_cont"
      style={{
        gap: 12,
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: "24px",
        }}
      >
        {study?.studyname}
      </span>

      <span
        style={{
          fontSize: "15px",
        }}
      >
        <span style={{ fontWeight: 600 }}>Study on </span>:{" "}
        {get_industry_name(study?.studyoninductry)}
      </span>
      <span
        style={{
          fontSize: "12px",
          marginTop: "5px",
          lineHeight: 1.5,
        }}
      >
        {study?.description}
      </span>
      <Flex_box
        style={{
          width: "",
          gap: 25,
          justifyContent: "start",
          marginTop: "10px",
        }}
      >
        <Button
          id="btn_comp"
          style={{ height: "50px", fontSize: "16px", fontWeight: "500" }}
          onClick={() => {
            navigate("/study-details", { state: { study_id: study?._id,type:'gd' } });
          }}
          label={"View Details"}
        ></Button>
      </Flex_box>
    </div>
  );
};

export default Study_Box;
