import React, { useEffect, useState } from "react";
import Page_heading from "../../../../utilities/Page_heading";
import Table from "../../../../utilities/Table";
import user_icon from "../../../../assets/user_dummy.png";
import Pagination from "../../../../utilities/Pagination";
import {
  gd_request_action_api,
  get_gd_requests_api,
  image_url,
  StudyDetails_api,
} from "../../../../apis/apis";
import { useLocation } from "react-router-dom";
import Button from "../../../../utilities/Button";
import { theme_bg, theme_color } from "../../../../utilities/colors";
import Flex_box from "../../../../utilities/flex_box";
import { useDispatch, useSelector } from "react-redux";
import { alert_action } from "../../../../redux_prog/actions/base_action";

const GD_Request_table = ({ gd_data, study_id, study_detail }) => {
  const dispatch = useDispatch();
  const [resp_list, setresp_list] = useState([]);
  const { user_data } = useSelector((state) => state?.authReducer);

  const pageSize = 10;
  const [currentPage, setcurrentPage] = useState(1);
  const [totalRows, settotalRows] = useState(0);

  const req_action = (el, status) => {
    const lookup_res = el?.users_lookup[0] || {};
    const status_msg = status == 1 ? "approved!" : "rejected!";
    gd_request_action_api({
      _id: el?._id,
      status,
      noti_body: {
        UserId: user_data?._id,
        toId: lookup_res?._id,
        msg:
          "Your request for joining " +
          gd_data?.groupdiscussionname +
          " has been " +
          status_msg,
        title: "Request " + status_msg,
        type: "GD",
        notificatonTypeId: study_id,
        isread: false,
        sender_type: 2,
      },
    })
      .then((res) => {
        get_gd_requests();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const get_gd_requests = () => {
    const g_id = gd_data?._id;
    if (g_id) {
      get_gd_requests_api({
        g_id,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
      })
        .then((res) => {
          const res_data = res.data?.Res[0]?.paginatedResults;
          settotalRows(res.data?.Res[0]?.totalCount[0]?.count);

          const approved_members_count = res_data?.filter((el) => {
            return el?.status == 1;
          })?.length;

          const new_data = res_data?.map((el) => {
            const lookup_res = el?.users_lookup[0] || {};
            return [
              <img
                src={image_url + lookup_res?.profilepictuer}
                style={{
                  height: "35px",
                }}
              />,
              lookup_res?.firstname + " " + lookup_res?.lastname,
              lookup_res?.email,
              <Flex_box
                style={{
                  width: el?.status == 0 ? "200px" : "100px",
                  gap: 5,
                }}
              >
                <Button
                  style={
                    el?.status != 0
                      ? {
                          fontWeight: "600",
                          color: el?.status == 2 ? "red" : theme_color,
                          backgroundColor: theme_bg,
                          border:
                            "2px solid " +
                            (el?.status == 2 ? "red" : theme_color),
                          padding: "8px 0px",
                          width: "100px",
                        }
                      : {
                          padding: "8px 0px",
                          width: "100px",
                          backgroundColor: "green",
                        }
                  }
                  label={
                    el?.status == 0
                      ? "Approve"
                      : el?.status == 1
                      ? "Approved"
                      : "Rejected"
                  }
                  onClick={() => {
                    if (el?.status == 0) {
                      if (
                        approved_members_count < study_detail?.totalrespondents
                      ) {
                        req_action(el, 1);
                      } else {
                        dispatch(
                          alert_action({
                            type: "error",
                            title: "Max Limit!",
                            msg: `This study has reached it's maximum members!`,
                          })
                        );
                      }
                    }
                  }}
                />
                {el?.status == 0 && (
                  <Button
                    style={{
                      padding: "8px 0px",
                      width: "100px",
                      backgroundColor: "red",
                    }}
                    label={"Reject"}
                    onClick={() => {
                      req_action(el, 2);
                    }}
                  />
                )}
              </Flex_box>,
            ];
          });
          setresp_list(new_data);
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };

  useEffect(get_gd_requests, [currentPage, gd_data]);

  const onNext = (page) => {
    setcurrentPage(page);
  };

  const onPrev = (page) => {
    setcurrentPage(page);
  };

  return (
    <div
      style={{
        padding: "20px 0px",
        paddingBottom: "40px",
      }}
    >
      <Page_heading label={"Members"} />
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          boxShadow: "0px 10px 15px #2424240D",
          borderRadius: "10px",
        }}
      >
        {resp_list?.length > 0 && (
          <>
            <Table
              style={{}}
              heads={[`Profile Picture`, `Name`, `Email`, `Action`]}
              rows={resp_list}
            />
            <Pagination
              currentPage={currentPage}
              onNext={onNext}
              onPrev={onPrev}
              totalRows={totalRows}
              pageSize={pageSize}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GD_Request_table;
