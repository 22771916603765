import React from 'react'
import Points_Comp from '../Components/Points_Comp'
import bg from "../../../assets/Path37581.png";

const The_Value = () => {
    return (
        <Points_Comp
            bg={bg}
            label={'The Value'}
            sub_text_arr={[
                { text: 'Yaarnbox offers a comprehensive suite of features to cater to all your research needs:', bold: true}
            ]}
            points_arr={[
                {
                    heading: 'Survey Creation',
                    text: 'Craft engaging surveys with various question formats, branching logic, quota management, and multimedia content integration.'
                },
                {
                    heading: 'Group Discussions',
                    text: 'Facilitate interactive group discussions with real-time text chat, audio conferencing, and video capabilities.'
                },
                {
                    heading: 'Respondent Matching',
                    text: 'Target the right audience for your studies using advanced demographic filters and preferences.'
                },
                {
                    heading: 'Data Analytics and Reporting',
                    text: 'Gain in-depth understanding of your data through intuitive dashboards, insightful reports, and powerful visualization tools.'
                },
                {
                    heading: 'Data Security and Compliance',
                    text: 'We prioritize the security of your data with industry-standard encryption protocols and rigorous KYC verification processes.'
                },
            ]}
        />
    )
}

export default The_Value
