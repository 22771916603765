import React, { useEffect, useState } from "react";
import Text_Input from "../../../utilities/Text_Input";
import Flex_box from "../../../utilities/flex_box";
import search_icon from "../../../assets/Icon ionic-md-search.svg";
import blue_arrow from "../../../assets/arrow-ios-downward-outline.svg";
import white_arrow from "../../../assets/arrow-ios-downward-outline (1).svg";
import { theme_bg } from "../../../utilities/colors";

const FAQ_Tiles = ({ item, setselected_question }) => {
  return (
    <div
      style={{
        width: "90%",
        boxShadow: "0px 10px 20px #0000000D",
        borderRadius: "10px",
        cursor: "pointer",
      }}
      onClick={() => {
        setselected_question(item);
      }}
    >
      <Flex_box
        style={{
          padding: "20px 10px",
          justifyContent: "space-between",
        }}
      >
        <b style={{ fontSize: "14px" }}>{item?.title}</b>
        <img src={blue_arrow} style={{ height: "16px" }} />
      </Flex_box>
    </div>
  );
};

const FAQ_Comp = () => {
  const faq_arr = [
    {
      title: "How do I create a survey?",
      content:
        "Yaarnbox offers an intuitive interface for building engaging surveys. Choose from a variety of question formats, including multiple-choice, open-ended, ranking scales, and logic branching to create a dynamic survey experience that gathers the data you need. You also get to specify the demographic details of the respondents that you need. The system automatically filters out unqualified respondents from taking your surveys.",
      open: false,
    },
    {
      title: "How do I target the right audience for my studies?",
      content: `Go beyond demographics! Yaarnbox's advanced targeting options allow you to reach highly qualified respondents who perfectly match your research criteria. Utilize detailed demographic filters, location targeting, and Socio-Economic Classification targeting to ensure you connect with the ideal audience for your study.`,
      open: false,
    },
    {
      title: "What quota management options are available?",
      content: `Never settle for generic data. Set specific quotas for different demographics within your surveys. This ensures you receive the desired number of responses from each target group, guaranteeing a well-rounded and representative data set for your analysis.`,
      open: false,
    },
    {
      title: "How do I conduct interactive group discussions?",
      content: `Spark meaningful conversations with real-time text chat, audio conferencing, and video capabilities. Yaarnbox facilitates dynamic group discussions, allowing you to delve deeper into participant insights and gather richer qualitative data. Keep in mind that group discussions are limited to a maximum of 2 hours per session to maintain focus and engagement.`,
      open: false,
    },
    {
      title: "How do I analyze my data and gain actionable insights?",
      content: `Our user-friendly platform empowers you to transform raw data into actionable insights. Leverage intuitive dashboards that provide real-time visualizations of key metrics and trends. Generate comprehensive reports with customizable charts and graphs to effectively communicate your research findings. Utilize advanced filtering and segmentation tools to drill down into specific demographics or response options, uncovering hidden patterns and gaining a deeper understanding of your data.`,
      open: false,
    },
    {
      title: "What are Smart-Coins, and how do they incentivize participation?",
      content: `Smart-Coins are our platform's virtual currency used to reward respondents for their valuable time and contributions. Allocate Smart Coins as incentives for completing surveys or participating in group discussions. This incentivizes participation, leading to a wider pool of qualified respondents for your research.`,
      open: false,
    },
  ];

  const [changable_faq_arr, setchangable_faq_arr] = useState(faq_arr);

  const [selected_question, setselected_question] = useState(faq_arr[0]);
  const [ques_search, setques_search] = useState("");

  useEffect(() => {
    setchangable_faq_arr(
      faq_arr?.filter((el) => {
        if (ques_search) {
          return el?.title?.toLowerCase()?.includes(ques_search?.toLowerCase());
        } else {
          return el;
        }
      })
    );
  }, [ques_search]);

  return (
    <div
      style={{
        padding: "20px",
        maxHeight: "79vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <span
        style={{
          fontSize: "26px",
          fontStyle: "italic",
          fontWeight: "700",
        }}
      >
        FAQ
      </span>
      <Flex_box>
        <div className="responsive_width_35">
          <Text_Input
            label={""}
            prefix_img={search_icon}
            placeholder={"Search here.."}
            cont_style={{
              width: "100%",
            }}
            input_style={{
              paddingLeft: "30px",
            }}
            value={ques_search}
            onChange={(e) => {
              setques_search(e.target.value);
            }}
          />
        </div>
      </Flex_box>
      <Flex_box
        style={{
          gap: 20,
          flexWrap: "wrap",
          alignItems: "",
        }}
      >
        <Flex_box
          className="responsive_width_35"
          style={{
            flexDirection: "column",
            width: "",
            gap: 20,
            padding: "20px 0px",
            borderRadius: "10px",
            boxShadow: "0px 10px 20px #0000000D",
            height: "300px",
            overflowY: "auto",
          }}
        >
          {changable_faq_arr?.map((el, index) => {
            return (
              <FAQ_Tiles
                item={el}
                setselected_question={setselected_question}
                key={index}
              />
            );
          })}
        </Flex_box>
        <div
          className="responsive_width_35"
          style={{
            width: "",
            alignItems: "",
            padding: "20px 0px 20px 10px",
            borderRadius: "10px",
            boxShadow: "0px 10px 20px #0000000D",
            // justifyContent:"start"
          }}
        >
          <div
            style={{
              backgroundColor: theme_bg,
              padding: "10px",
              width: "90%",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              {selected_question?.title}
            </span>
            <br />
            <span style={{ fontSize: "12px" }}>
              {selected_question?.content}
            </span>
          </div>
        </div>
      </Flex_box>
    </div>
  );
};

export default FAQ_Comp;
